import React, { useState } from 'react';
import { ActionPlanZoneType, ActionplanInputType, IActionPlanItem } from '../../../../types/models';
import { ZoneSectionContainer, ActionPlanLink, ActionMedicationInput } from '../common';
import * as S from './index.styles';
import { theme } from '@apps/common-ui';

type Props = {
    medicationActions: IActionPlanItem[];
    updateActions: (actions: IActionPlanItem[]) => void;
    disabled: boolean;
    zoneType: ActionPlanZoneType;
}

const MedicationActions = ({ medicationActions, updateActions, disabled, zoneType }: Props) => {
    const [actions, setActions] = useState<IActionPlanItem[]>(medicationActions);

    const onActionChange = (index: number, action: Partial<IActionPlanItem>) => {
        const newActions = [...actions];
        newActions[index] = {
            ...newActions[index],
            ...action
        };
        setActions(newActions);
        updateActions(newActions);
    };

    const addNewItem = () => {
        const newActions = [...actions, { value: '', inputType: ActionplanInputType.MEDICATION }];
        setActions(newActions);
        updateActions(newActions);
    };

    const removeItem = (index: number) => {
        const newActions = [...actions];
        newActions.splice(index, 1);
        setActions(newActions);
        updateActions(newActions);
    };

    return (
        <ZoneSectionContainer>
            <S.SectionTitle>Medications</S.SectionTitle>
            {zoneType === ActionPlanZoneType.GREEN && (
                <S.SectionText>
                    They use these long-term medications on a daily basis for regular maintenance
                </S.SectionText>
            )}
            {zoneType === ActionPlanZoneType.YELLOW && (
                <S.SectionText>
                    Changes to medication when in the yellow zone
                </S.SectionText>
            )}
            {zoneType === ActionPlanZoneType.RED && (
                <S.SectionText>
                    Changes to medication when in the red zone
                </S.SectionText>
            )}
            <S.SectionSubText>Medication Z - 3 puffs/day or Medication Y as needed</S.SectionSubText>
            {actions.map((medicationAction, index) => (
                <S.InputContainer key={medicationAction.id || index}>
                    <ActionMedicationInput
                      value={medicationAction.value}
                      onChange={e => onActionChange(index, { value: e.target.value })}
                      disabled={disabled}
                      placeholder="Medication name - dosage"
                    />
                    <ActionPlanLink
                      color={theme.links.red}
                      onClick={() => removeItem(index)}
                    >Remove
                    </ActionPlanLink>
                </S.InputContainer>
            ))}
            <ActionPlanLink
              color={theme.links.blue}
              marginLeft="10"
              onClick={addNewItem}
            >+ Add item
            </ActionPlanLink>
        </ZoneSectionContainer>
    );
};

export default MedicationActions;
