import { Inputs } from '@apps/common-ui';
import * as S from './index.styles';
import { USER_GROUP_CODES } from '@apps/common-ui/src/types/constants';
import React from 'react';

type Props = {
    groupCode: string
    onChange: (groupCode: string | null) => void
};

const UserAccessSelection = ({ groupCode, onChange }: Props) => {
    const onSelect = (v) => {
        const codes = groupCode?.split('-') || [];
        if (v.target.checked) {
            codes.push(v.target.id);
        } else {
            const index = codes.indexOf(v.target.id);
            codes.splice(index, 1);
        }
        onChange(codes.length === 0 ? null : codes.sort().join('-'));
    };

    return (
        <S.AccessFieldRow>
            <S.AccessCheckboxContainer>
                <Inputs.Input
                  type="checkbox"
                  id={USER_GROUP_CODES.healthieBilling}
                  defaultChecked={groupCode?.includes(USER_GROUP_CODES.healthieBilling)}
                  onChange={onSelect}
                />
                <Inputs.Label htmlFor={USER_GROUP_CODES.healthieBilling}>Healthie Payment Portal</Inputs.Label>
            </S.AccessCheckboxContainer>
        </S.AccessFieldRow>
    );
};

export default UserAccessSelection;
