import { Buttons, Inputs } from '@apps/common-ui';
import styled from 'styled-components';

export const Container = styled.div`
    display flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.p`
    font-weight: bold;
    margin: 0 0 20px 0;
`;

export const Subtitle = styled.p`
    margin: 0 0 60px 0;
    padding: 0 25px;
    text-align: center;
`;

export const NewTime = styled.p`
    margin: 0 0 20px 0;
`;

export const ErrorMessage = styled.p`
    color: ${props => props.theme.buttons.red};
    margin: 0 0 20px 0;
    text-align: center;
`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
`;

export const ConfirmButton = styled(Buttons.Button)`
    background: ${props => props.theme.buttons.blue};
    color: ${props => props.theme.shades.white};
    width: 180px;
    height: 50px;
    white-space: nowrap;
`;

export const CancelButton = styled(Buttons.Button)`
    background: ${props => props.theme.shades.white};
    color: ${props => props.theme.buttons.blue};
    width: 180px;
    height: 50px;
`;
