import styled from 'styled-components';

export const ProgressBarBackground = styled.div<{ backgroundColor?: string, borderRadius?: string}>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : props.theme.shades.lighterGrey};
  border-radius: ${(props) => props.borderRadius ? props.borderRadius : '10px'};
`;

export const ProgressBarFill = styled.div<{ percentage: number, color?: string, borderRadius?: string}>`
  width: ${props => props.percentage}%;
  height: 100%;
  background-color: ${props => props.color || props.theme.primary.medium};
  border-radius: ${(props) => props.borderRadius ? props.borderRadius : '10px'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProgressBarTextSpan = styled.span`
  color: ${(props) => props.theme.text.white};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  padding: 0 5px;
`;

export const ProgressBarTextP = styled.p`
  position absolute;
  left: 30%;
  font-size: 12px;
  font-weight: bold;
  margin: 0px;
  color: ${(props) => props.theme.text.white};
`;
