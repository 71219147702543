import { Api } from '@apps/common-utilities/src/api/Api';
import ApiError from '@apps/common-utilities/src/api/ApiError';

class CoachingApi extends Api {
    async handleError(r: any, status: number): Promise<any> {
        // leaving because it makes debugging really easy
        // eslint-disable-next-line no-console
        if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
            console.log(`${r.message}:  ${r.errors[0]}`);
        }
        if (r.errors) {
            throw new ApiError(r.errors[0], status);
        }
        throw new ApiError(r.message, status);
    }
}
export const TenantApi = new CoachingApi(process.env.REACT_APP_TENANT_API_URL || '');
