import toast from 'react-hot-toast';
import { TenantApi } from '../api/CoachingAPI';
import { Routes } from '../api/Routes';

type PresignedUrlResponse = {
    uploadUrl: string;
    cdnUrl: string;
};

// eslint-disable-next-line arrow-body-style
const getPresignedPutUrl = async (fileName: string, contentType: string): Promise<PresignedUrlResponse> => {
    const response = await TenantApi.post<PresignedUrlResponse>(`/${Routes.coaches}/files/upload`, {
        fileName,
        contentType
    });

    if (!response || !response.uploadUrl || !response.cdnUrl) {
        throw new Error('Failed to get presigned URL');
    }

    return response;
};

// eslint-disable-next-line arrow-body-style
export const uploadFile = async (file: File): Promise<any> => {
    const randomString = Math.random().toString(36).substring(2, 32);
    const extension = file.name.split('.').pop();
    const fileName = `${randomString}.${extension}`;
    return getPresignedPutUrl(fileName, file.type).then((res: PresignedUrlResponse) => {
        const { uploadUrl, cdnUrl } = res;
        return fetch(uploadUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type,
            }
        }).then((res2: any) => `${cdnUrl}/${fileName}`);
    });
};
