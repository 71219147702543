import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ModalRouter from './ModalRouter';
import AppRouter from './AppRouter';

const RouterContainer = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                  path="/modal/*"
                  element={(
                      <div className="app-container">
                          <ModalRouter />
                      </div>
                    )}
                />
                <Route
                  path="*"
                  element={(
                      <div className="app-container">
                          <AppRouter />
                      </div>
                )}
                />
            </Routes>
        </BrowserRouter>
    );
};
export default RouterContainer;
