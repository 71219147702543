import styled from 'styled-components';

export const QuestionContainer = styled.div`
    margin-bottom: 20px;
`;

export const QuestionTitle = styled.div`
    display: flex;
    align-items: center;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Answers = styled.div`
    width: 100%;
    margin-bottom: 40px;
    button {
        margin-top: 20px;
    }
`;

export const AnswerContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    label {
        margin-right: 20px;
        width: 15%;
        cursor: pointer;
    }

    label input {
        margin-right: 15px;
    }

    label:has(input:checked) {
        color: ${props => props.theme.success.medium};
    }

    input {
        margin-right: 5px;
        max-width: none !important;
    }
`;
