import { Inputs } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import * as S from '../index.styles';
import { ExerciseTypes } from '@apps/common-utilities';
import { HeartRateValue } from '../../../../../components/ExerciseForm/types';

interface EditExerciseTargetsProps {
  showBreathlessnessRest: boolean;
  showBreathlessnessTarget: boolean;
  showSpO2TargetPercent: boolean;
  showSpO2RestPercent: boolean;
  showHeartRateRest: boolean;
  showHeartRateTarget: boolean;
  setShowTarget: (value: {
    showBreathlessnessRest?: boolean;
    showBreathlessnessTarget?: boolean;
    showSpO2TargetPercent?: boolean;
    showSpO2RestPercent?: boolean;
    requireRMTDifficultyReporting?: boolean;
    requireRMTExhaleResistanceReporting?: boolean;
    requireRMTInhaleResistanceReporting?: boolean;
    requireActivityReporting?: boolean;
    requireLowestSpO2PercentReporting?: boolean;
    requireImmediateSpO2PercentReporting?: boolean;
    requireHeartRateReporting?: boolean;
    requireBreathlessnessLevelReporting?: boolean;
    requireFlowRateReporting?: boolean;
  }) => void;
  setHeartRate: (value: HeartRateValue, rest?: boolean) => void;
  heartRateTargetMax?: number;
  heartRateTargetMin?: number;
  heartRateRest?: number;
  requireRMTDifficultyReporting: boolean;
  requireRMTExhaleResistanceReporting: boolean;
  requireRMTInhaleResistanceReporting: boolean;
  requireActivityReporting: boolean;
  requireLowestSpO2PercentReporting: boolean;
  requireImmediateSpO2PercentReporting: boolean;
  requireHeartRateReporting: boolean;
  requireBreathlessnessLevelReporting: boolean;
  requireFlowRateReporting: boolean;
  exerciseType: ExerciseTypes.ExerciseType;
}

const EditExerciseTargets = ({
    showBreathlessnessRest,
    showBreathlessnessTarget,
    showSpO2TargetPercent,
    showSpO2RestPercent,
    showHeartRateRest,
    showHeartRateTarget,
    setShowTarget,
    setHeartRate,
    heartRateTargetMax,
    heartRateTargetMin,
    heartRateRest,
    requireRMTDifficultyReporting,
    requireRMTExhaleResistanceReporting,
    requireRMTInhaleResistanceReporting,
    requireActivityReporting,
    requireFlowRateReporting,
    requireLowestSpO2PercentReporting,
    requireImmediateSpO2PercentReporting,
    requireHeartRateReporting,
    requireBreathlessnessLevelReporting,
    exerciseType,
}: EditExerciseTargetsProps) => {
    const [heartRateRestValue, setHeartRateRestValue] = useState<HeartRateValue | null>(null);
    const [heartRateTargetValue, setHeartRateValue] = useState<HeartRateValue | null>(null);

    const getHeartRateTargetValue = () => {
        if (heartRateTargetMax === 60 && heartRateTargetMin === 40) {
            return HeartRateValue.FORTY_TO_SIXTY;
        }
        if (heartRateTargetMax === 80 && heartRateTargetMin === 40) {
            return HeartRateValue.FORTY_TO_EIGHTY;
        }
        if (heartRateTargetMax === 80 && heartRateTargetMin === 60) {
            return HeartRateValue.SIXTY_TO_EIGHTY;
        }
        return null;
    };

    const getHeartRateRestValue = () => {
        if (heartRateRest === 40) {
            return HeartRateValue.BELOW_FORTY;
        }
        if (heartRateRest === 60) {
            return HeartRateValue.BELOW_SIXTY;
        }
        return null;
    };

    useEffect(() => {
        setHeartRateValue(getHeartRateTargetValue());
        setHeartRateRestValue(getHeartRateRestValue());
    }, [heartRateTargetMax, heartRateTargetMin, heartRateRest]);

    return (
        <div>
            <S.Section>
                <S.InputContainer>
                    <h4>Active Targets</h4>
                    <S.CheckboxContainer>
                        <Inputs.Checkbox
                          id="active-target-breathlessness"
                          key={`active-target-breathlessness${Math.random()}`}
                          onChange={(e) => setShowTarget({
                    showBreathlessnessTarget: !showBreathlessnessTarget
                  })}
                          activeText="Breathlessness (Between 4-6)"
                          inactiveText="Breathlessness (Between 4-6)"
                          defaultChecked={showBreathlessnessTarget}
                        />
                        <Inputs.Select
                          size="medium"
                          value={heartRateTargetValue}
                          onChange={(e) => setHeartRate(e.target.value, false)}
                        >
                            <option>Hide Heart Rate</option>
                            <option value={HeartRateValue.FORTY_TO_SIXTY}>40-60%</option>
                            <option value={HeartRateValue.FORTY_TO_EIGHTY}>40-80%</option>
                            <option value={HeartRateValue.SIXTY_TO_EIGHTY}>60-80%</option>
                        </Inputs.Select>
                        <Inputs.Checkbox
                          id="active-target-spo2"
                          key={`active-target-spo2${Math.random()}`}
                          onChange={(e) => setShowTarget({
                        showSpO2TargetPercent: !showSpO2TargetPercent
                      })}
                          activeText="SpO2"
                          inactiveText="SpO2"
                          defaultChecked={showSpO2TargetPercent}
                        />
                    </S.CheckboxContainer>
                </S.InputContainer>
                <S.InputContainer>
                    <h4>Rest Targets</h4>
                    <S.CheckboxContainer>
                        <Inputs.Checkbox
                          id="rest-target-breathlessness"
                          key={`rest-target-breathlessness${Math.random()}`}
                          onChange={(e) => setShowTarget({
                    showBreathlessnessRest: !showBreathlessnessRest
                  })}
                          activeText="Breathlessness (less than 4/10)"
                          inactiveText="Breathlessness (less than 4/10)"
                          defaultChecked={showBreathlessnessRest}
                        />
                        <Inputs.Select
                          size="medium"
                          value={heartRateRestValue}
                          onChange={(e) => setHeartRate(e.target.value, true)}
                        >
                            <option>Hide Heart Rate</option>
                            <option value={HeartRateValue.BELOW_FORTY}>Below 40%</option>
                            <option value={HeartRateValue.BELOW_SIXTY}>Below 60%</option>
                        </Inputs.Select>
                        <Inputs.Checkbox
                          id="rest-target-spo2"
                          key={`rest-target-spo2${Math.random()}`}
                          onChange={(e) => setShowTarget({
                    showSpO2RestPercent: !showSpO2RestPercent
                  })}
                          activeText="SpO2"
                          inactiveText="SpO2"
                          defaultChecked={showSpO2RestPercent}
                        />
                    </S.CheckboxContainer>
                </S.InputContainer>
            </S.Section>
            <h4>Submission Options</h4>
            <S.Section>
                <S.InputContainer>
                    <S.CheckboxContainer>
                        <Inputs.Checkbox
                          id="require-sets-reps"
                          key={`require-sets-reps${Math.random()}`}
                          onChange={(e) => setShowTarget({
                            requireActivityReporting: !requireActivityReporting
                          })}
                          activeText="Require activity in submission"
                          inactiveText="Require activity in submission"
                          defaultChecked={requireActivityReporting}
                        />
                    </S.CheckboxContainer>
                    <S.CheckboxContainer>
                        <Inputs.Checkbox
                          id="require-heart-rate"
                          key={`require-heart-rate${Math.random()}`}
                          onChange={(e) => setShowTarget({
                            requireHeartRateReporting: !requireHeartRateReporting
                          })}
                          activeText="Require heart rate in submission"
                          inactiveText="Require heart rate in submission"
                          defaultChecked={requireHeartRateReporting}
                        />
                    </S.CheckboxContainer>
                    <S.CheckboxContainer>
                        <Inputs.Checkbox
                          id="require-spO2-immediate"
                          key={`require-spO2-immediate${Math.random()}`}
                          onChange={(e) => setShowTarget({
                            requireImmediateSpO2PercentReporting: !requireImmediateSpO2PercentReporting
                          })}
                          activeText="Require SpO2 immediate in submission"
                          inactiveText="Require SpO2 immediate in submission"
                          defaultChecked={requireImmediateSpO2PercentReporting}
                        />
                    </S.CheckboxContainer>
                    <S.CheckboxContainer>
                        <Inputs.Checkbox
                          id="require-spO2-lowest"
                          key={`require-spO2-lowest${Math.random()}`}
                          onChange={(e) => setShowTarget({
                            requireLowestSpO2PercentReporting: !requireLowestSpO2PercentReporting
                          })}
                          activeText="Require SpO2 lowest in submission"
                          inactiveText="Require SpO2 lowest in submission"
                          defaultChecked={requireLowestSpO2PercentReporting}
                        />
                    </S.CheckboxContainer>
                    <S.CheckboxContainer>
                        <Inputs.Checkbox
                          id="require-oxygen"
                          key={`require-oxygen${Math.random()}`}
                          onChange={(e) => setShowTarget({
                            requireFlowRateReporting: !requireFlowRateReporting
                          })}
                          activeText="Require oxygen in submission"
                          inactiveText="Require oxygen in submission"
                          defaultChecked={requireFlowRateReporting}
                        />
                    </S.CheckboxContainer>
                    <S.CheckboxContainer>
                        <Inputs.Checkbox
                          id="require-breathlessness"
                          key={`require-breathlessness${Math.random()}`}
                          onChange={(e) => setShowTarget({
                            requireBreathlessnessLevelReporting: !requireBreathlessnessLevelReporting
                          })}
                          activeText="Require breathlessness in submission"
                          inactiveText="Require breathlessness in submission"
                          defaultChecked={requireBreathlessnessLevelReporting}
                        />
                    </S.CheckboxContainer>
                </S.InputContainer>
                <S.InputContainer>
                    {exerciseType === ExerciseTypes.ExerciseType.SETS_AND_BREATHS && (
                        <S.InputColumn>
                            <S.CheckboxContainer>
                                <Inputs.Checkbox
                                  id="require-inhalation-resistance"
                                  key={`require-inhalation-resistance${Math.random()}`}
                                  onChange={(e) => setShowTarget({
                                    requireRMTInhaleResistanceReporting: !requireRMTInhaleResistanceReporting
                                  })}
                                  activeText="Require inhale resistance in submission"
                                  inactiveText="Require inhale resistance in submission"
                                  defaultChecked={requireRMTInhaleResistanceReporting}
                                />
                            </S.CheckboxContainer>
                            <S.CheckboxContainer>
                                <Inputs.Checkbox
                                  id="require-exhalation-resistance"
                                  key={`require-exhalation-resistance${Math.random()}`}
                                  onChange={(e) => setShowTarget({
                                    requireRMTExhaleResistanceReporting: !requireRMTExhaleResistanceReporting
                                  })}
                                  activeText="Require exhale resistance in submission"
                                  inactiveText="Require exhale resistance in submission"
                                  defaultChecked={requireRMTExhaleResistanceReporting}
                                />
                            </S.CheckboxContainer>
                            <S.CheckboxContainer>
                                <Inputs.Checkbox
                                  id="require-rmt-breathing-difficulty"
                                  key={`require-rmt-breathing-difficulty${Math.random()}`}
                                  onChange={(e) => setShowTarget({
                                    requireRMTDifficultyReporting: !requireRMTDifficultyReporting
                                  })}
                                  activeText="Require RMT breathing difficulty in submission"
                                  inactiveText="Require RMT breathing difficulty in submission"
                                  defaultChecked={requireRMTDifficultyReporting}
                                />
                            </S.CheckboxContainer>
                        </S.InputColumn>
            )}
                </S.InputContainer>
            </S.Section>
        </div>
    );
};

export default EditExerciseTargets;
