import React, { useEffect, useState } from 'react';
import { Buttons, useModal, Spinner } from '@apps/common-ui';
import * as S from './index.styles';
import { LearningTypes } from '@apps/common-utilities';
import { useNavigate, useParams } from 'react-router';
import { TenantApi } from '../../api/CoachingAPI';
import PauseCourseModal from '../ManageEducationPlan/components/PauseCourseModal';
import ModuleSummarySubmission from './components/ModuleSummarySubmission';

const LearningReporting = () => {
    const { closeModal, isOpen, openModal } = useModal();
    const { patientId } = useParams();
    const [courseSubmission, setCourseSubmission] = useState<LearningTypes.ICourseReport | null>(null);
    const [courseSummary, setCourseSummary] = useState<LearningTypes.ICourseSummary | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const getCourseProgress = () => {
        TenantApi.get(`/coaches/users/${patientId}/education/course-assignments/report/full`).then((res) => {
            setCourseSubmission(res);
            setLoading(false);
        }).catch(() => {
            // no course found
            setCourseSubmission(null);
            setLoading(false);
        });
        TenantApi.get(`/coaches/users/${patientId}/education/course-assignments/report/summary`).then((res) => {
            setCourseSummary(res);
        }).catch(() => {
            // no course found
            setCourseSummary(null);
        });
    };

    useEffect(() => {
        getCourseProgress();
    }, [patientId, isOpen]);

    return (
        <>
            <PauseCourseModal
              showModal={isOpen}
              dismissModal={closeModal}
              courseId={courseSummary?.courseUserAssignmentId || 0}
              isPaused={courseSummary?.isPaused || false}
              userId={patientId || ''}
            />
            <div>
                {!loading && courseSubmission && (
                <>
                    <S.Header>
                        <h1>Course Progress</h1>
                        <S.ButtonContainer>
                            <Buttons.Button onClick={() => navigate(`/patients/${patientId}/education-plan`)}>Edit Course</Buttons.Button>
                            <Buttons.Button onClick={openModal}>Manage User Program</Buttons.Button>
                        </S.ButtonContainer>
                    </S.Header>
                    <h2>{courseSubmission.title}</h2>
                    {courseSubmission.modules.map((module) => (
                        <ModuleSummarySubmission
                          patientId={patientId || ''}
                          moduleSubmission={module}
                        />
                ))}
                </>
                )}
                {!loading && !courseSubmission && (
                <div>
                    <h1>No Course Found</h1>
                </div>
                )}
                {loading && (
                <S.Container>
                    <Spinner />
                </S.Container>
            )}
            </div>
        </>
    );
};

export default LearningReporting;
