export const KarvonenFormula = (age: number, restingHR: number, betaBlockers: boolean, percentage: number) => {
    // Karvonen Formula
    // calculate heart rate
    // with or without beta blockers
    // at a percentage of max heart rate
    // https://www.notion.so/breathesuite/Coaching-Calculations-95b74135ae8d4c5288ea2b054f50af0c
    let maxHR = 0;
    if (betaBlockers) {
        maxHR = 164 - (0.7 * age);
    } else {
        maxHR = 206.9 - (0.67 * age);
    }
    return Math.round(((maxHR - restingHR) * (percentage / 100)) + restingHR);
};


/***
 * Convert a camel case string to title Case
 * @example "helloWorld" => "Hello World"
 */
export const camelCaseToTitleCase = (str: string) => { 
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
}
