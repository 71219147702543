import React, { useEffect, useState } from 'react';
import * as SParent from '../../index.styles';
import * as S from './index.styles';
import { useParams } from 'react-router';
import { differenceInCalendarWeeks, format } from 'date-fns';
import { ProgramStatusIndicator } from '../../../../../../components/common/commonStyled';
import { NaiveDate } from '../../../../../../utils/dateUtils';
import { exercisePlanTypeLabel } from '../../../../../../components/ExerciseForm/utils';
import { useActionLoader } from '../../../../../../hooks/useActionLoader';
import { RootState } from '../../../../../../state/store';
import { useSelector } from 'react-redux';
import { fetchExerciseScheduledAppointmentSummary, fetchExerciseSummary } from '../../../../../../state/reducers/physicalTherapy';

const ExercisePlanProgress = () => {
    const { exerciseScheduledAppointmentSummary } = useSelector((state: RootState) => state.physicalTherapy);
    const { callAction: getExerciseAppointmentsAttended } = useActionLoader(fetchExerciseScheduledAppointmentSummary);
    const {
        callAction: getExerciseSummary,
        done: exerciseSummaryDone,
        loading: exerciseSummaryLoading
    } = useActionLoader(fetchExerciseSummary);
    const { patientId } = useParams();
    const { exerciseSummary, totalExercises, categorySummary } = useSelector((state: RootState) => state.physicalTherapy);

    let exerciseProgramLength = 0;
    let currentWeek = 0;
    if (exerciseSummary && exerciseSummary.endDate !== null) {
        const startDate = new Date(exerciseSummary.startDate);
        const endDate = new Date(exerciseSummary.endDate);

        exerciseProgramLength = differenceInCalendarWeeks(endDate, startDate, { weekStartsOn: 1 });
        currentWeek = differenceInCalendarWeeks(new Date(), startDate) + 1;
    }

    useEffect(() => {
        if (patientId && !exerciseSummaryDone && !exerciseSummaryLoading) {
            getExerciseSummary(patientId);
            getExerciseAppointmentsAttended(patientId);
        }
    }, [patientId]);

    return (
        <>
            {null}
            { exerciseSummary && (
                <>
                    <SParent.Header>EXERCISE PLAN PROGRESS</SParent.Header>
                    <SParent.PatientInfoRow gap="70px">
                        {exerciseSummary && (exerciseSummary.complete || !exerciseSummary.endDate) && (
                        <SParent.PatientInfoColumn>
                            <S.ExerciseRowBlock>
                                <S.ExerciseFlexRow>
                                    <ProgramStatusIndicator status={exerciseSummary.endDate ? 'success' : 'danger'} style={{ minWidth: '200px', width: 'auto', padding: '5px 10px' }}>
                                        {exercisePlanTypeLabel(exerciseSummary.exercisePlanType)}
                                    </ProgramStatusIndicator>
                                    {exerciseSummary.complete && (
                                    <ProgramStatusIndicator status="danger">
                                        Discharged
                                    </ProgramStatusIndicator>
                                            )}
                                    {exerciseSummary.paused && (
                                    <ProgramStatusIndicator status="danger">
                                        Disabled
                                    </ProgramStatusIndicator>
                                            )}
                                </S.ExerciseFlexRow>
                            </S.ExerciseRowBlock>
                            <S.ExerciseRowBlock>
                                <S.ExerciseFlexRow>
                                    <S.ExerciseRowItem>{exerciseSummary.dischargeDate ? 'Discharge' : 'Start'} Date:</S.ExerciseRowItem>
                                    <S.ExerciseRowItem size="large"><strong>{format(new NaiveDate(exerciseSummary.dischargeDate ? exerciseSummary.dischargeDate : exerciseSummary.startDate), 'do MMMM yyyy')}</strong></S.ExerciseRowItem>
                                </S.ExerciseFlexRow>
                            </S.ExerciseRowBlock>
                        </SParent.PatientInfoColumn>
                        )}
                        {exerciseSummary.endDate !== null && !exerciseSummary.dischargeDate && (
                        <S.MultiColumnContainer>
                            <SParent.PatientInfoColumn>
                                <S.ExerciseRowBlock>
                                    <S.ExerciseFlexRow>
                                        <ProgramStatusIndicator status="success">
                                            {exercisePlanTypeLabel(exerciseSummary.exercisePlanType)}
                                        </ProgramStatusIndicator>
                                        <ProgramStatusIndicator status={exerciseSummary.paused ? 'danger' : 'success'}>
                                            {exerciseSummary.paused ? 'Disabled' : 'Active'}
                                        </ProgramStatusIndicator>
                                    </S.ExerciseFlexRow>
                                </S.ExerciseRowBlock>
                                <S.ExerciseRowBlock>
                                    <S.ExerciseFlexRow>
                                        <S.ExerciseRowItem>Plan of Care Start:</S.ExerciseRowItem>
                                        <S.ExerciseRowItem><strong>{format(new NaiveDate(exerciseSummary.startDate), 'do MMMM yyyy')}</strong></S.ExerciseRowItem>
                                    </S.ExerciseFlexRow>
                                    <S.ExerciseFlexRow>
                                        <S.ExerciseRowItem>Plan of Care End:</S.ExerciseRowItem>
                                        <S.ExerciseRowItem><strong>{format(new NaiveDate(exerciseSummary.endDate as string), 'do MMMM yyyy')}</strong></S.ExerciseRowItem>
                                    </S.ExerciseFlexRow>
                                </S.ExerciseRowBlock>
                                <S.ExerciseRowBlock>
                                    <S.ExerciseFlexRow>Plan of Care Progress</S.ExerciseFlexRow>
                                    <S.ExerciseFlexRow>
                                        <S.ExerciseRowItem><strong>Week {currentWeek} of {exerciseProgramLength}</strong></S.ExerciseRowItem>
                                        <S.ExerciseRowItem>
                                            <S.ExerciseProgressContainer>
                                                <S.ExerciseProgressLine filledAmount={`${Math.min((currentWeek / exerciseProgramLength) * 100, 100).toString()}%`} />
                                            </S.ExerciseProgressContainer>
                                        </S.ExerciseRowItem>
                                    </S.ExerciseFlexRow>
                                </S.ExerciseRowBlock>
                                <S.ExerciseRowBlock>
                                    <S.ExerciseFlexRow>
                                        <S.ExerciseRowItem>
                                            <strong>Total&nbsp;Exercise&nbsp;Adherence:</strong>
                                        </S.ExerciseRowItem>
                                        <S.ExerciseRowItem><strong>{totalExercises}</strong></S.ExerciseRowItem>
                                    </S.ExerciseFlexRow>
                                    {categorySummary && Object.entries(categorySummary)
                                    .map(([category, adherence]) => (
                                        <S.ExerciseFlexRow key={category}>
                                            <S.ExerciseRowItem>{category}:</S.ExerciseRowItem>
                                            <S.ExerciseRowItem>{adherence}</S.ExerciseRowItem>
                                        </S.ExerciseFlexRow>
                                        ))}
                                </S.ExerciseRowBlock>
                            </SParent.PatientInfoColumn>
                            <SParent.PatientInfoColumn maxWidth="450px">
                                <S.ExerciseRowBlock>
                                    <S.ExerciseRowBlockTitle>Total (1 on 1 Only)</S.ExerciseRowBlockTitle>
                                    <S.ExerciseFlexRow>
                                        <S.ExerciseRowItem size="large">PT Appointments Attended:</S.ExerciseRowItem>
                                        <S.ExerciseRowItem>{exerciseScheduledAppointmentSummary?.totalIndividualAppointmentsAttended}</S.ExerciseRowItem>
                                    </S.ExerciseFlexRow>
                                    <S.ExerciseFlexRow>
                                        <S.ExerciseRowItem size="large">Appointments Rescheduled:</S.ExerciseRowItem>
                                        <S.ExerciseRowItem>{exerciseScheduledAppointmentSummary?.totalIndividualAppointmentsRescheduled}</S.ExerciseRowItem>
                                    </S.ExerciseFlexRow>
                                    <S.ExerciseFlexRow>
                                        <S.ExerciseRowItem size="large">Appointments Missed/No-Showed:</S.ExerciseRowItem>
                                        <S.ExerciseRowItem>{exerciseScheduledAppointmentSummary?.totalIndividualAppointmentsMissedOrNoShowed}</S.ExerciseRowItem>
                                    </S.ExerciseFlexRow>
                                </S.ExerciseRowBlock>
                                <S.ExerciseRowBlock>
                                    <S.ExerciseRowBlockTitle>Since Last Assessment</S.ExerciseRowBlockTitle>
                                    <S.ExerciseFlexRow>
                                        <S.ExerciseRowItem size="large"><strong>PT Appointments Attended:</strong></S.ExerciseRowItem>
                                        <S.ExerciseRowItem><strong>{exerciseScheduledAppointmentSummary?.appointmentsAttendedSinceLastEval}</strong></S.ExerciseRowItem>
                                    </S.ExerciseFlexRow>
                                    <S.ExerciseFlexRow>
                                        <S.ExerciseRowItem size="large" indent>1 on 1 Appointments:</S.ExerciseRowItem>
                                        <S.ExerciseRowItem>{exerciseScheduledAppointmentSummary?.oneOnOneAppointmentsSinceLastEval}</S.ExerciseRowItem>
                                    </S.ExerciseFlexRow>
                                    <S.ExerciseFlexRow>
                                        <S.ExerciseRowItem size="large" indent>Group Appointments:</S.ExerciseRowItem>
                                        <S.ExerciseRowItem>{exerciseScheduledAppointmentSummary?.groupAppointmentsSinceLastEval}</S.ExerciseRowItem>
                                    </S.ExerciseFlexRow>
                                </S.ExerciseRowBlock>
                            </SParent.PatientInfoColumn>
                        </S.MultiColumnContainer>
                        )}
                    </SParent.PatientInfoRow>
                </>
            )}
        </>
    );
};

export default ExercisePlanProgress;
