import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

interface TimerValueProps {
    isStopped: boolean;
}

export const Container = styled.div`
    display flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.p`
    font-weight: bold;
    font-size: 22px;
    margin: 0 0 20px 0;
`;

export const TimerValue = styled.p<TimerValueProps>`
    font-weight: bold;
    font-size: 22px;
    color: ${props => props.isStopped ? props.theme.buttons.red : props.theme.shades.black};
    margin: 0 0 20px 0;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    gap: 15px;
`;

export const ContinueButton = styled(Buttons.Button)`
    width: 180px;
    height: 50px;
`;

export const StopButton = styled(Buttons.Button)`
    width: 180px;
    height: 50px;
    background: ${props => props.theme.buttons.red};
    border: 2px solid ${props => props.theme.buttons.red};
`;

export const Message = styled.p`
    font-size: 12px;
    width: 400px;
    margin: 0;
    text-align: center;
`;
