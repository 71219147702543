import React from 'react';
import { WEEKDAYS } from '../../../../../types/constants';
import { ExerciseFunctions } from '../../../../../types/models';
import * as S from '../index.styles';

const CalendarTooltip = (
    { exerciseFunctions, dayOfWeek }: {exerciseFunctions: ExerciseFunctions, dayOfWeek: string}
) => (
    <S.CalendarTooltipContainer>
        {Object.keys(WEEKDAYS).map((day) => (
            <S.CalendarTooltipItem
              key={day}
              onClick={() => exerciseFunctions.cloneDay(dayOfWeek, day)}
            >
                Clone to {WEEKDAYS[day]}
            </S.CalendarTooltipItem>
            ))}
        <S.CalendarTooltipItem danger onClick={() => exerciseFunctions.removeDay(dayOfWeek)}>
            Remove Day
        </S.CalendarTooltipItem>
    </S.CalendarTooltipContainer>
);

export default CalendarTooltip;
