import React from 'react';
import { ActionPlanZoneType, MedicalCondition } from '../../../types/models';
import ActionPlanZone from '../components/ActionPlanZone';
import CopdOxygenUseInput from '../components/FlowRateInputs';
import CopdSymptoms from '../components/CopdSymptoms';
import ProviderInfo from '../components/ProviderInfo';

const CopdActionPlan = () => (
    <div>
        <ProviderInfo />
        <ActionPlanZone
          zoneType={ActionPlanZoneType.GREEN}
          upperExtra={<CopdSymptoms />}
          flowRate={<CopdOxygenUseInput zoneType={ActionPlanZoneType.GREEN} condition={MedicalCondition.COPD} />}
        />
        <ActionPlanZone
          zoneType={ActionPlanZoneType.YELLOW}
          flowRate={<CopdOxygenUseInput zoneType={ActionPlanZoneType.YELLOW} condition={MedicalCondition.COPD} />}
        />
        <ActionPlanZone
          zoneType={ActionPlanZoneType.RED}
          flowRate={<CopdOxygenUseInput zoneType={ActionPlanZoneType.RED} condition={MedicalCondition.COPD} />}
        />
    </div>
);

export default CopdActionPlan;
