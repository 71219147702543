import styled from 'styled-components';

export const InputContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin: 10px 0;

    input {
        width: 80%;
    }
`;

export const ZoneNote = styled.p`
    margin-bottom: 40px;
`;
