import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    background-color: white;
`;

export const TaskContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid ${props => props.theme.shades.lightGrey};
    width: 100%;
    border-radius: 5px;
`;

export const TaskContent = styled.p`
    margin: 0;
    font-weight: bold;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 20px;
    button {
        width: 175px;
    }
`;
