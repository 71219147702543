import React, { useState } from 'react';
import { ActionplanInputType, ActionPlanZoneType, IActionPlanItem } from '../../../../types/models';
import { ZoneSectionContainer, ZoneSectionHeader, ActionPlanLink, ActionMedicationInput } from '../common';
import * as S from './index.styles';
import { theme } from '@apps/common-ui';

type Props = {
    actions: IActionPlanItem[];
    zoneType: ActionPlanZoneType;
    updateActions: (actions: IActionPlanItem[]) => void;
    disabled: boolean;
}

const Actions = ({ actions, zoneType, updateActions, disabled }: Props) => {
    const [todos, setTodos] = useState<IActionPlanItem[]>(actions);

    const onActionChange = (index: number, action: Partial<IActionPlanItem>) => {
        const newTodos = [...todos];
        newTodos[index] = {
            ...newTodos[index],
            ...action
        };
        setTodos(newTodos);
        updateActions(newTodos);
    };

    const addNewItem = () => {
        const newTodos = [...todos, { value: '', inputType: ActionplanInputType.ACTION }];
        setTodos(newTodos);
        updateActions(newTodos);
    };

    const removeItem = (index: number) => {
        const newTodos = [...todos];
        newTodos.splice(index, 1);
        setTodos(newTodos);
        updateActions(newTodos);
    };

    return (
        <ZoneSectionContainer>
            {zoneType === ActionPlanZoneType.YELLOW && (
                <S.ZoneNote>
                    Note: All patients will be asked in app to contact their primary
                    care provider when they are in the yellow zone
                </S.ZoneNote>
            )}
            {zoneType === ActionPlanZoneType.RED && (
                <S.ZoneNote>
                    Note: All patients will be asked in app to call 911 when they are in the red zone
                </S.ZoneNote>
            )}
            <ZoneSectionHeader>Actions to take when in the {zoneType.toLowerCase()} zone</ZoneSectionHeader>
            {todos.map((action, index) => (
                <S.InputContainer key={action.id || index}>
                    <ActionMedicationInput
                      value={action.value}
                      onChange={e => onActionChange(index, { value: e.target.value })}
                      disabled={disabled}
                      placeholder="Action to take"
                    />
                    <ActionPlanLink
                      color={theme.links.red}
                      onClick={() => removeItem(index)}
                    >Remove
                    </ActionPlanLink>
                </S.InputContainer>
            ))}
            <ActionPlanLink
              color={theme.links.blue}
              marginLeft="10"
              onClick={addNewItem}
            >+ Add item
            </ActionPlanLink>
        </ZoneSectionContainer>
    );
};

export default Actions;
