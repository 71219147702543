import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useReducer, useState } from 'react';
import { addDays, addWeeks, eachDayOfInterval, endOfWeek, format, isAfter, isBefore, isMonday, isSameDay, isSameWeek, nextMonday, previousMonday, startOfWeek } from 'date-fns';
import Charts from './components/Charts';
import * as S from './index.styles';
import { Buttons } from '@apps/common-ui';
import { ExerciseTypes } from '@apps/common-utilities';
import { useParams } from 'react-router';
import { Routes } from '../../api/Routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { useActionLoader } from '../../hooks/useActionLoader';
import { fetchExerciseSummary } from '../../state/reducers/physicalTherapy';
import toast from 'react-hot-toast';
import WeekHeader from './components/WeekHeader';
import { isDayOutsideOfPlan } from './reportingUtils';
import GeneralReporting from './GeneralReporting';
import WeekSummary from './components/WeekSummary';
import RMTReporting from './RMTReporting';
import { TenantApi } from '../../api/CoachingAPI';

// Fetch exercises from API
// store locally
const ExerciseReporting = ({ rmt }: {rmt: boolean}) => {
    const { callAction: getExerciseSummary } = useActionLoader(fetchExerciseSummary);
    const { exerciseSummary } = useSelector((state: RootState) => state.physicalTherapy);
    const { patientId } = useParams();
    const [viewingWeekStart, setViewingWeekStart] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
    const [viewingDay, setViewingDay] = useState(new Date());
    const [viewingWeek, setViewingWeek] = useState(eachDayOfInterval({
        start: viewingWeekStart,
        end: endOfWeek(viewingWeekStart, { weekStartsOn: 1 })
    }));
    const [dailyReportingData, setDailyReportingData] = useState<ExerciseTypes.IExerciseReport | ExerciseTypes.IExerciseRMTReport | null>(null);
    const [weeklyReportingData, setWeeklyReportingData] = useState<ExerciseTypes.IExerciseReportWeek | null>(null);

    const handleChangeDay = (day: Date) => {
        if (exerciseSummary) {
            setViewingDay(day);
        }
    };

    const handleChangeWeek = () => {
        const endDate = format(endOfWeek(viewingWeekStart, { weekStartsOn: 1 }), 'yyyy-MM-dd');
        const startDate = format(viewingWeekStart, 'yyyy-MM-dd');

        if (!rmt) {
            TenantApi
                .get(`/${Routes.coaches}/${Routes.users}/${patientId}/${Routes.exercisePlans}/exercises/reports?startDate=${startDate}&endDate=${endDate}`)
                .then(response => {
                    setWeeklyReportingData(response);
                })
                .catch(error => {
                    toast.error('Error fetching exercise reporting data');
                });
        } else {
            TenantApi
                .get(`/${Routes.coaches}/${Routes.users}/${patientId}/${Routes.exercisePlans}/rmt/reports?startDate=${startDate}&endDate=${endDate}`)
                .then(response => {
                    setWeeklyReportingData(response);
                })
                .catch(error => {
                    toast.error('Error fetching RMT reporting data');
                });
        }
        setViewingWeek(eachDayOfInterval({
            start: startOfWeek(new Date(viewingWeekStart), { weekStartsOn: 1 }),
            end: endOfWeek(new Date(viewingWeekStart), { weekStartsOn: 1 })
        }));
    };

    useEffect(() => {
        setViewingWeekStart(startOfWeek(viewingWeekStart, { weekStartsOn: 1 }));
        setViewingWeek(eachDayOfInterval({
            start: viewingWeekStart,
            end: endOfWeek(viewingWeekStart, { weekStartsOn: 1 })
        }));
    }, []);

    useEffect(() => {
        getExerciseSummary(patientId);
    }, []);

    useEffect(() => {
        handleChangeWeek();
    }, [viewingWeekStart, exerciseSummary]);

    useEffect(() => {
        if (weeklyReportingData) {
            const weekday = format(viewingDay, 'EEEE').toLowerCase();
            setDailyReportingData(weeklyReportingData[weekday]);
        }
    }, [viewingDay, exerciseSummary, weeklyReportingData]);

    useEffect(() => {
        setViewingWeekStart(startOfWeek(new Date(), { weekStartsOn: 1 }));
    }, [rmt]);

    return (
        <div>
            <S.HeaderContainer>
                {exerciseSummary && (
                <>
                    <S.HeaderRow>
                        <h3>{rmt ? 'RMT Reporting' : 'Exercise Reporting'}</h3>
                        <WeekHeader
                          onThisWeekClicked={() => {
                            if (isMonday(new Date())) {
                                setViewingWeekStart(new Date());
                                setViewingDay(new Date());
                            } else {
                                setViewingWeekStart(previousMonday(new Date()));
                                setViewingDay(new Date());
                            }
                          }}
                          onPreviousWeekClicked={
                            () => setViewingWeekStart(new Date(previousMonday(viewingWeekStart).setHours(0, 0, 0, 0)))
                          }
                          onNextWeekClicked={
                            () => setViewingWeekStart(new Date(nextMonday(viewingWeekStart).setHours(0, 0, 0, 0)))
                          }
                          weekText={
                            `${format(viewingWeekStart, 'do MMMM')} - ${format(endOfWeek(viewingWeekStart, { weekStartsOn: 1 }), 'do MMMM yyyy')}`
                          }
                          exerciseSummary={exerciseSummary}
                        />
                    </S.HeaderRow>
                    <WeekSummary
                      viewingDay={viewingDay}
                      handleChangeDay={handleChangeDay}
                      viewingWeek={viewingWeek}
                      exerciseSummary={exerciseSummary}
                      weeklyReportingData={weeklyReportingData}
                    />
                </>
                )}
            </S.HeaderContainer>
            {dailyReportingData && !rmt && (
                <GeneralReporting
                  dailyReportingData={dailyReportingData as ExerciseTypes.IExerciseReport}
                  viewingDay={viewingDay}
                  rmt={false}
                />
            )}
            {dailyReportingData && rmt && (
                <RMTReporting
                  dailyReportingData={dailyReportingData as ExerciseTypes.IExerciseRMTReport}
                  viewingDay={viewingDay}
                />
            )}
            {!dailyReportingData && (
                <S.NoDataContainer>
                    <h3>No assigned exercises for selected day</h3>
                </S.NoDataContainer>
            )}
        </div>
    );
};

export default ExerciseReporting;
