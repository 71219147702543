import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { RootState } from '../../../state/store';
import * as S from '../index.styles';
import { ExerciseTypes } from '@apps/common-utilities';
import Charts from '../components/Charts';
import { getPercentageCompletionPerSession, rmtDifficultyColor } from '../reportingUtils';
import { ProgressBar } from '@apps/common-ui';
import Comments from '../components/Comments';

interface Props {
  viewingDay: Date;
  dailyReportingData: ExerciseTypes.IExerciseRMTReport;
}
const RMTReporting = ({ dailyReportingData, viewingDay }: Props) => {
    return (
        <S.Reporting>
            <S.DailyInfoContainer>
                <div>
                    <h3>{format(viewingDay, 'do MMMM yyyy')}</h3>
                    <S.WarningText>{dailyReportingData.warnings} Warnings</S.WarningText>
                </div>
                <S.DailyInfo>Extra Sessions Completed: {dailyReportingData.additionalExercisesCompleted}</S.DailyInfo>
                <S.DailyInfo>Sessions Completed: {dailyReportingData.todaysExercisesCompleted} / {dailyReportingData.exercisesAssigned}</S.DailyInfo>
                <S.DailyInfo>Session Completion Percent: {dailyReportingData.setsCompletedPercentage}%</S.DailyInfo>
            </S.DailyInfoContainer>
            <S.SessionChartContainer>
                {dailyReportingData?.rmtData?.map((chartData, index) => (
                    <>
                        <S.RMTChartRow>
                            <S.RMTChartContainer>
                                <ProgressBar
                                  percentage={getPercentageCompletionPerSession(chartData.results, chartData.assignedAmount)}
                                  color={rmtDifficultyColor(chartData.rmtDifficulty)}
                                />
                            </S.RMTChartContainer>
                            <S.RMTDetailsContainer>
                                <div style={{ marginLeft: '10px' }}>
                                    <h4>Submission Details:</h4>
                                    <S.RMTReportingSummary>
                                        <p style={{ color: rmtDifficultyColor(chartData.rmtDifficulty) }}>
                                            Difficulty:
                                            <b>
                                                {chartData.rmtDifficulty}
                                            </b>
                                        </p>
                                        <p
                                          style={{
                                            color: chartData.rmtInhaleResistanceAssigned === chartData.rmtInhaleResistanceCompleted ? 'green' : 'orange'
                                        }}
                                        >
                                            Inhale Resistance: <b>{chartData.rmtInhaleResistanceCompleted}/{chartData.rmtInhaleResistanceAssigned}</b>
                                        </p>
                                        <p
                                          style={{
                                            color: chartData.rmtExhaleResistanceAssigned === chartData.rmtExhaleResistanceCompleted ? 'green' : 'orange'
                                        }}
                                        >
                                            Exhale Resistance: <b>{chartData.rmtExhaleResistanceCompleted}/{chartData.rmtExhaleResistanceAssigned}</b>
                                        </p>
                                    </S.RMTReportingSummary>
                                </div>
                                <div style={{ marginLeft: '10px' }}>
                                    <h4>Reported Vitals:</h4>
                                    <S.RMTReportingSummary>
                                        {chartData.immediateSpO2Percent && <p>Immediate SpO2: {chartData.immediateSpO2Percent}%</p>}
                                        {chartData.lowestSpO2Percent && <p>Lowest SpO2: {chartData.lowestSpO2Percent}%</p>}
                                        {chartData.heartRate && <p>Heart Rate: {chartData.heartRate} bpm</p>}
                                        {chartData.flowRate && <p>Flow Rate: {chartData.flowRate} L/min</p>}
                                        {chartData.breathlessnessLevel && <p>Breathlessness: {chartData.breathlessnessLevel}</p>}
                                        {chartData.bpSystolic && <p>bpSystolic: {chartData.bpSystolic}</p>}
                                        {chartData.bpDiastolic && <p>bpDiastolic: {chartData.bpDiastolic}</p>}
                                    </S.RMTReportingSummary>
                                </div>
                            </S.RMTDetailsContainer>
                        </S.RMTChartRow>
                        <S.RMTCommentRow>
                            <S.RMTCommentTitle>Comment</S.RMTCommentTitle>
                            <Comments.CommentExercise comment={chartData.comment} />
                        </S.RMTCommentRow>
                    </>
                ))}
            </S.SessionChartContainer>
        </S.Reporting>
    );
};

export default RMTReporting;
