import { types } from '@apps/common-utilities';

export enum MedicalCondition {
    ASTHMA = 'ASTHMA',
    COPD = 'COPD',
    ILD = 'ILD',
    BRONCHIECTASIS = 'BRONCHIECTASIS',
    PULMONARY_HYPERTENSION = 'PULMONARY_HYPERTENSION',
    CYSTIC_FIBROSIS = 'CYSTIC_FIBROSIS',
    POST_COVID = 'POST_COVID',
}

export enum ActionPlanZoneType {
    GREEN = 'GREEN',
    YELLOW = 'YELLOW',
    RED = 'RED'
}

export enum ActionplanInputType {
    MEDICATION = 'MEDICATION',
    ACTION = 'ACTION'
}

export enum PhlegmColor {
    CLEAR = 'CLEAR',
    WHITE = 'WHITE',
    YELLOW_WHITE = 'YELLOW_WHITE',
    YELLOW_GREEN = 'YELLOW_GREEN',
    GREEN = 'GREEN'
}

export interface IUser {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    weight: string;
    height: string;
    dateOfBirth: string;
    sexAssignedAtBirth: string;
    phoneNumber: string;
    medicalCondition: MedicalCondition | null;
    groupCode: string;
    primaryRespiratoryTherapist: ICoach | null;
    primaryPhysicalTherapist: ICoach | null;
    primaryNursePractitioner: ICoach | null;
    coaches: ICoach[];
    roles: IRole[];
}

export interface IRole {
    id: string;
    billable: boolean;
    role: UserRoles;
}

export interface ICoach extends Omit<IUser, 'id'>{
    userId: string;
    name: string
}

export interface IActionPlanItem {
    id?: number;
    inputType: ActionplanInputType;
    value: string;
}

export interface IActionPlanZone {
    id?: number;
    zone: ActionPlanZoneType;
    zoneItems: IActionPlanItem[];
    minFlowRate: number;
    maxFlowRate: number;
}

export interface IProviderInfo {
    providerName: string;
    providerPhone: string;
    emergencyContactName: string;
    emergencyContactPhone: string;
}

export interface IGenericActionPlan {
    id?: number;
    version?: number;
    lang: string;
    userId: string | null;
    coachId: string | null;
    createdAt?: string;
    createdBy: string | null;
    providerName: string;
    phoneNumber: string;
    emergencyContactName: string;
    emergencyContactPhone: string;
    published: boolean;
    actionPlanZones: IActionPlanZone[]
    copdInfo?: ICopdInfo;
    isActive?: boolean;
}

export interface IShortnessOfBreathCause {
    id?: number;
    cause: string;
}

export interface ICopdInfo {
    phlegmColour: PhlegmColor;
    shortnessOfBreathCauses: IShortnessOfBreathCause[];
}

export interface IAsthmaActionPlan extends IGenericActionPlan {

}

export interface UserAssignment {
    coachAssignment?: UserAssignmentCoachAssignment | null
    groupCode?: string | null
    primaryCondition?: MedicalCondition | null
}

export interface UserAssignmentCoachAssignment {
    primaryCoachId: string,
    assignedCoachIds: string[]
}

export interface ExerciseFunctions {
    cloneExercise: Function;
    cloneDay: Function;
    removeExercise: Function;
    removeDay: Function;
    moveExerciseUp: Function;
    moveExerciseDown: Function;
}

export enum BillableSessionStatus {
    EDITED = 'EDITED',
    MANUALLY_CREATED = 'MANUALLY_CREATED',
    HEALTHIE_CHARTING_NOTE = 'HEALTHIE_CHARTING_NOTE',
}

export enum BillableSessionType {
    SYNC = 'SYNC',
    ASYNC = 'ASYNC'
}

export interface IFetchedGenericSession {
    sessionId: number;
    calendarDate: string;
    type: BillableSessionType;
    durationSeconds: number;
    providerName: string;
    providerId: string;
    providerRole: UserRoles;
    status?: BillableSessionStatus;
}

export interface IBillableSessionMonthSummary {
    calendarMonth: string;
    totalSeconds: number;
    totalAsyncSeconds: number;
    totalSyncSeconds: number;
    totalBillingUnits: number;
    maxBillingUnits: number;
    maxSeconds: number;
    sessions: IFetchedGenericSession[];
}

export interface IBillingMonthlySummary {
    calendarMonth: string;
    minutes: number;
    billingUnits98980: number;
    billingUnits98981: number;
}

export interface IBillingTotalSummary {
    monthlySummaries: IBillingMonthlySummary[];
    totalMinutes: number;
    totalBillingUnits98980: number;
    totalBillingUnits98981: number;
}

export interface ISyncBillingTotalSummary {
    calendarMonth: string;
    totalMinutes: number;
}

export interface IBillableSession {
    id?: number;
    startDate: Date;
    endDate: Date;
    userId: string;
    providerId: string;
    providerName: string;
    status?: BillableSessionStatus;
}

export interface IBillableSessionCreationResponse {
    id?: number;
    startDate: Date;
    endDate: Date;
    userId: string;
    providerId: string;
    providerName: string;
    timerPopupInterval: number;
    stopTimerInterval: number;
    status?: BillableSessionStatus;
}

export interface IFetchedBillableSession {
    id: number;
    calendarDate: string;
    seconds: number;
    startDate: string;
    endDate: string;
    userId: string;
    providerId: string;
    providerName: string;
    status?: BillableSessionStatus;
    providerRole: UserRoles;
}

export interface ICurrent98976PeriodSummary {
    daysWith98976Data: number;
    daysLeftInPeriod: number;
    isDischarged: boolean;
    evalPending: boolean;
}

export enum UserRoles {
    PHYSICAL_THERAPIST = 'PHYSICAL_THERAPIST',
    RESPIRATORY_THERAPIST = 'RESPIRATORY_THERAPIST',
    NURSE_PRACTITIONER = 'NURSE_PRACTITIONER',
    COACH = 'COACH'
}

export interface IVideoGenerationJob {
    id: number;
    status: VideoGenerationJobStatus;
    draftVideoUrl: string;
}

export enum VideoGenerationJobStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}
