import styled from 'styled-components';

export const EditPatientForm = styled.form`
    width: 90%;
`;

export const PatientFieldRow = styled.div`
    display: flex;
    margin-bottom: 30px;
    width: 100%;
    justify-content: space-between;

    &:last-of-type {
        margin-bottom: 0;
    }

    > div {
        width: 45%; 
        > select {
            max-width: 400px;
        }
    }
`;

export const CoachSelectionRow = styled.div`
    width: 100%;
`;
