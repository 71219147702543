import React, { useContext, useState } from 'react';
import * as S from '../../index.styles';
import { Buttons } from '@apps/common-ui';
import { ExerciseTypes } from '@apps/common-utilities';

type Props = {
    onDischarge: Function;
    onNonPtMaintenance: Function;
}

const ManageProgramDischarge = ({ onDischarge, onNonPtMaintenance }: Props) => {
    return (
        <>
            <S.Row>
                <p>Are you sure you want to end this plan of care?</p>
            </S.Row>
            <S.Row>
                <Buttons.Button
                  type="button"
                  buttonStyle="danger"
                  onClick={onDischarge}
                >
                    Leave BreatheSuite Program
                </Buttons.Button>
                <Buttons.Button
                  type="button"
                  buttonStyle="danger"
                  buttonType="secondary"
                  onClick={onNonPtMaintenance}
                >
                    Continue Maintenance w/o PT
                </Buttons.Button>
            </S.Row>
        </>
    );
};

export default ManageProgramDischarge;
