import React, { useEffect } from 'react';
import { Modal } from '@apps/common-ui';
import { IFetchedBillableSession } from '../../../types/models';
import * as S from './index.styles';
import { formatBillableSessionDate, getMinutesStringFromSeconds } from '../Utils/utils';
import { TenantApi } from '../../../api/CoachingAPI';

interface Props {
    showModal: boolean;
    dismissModal: () => void;
    billableSession?: IFetchedBillableSession;
    refreshSessions: () => void;
}

export const DeleteEntryModal = ({ showModal, dismissModal, billableSession, refreshSessions }: Props) => {
    const deleteSession = async () => {
        if (!billableSession) {
            return;
        }
        await TenantApi.delete(`/users/${billableSession.userId}/billable-sessions/${billableSession.id}`);
        refreshSessions();
        dismissModal();
    };

    if (!billableSession) {
        return null;
    }

    return (
        <Modal showModal={showModal} dismissModal={dismissModal} showTopBar={false} closeOnOutsideClick={false} minWidth="500px">
            <S.Container>
                <S.Title>Delete Time Entry</S.Title>
                <S.Subtitle>You are about to delete the following time entry:</S.Subtitle>
                <S.TimeContainer>
                    <S.Time>
                        <b>{getMinutesStringFromSeconds(billableSession.seconds)}</b> on <b>{formatBillableSessionDate(billableSession.calendarDate, true)}</b>
                    </S.Time>
                </S.TimeContainer>
                <S.ButtonContainer>
                    <S.DeleteButton onClick={deleteSession}>
                        <S.DeleteIcon src="/DiscardIconWhite.svg" /> Delete
                    </S.DeleteButton>
                    <S.CancelButton onClick={dismissModal}>Cancel</S.CancelButton>
                </S.ButtonContainer>
            </S.Container>
        </Modal>
    );
};

export default DeleteEntryModal;
