import { Buttons, Modal } from '@apps/common-ui';
import React, { useContext, useEffect, useState } from 'react';
import { Routes } from '../../../../api/Routes';
import { ExerciseActions } from '../../state/actions';
import { defaultExercisePlan, ExerciseContext } from '../../state/ExerciseContext';
import * as S from './index.styles';
import { TenantApi } from '../../../../api/CoachingAPI';
import { monthDateYear } from '../../../../utils/dateUtils';
import { ExercisePlanType } from '@apps/common-utilities/src/types/exerciseTypes';
import { exercisePlanTypeLabel } from '../../../../components/ExerciseForm/utils';
import ManageProgramDischarge from './components/ManageProgramDischarge';
import ManageProgramPaused from './components/ManageProgramPaused';
import ManageProgramNewPlanType from './components/ManageProgramNewPlanType';
import ManageProgramNewPlanCopyExercises from './components/ManageProgramNewPlanCopyExercises';
import ManageProgramOverview from './components/ManageProgramOverview';

const ManageProgramModal = ({ showModal, dismissModal, onDischarge, createNonPtMaintenancePlan }: {showModal: boolean, dismissModal: Function, onDischarge: Function, createNonPtMaintenancePlan: Function }) => {
    enum ModalState {
        OVERVIEW,
        DISCHARGE,
        PAUSE,
        NEW_PLAN_TYPE,
        NEW_PLAN_COPY_EXERCISES,
    }

    const { state, dispatch } = useContext(ExerciseContext);
    const [modalState, setModalState] = useState(ModalState.OVERVIEW);
    const [planTypeState, setPlanTypeState] = useState<ExercisePlanType>(ExercisePlanType.VPR);
    const [startNonPtMaintenance, setStartNonPtMaintenance] = useState(false);

    const baseNewPlan = {
        ...defaultExercisePlan(),
        userId: state.exercisePlan.userId,
        userAge: state.exercisePlan.userAge,
        restingHeartRate: state.exercisePlan.restingHeartRate,
        onBetaBlockers: state.exercisePlan.onBetaBlockers,
        spO2PercentTarget: state.exercisePlan.spO2PercentTarget,
        minHeartRatePercentOverride: state.exercisePlan.minHeartRatePercentOverride,
        maxHeartRatePercentOverride: state.exercisePlan.maxHeartRatePercentOverride,
        publishedAt: null
    };

    const closeModal = () => {
        dismissModal();
        setModalState(ModalState.OVERVIEW);
    };

    useEffect(() => {
        if (startNonPtMaintenance && state.dischargeIdOnCreate) {
            const newPlan = {
                ...baseNewPlan,
                exercisePlanType: ExercisePlanType.NON_PT_MAINTENANCE,
                assignedExercises: state.exercisePlan.assignedExercises,
                endDate: null,
            };
            createNonPtMaintenancePlan(newPlan);
            setStartNonPtMaintenance(false);
            closeModal();
        }
    }, [state.dischargeIdOnCreate]);

    const togglePause = (paused: boolean) => {
        TenantApi.post(`/${Routes.coaches}/${Routes.exercisePlans}/${state.exercisePlan.id}/pauses`, { paused })
            .then((response) => {
                TenantApi.get(`/${Routes.coaches}/${Routes.exercisePlans}/${state.exercisePlan.id}`).then(exercisePlan => {
                    dispatch({ type: ExerciseActions.SET_EXERCISE_PLAN, payload: exercisePlan });
                });
            });
    };

    const onOverviewDischarge = () => {
        setModalState(ModalState.DISCHARGE);
    };

    const onOverviewNewPlan = () => {
        setModalState(ModalState.NEW_PLAN_TYPE);
        dispatch({ type: ExerciseActions.SET_DISCHARGE_ID_ON_CREATE, payload: state.exercisePlan.id });
    };

    const onOverviewPause = () => {
        setModalState(ModalState.PAUSE);
    };

    const onFullDischarge = async () => {
        await onDischarge();
        closeModal();
    };

    const onConfirmPause = () => {
        togglePause(!state.exercisePlan.paused);
        closeModal();
    };

    const onNonPtMaintenance = async () => {
        setStartNonPtMaintenance(true);
        dispatch({ type: ExerciseActions.SET_DISCHARGE_ID_ON_CREATE, payload: state.exercisePlan.id });
    };

    const onNewVprPlan = () => {
        setModalState(ModalState.NEW_PLAN_COPY_EXERCISES);
        setPlanTypeState(ExercisePlanType.VPR);
    };

    const onNewPTMaintenancePlan = () => {
        setModalState(ModalState.NEW_PLAN_COPY_EXERCISES);
        setPlanTypeState(ExercisePlanType.PT_MAINTENANCE);
    };

    const onCopyExercises = () => {
        const planPayload = {
            ...baseNewPlan,
            exercisePlanType: planTypeState,
            assignedExercises: state.exercisePlan.assignedExercises,
        };
        if (planTypeState === ExercisePlanType.NON_PT_MAINTENANCE) {
            planPayload.endDate = null;
        }
        dispatch({
            type: ExerciseActions.SET_EXERCISE_PLAN,
            payload: planPayload
        });
        dispatch({
            type: ExerciseActions.SET_UNSAVED_CHANGES,
            payload: true,
        });
        closeModal();
    };

    const onDontCopyExercises = () => {
        dispatch({
            type: ExerciseActions.SET_UNSAVED_CHANGES,
            payload: true,
        });
        const planPayload = {
            ...baseNewPlan,
            exercisePlanType: planTypeState,
        };
        if (planTypeState === ExercisePlanType.NON_PT_MAINTENANCE) {
            planPayload.endDate = null;
        }
        dispatch({
            type: ExerciseActions.SET_EXERCISE_PLAN,
            payload: planPayload,
        });
        closeModal();
    };

    return (
        <Modal
          showModal={showModal}
          dismissModal={() => {
            closeModal();
            dispatch({ type: ExerciseActions.SET_DISCHARGE_ID_ON_CREATE, payload: null });
        }}
          title="Manage Exercise Plan"
          style={{ width: '30%' }}
          closeLabel="Cancel"
        >
            <S.Container style={{ height: 'auto' }}>
                <S.Column>
                    { modalState === ModalState.OVERVIEW && (
                        <ManageProgramOverview
                          exercisePlan={state.exercisePlan}
                          onDischarge={onOverviewDischarge}
                          onNewPlan={onOverviewNewPlan}
                          onPause={onOverviewPause}
                        />
                    )}
                    { modalState === ModalState.DISCHARGE && (
                    <ManageProgramDischarge
                      onDischarge={onFullDischarge}
                      onNonPtMaintenance={onNonPtMaintenance}
                    />
                    )}
                    { modalState === ModalState.PAUSE && (
                    <ManageProgramPaused
                      exercisePlan={state.exercisePlan}
                      onConfirmPause={onConfirmPause}
                    />
                    )}
                    { modalState === ModalState.NEW_PLAN_TYPE && (
                    <ManageProgramNewPlanType
                      onNewVprPlan={onNewVprPlan}
                      onNewPTMaintenancePlan={onNewPTMaintenancePlan}
                    />
                    )}
                    { modalState === ModalState.NEW_PLAN_COPY_EXERCISES && (
                    <ManageProgramNewPlanCopyExercises
                      onCopyExercises={onCopyExercises}
                      onDontCopyExercises={onDontCopyExercises}
                    />
                    )}
                </S.Column>
            </S.Container>
        </Modal>
    );
};

export default ManageProgramModal;
