import styled from 'styled-components';

export const Form = styled.form`
    max-width: 1200px;
    margin: 0 auto;
`;

export const FormRow = styled.div<{ direction?: 'row' | 'column' }>`
    width: 100%;
    display: flex;
    flex-direction: ${({ direction }) => direction || 'row'};
    justify-content: space-evenly;
    padding: 20px 0;

    > div {
        align-items: flex-start;
    }

    .editor-wrapper {
        border: 1px solid #ccc;
        min-height: 300px;
        overflow: auto;
    }

    .editor {
        padding: 0 10px 10px 10px;
        overflow: hidden;
    }
`;

export const RadioGroup = styled.div`
    display: flex;

    > div {
        margin-right: 30px;
    }
`;

export const FormSectionTitle = styled.h3`
    font-size: 18px;
    margin-bottom: 5px;

    svg {
        color: ${props => props.theme.shades.grey};
    }
`;

export const FormSectionSubtitle = styled.span`
    font-size: 14px;
    color: ${props => props.theme.shades.darkGrey};
    margin-bottom: 10px;
`;

export const VariableInputsSection = styled.div`
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    max-width: 800px;
    margin-top: 20px;
`;

export const SubsectionInputs = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    > div {
        margin-left: 10px;
    }
`;

export const GroupedFormRow = styled(FormRow)`
    border-left: 2px solid ${props => props.theme.shades.lighterGrey};
    margin-bottom: 20px;
    padding-left: 10px;
`;

export const SubHeader = styled.p`
    font-weight: bold;
    font-size: 14px;
`;

export const TargetsCheckbox = styled.div`
    margin-bottom: 20px;

    input {
        width: 50px;
    }

    label {
        margin-top: 0;
    }
`;

export const EquipmentContainer = styled.div`
    label {
        margin-right: 10px;
    }
    display: flex;
    flex-direction: column;
`;

export const EquipmentError = styled.p`
    color: ${props => props.theme.text.error};
    margin: 0;
    padding-left: 18px;
    width: 200px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const ExerciseInstructions = styled.div`
    min-height: 300px;
    width: 90%;
    border: 1px solid ${props => props.theme.shades.lightGrey};
    background-color: ${props => props.theme.shades.offWhite};
    border-radius: 3px;

    .DraftEditor-root {
        padding: 5px;
    }
`;
