import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const MenuButton = styled(Buttons.Button).attrs({
    buttonType: 'tertiary'
})`
    color: ${props => props.theme.shades.darkGrey};
`;

export const MenuContainer = styled.div`
    position: relative;
`;

export const Menu = styled.ul`
    position: absolute;
    top: 50%;
    left: 30%;
    min-width: 80px;
    background-color: white;
    border: 1px solid ${props => props.theme.shades.lighterGrey};
    border-radius: 3px;
    padding: 5px 0;
    z-index: 2;
`;

export const MenuItem = styled.li<{ type: string | undefined }>`
    list-style: none;
    padding: 5px 0;
    border-bottom: 1px solid ${props => props.theme.shades.lighterGrey};
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    color: ${props => {
        switch (props.type) {
            case 'danger':
                return props.theme.danger.medium;
            case 'success':
                return props.theme.success.medium;
            default:
                return props.theme.shades.darkGrey;
        }
    }};

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        opacity: 0.8;
    }
`;
