import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './reducers/session';
import coachingReducer from './reducers/coaching';
import patientReducer from './reducers/patient';
import physicalTherapyReducer from './reducers/physicalTherapy';
import learningReducer from './reducers/learning';
import billingReducer from './reducers/billing';

export const store = configureStore({
    reducer: {
        session: sessionReducer,
        coaching: coachingReducer,
        patient: patientReducer,
        physicalTherapy: physicalTherapyReducer,
        learning: learningReducer,
        billing: billingReducer
    },
    devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
