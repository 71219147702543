import styled from 'styled-components';

export const PhlegmSelectContainer = styled.div`
    width: 100%;
    display: flex;
    algin-items: center;

    select {
        margin-left: 0;
    }
`;

export const ShortnessOfBreathCausesContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;
`;

export const InputContainer = styled.div`
    display: flex;
    width: 100%;

    button {
        margin-left: 10px;
    }
`;
