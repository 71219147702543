import styled from 'styled-components';

export const ChangesMadeWarning = styled.div`
    min-width: 170px;
    height: 40px;
    text-align: center;
    z-index: 1;
    position: fixed;
    bottom: 50px;
    right: 80px;
    padding: 5px 10px;
    line-height: 30px;
    color: red;
    font-size: 14px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid red;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    font-weight: bold;

    svg {
        margin-right: 5px;
    }
`;
