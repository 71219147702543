import { Auth } from 'aws-amplify';
import { createSlice } from '@reduxjs/toolkit';
import { Routes } from '../../../api/Routes';
import { ICoach, IUser, UserAssignment } from '../../../types/models';
import { AppDispatch } from '../../store';
import { TenantApi } from '../../../api/CoachingAPI';

export interface CoachingState {
    patients: IUser[] | [];
    totalPatients: number;
    coaches: ICoach[] | [];
}

const initialState = {
    patients: [],
    coaches: [],
    totalPatients: 0
} as CoachingState;

const sessionSlice = createSlice({
    name: 'coaching',
    initialState,
    reducers: {
        setPatients_: (state, { payload }: {payload: { patients: IUser[], patientsCount: number }}) => {
            state.patients = payload.patients;
            state.totalPatients = payload.patientsCount;
        },
        updatePatient: (state, { payload }: {payload: { patient: IUser }}) => {
            const index = state.patients.findIndex(patient => patient.id === payload.patient.id);
            state.patients[index] = payload.patient;
        },
        setCoaches_: (state, { payload }: {payload: { coaches: ICoach[] }}) => {
            state.coaches = payload.coaches;
        }
    }
});

export const {
    setPatients_,
    setCoaches_,
    updatePatient
} = sessionSlice.actions;

export default sessionSlice.reducer;

type PatientsFetchParams = {
    sort: string;
    page: number;
    size: number;
    name?: string;
    coachId?: string;
    assigned?: boolean;
}

export const fetchPatients = ({ page, size, search, coachId, assigned }:
    { page: number, size: number, search?: string, coachId?: string, assigned?: boolean }) => async (dispatch: AppDispatch) => {
    const params: PatientsFetchParams = {
        sort: 'createdAt,desc',
        page,
        size
    };
    if (search) {
        params.name = search;
    }
    if (coachId) {
        params.coachId = coachId;
    }
    if (assigned !== undefined) {
        params.assigned = assigned;
    }
    let patients = await TenantApi.get(`/${Routes.coaches}/patients`, { params });
    if (!patients) {
        patients = {
            content: [],
            totalElements: 0
        };
    }
    dispatch(setPatients_({ patients: patients.content, patientsCount: patients.totalElements }));
};

export const fetchCoaches = () => async (dispatch: AppDispatch) => {
    const coaches = await TenantApi.get(`/${Routes.coaches}/summary`);
    dispatch(setCoaches_({ coaches }));
};

export const patchUserAssignment = ({ patientId, userAssignment }: {patientId: string, userAssignment: UserAssignment}) => async (dispatch: AppDispatch) => {
    const updatedUser: IUser = await TenantApi.patch(`/${Routes.coaches}/${Routes.users}/${patientId}/${Routes.userAssignment}`, userAssignment);
    dispatch(updatePatient({ patient: updatedUser }));
};
