import { Buttons, theme } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router';
import { tableStyleOverrides } from '../../api/utils/tables';
import { useActionLoader } from '../../hooks/useActionLoader';
import { fetchActionPlans } from '../../state/reducers/patient';
import { RootState } from '../../state/store';
import { IGenericActionPlan, IUser } from '../../types/models';
import { PageHeader } from '../../components/common/commonStyled';
import * as S from './index.styles';

type IActionPlanWithIsActive = IGenericActionPlan & { isActive: boolean };

const tableColumns = [
    {
        name: 'Version',
        selector: (row: IActionPlanWithIsActive) => row.version || 'N/A',
        sortable: true
    },
    {
        name: 'Created at',
        selector: (row: IActionPlanWithIsActive) => {
            if (!row.createdAt) {
                return 'N/A';
            }
            const date = new Date(row.createdAt);
            return date.toLocaleDateString();
        },
        sortable: true
    },
    {
        name: 'Is active',
        selector: (row: IActionPlanWithIsActive) => row.isActive ? 'Active' : ''
    }
];

const conditionalRowStyles = [
    {
        when: (row: IActionPlanWithIsActive) => row.isActive,
        style: {
            color: theme.success.medium,
            fontWeight: 'bold'
        }
    },
    {
        when: (row: IActionPlanWithIsActive) => !row.isActive,
        style: {
            color: theme.shades.grey,
        }
    }
];

const ActionPlansList = () => {
    const { patient } = useOutletContext<{ patient: IUser }>();
    const { actionPlans } = useSelector((state: RootState) => state.patient);
    const { callAction: getActionPlans, loading, done } = useActionLoader(fetchActionPlans);
    const navigate = useNavigate();
    const [formattedPlans, setFormattedPlans] = useState<IActionPlanWithIsActive[]>([]);

    useEffect(() => {
        if (patient && !done && !loading) {
            getActionPlans({ patientId: patient.id });
        }
    }, [patient]);

    useEffect(() => {
        const formatted = actionPlans.map((plan: IGenericActionPlan, index: number) => {
            if (index === 0) {
                return { ...plan, isActive: true };
            }
            return { ...plan, isActive: false };
        });
        setFormattedPlans(formatted);
    }, [actionPlans]);

    const viewActionPlan = (row: IActionPlanWithIsActive) => {
        navigate(`/patients/${patient.id}/action-plans/${row.version}`);
    };

    return (
        <S.ActionPlansListContainer>
            <PageHeader>
                <h1>Action Plans</h1>
                <Buttons.LinkButton to={`/patients/${patient.id}/action-plans/new`}>New</Buttons.LinkButton>
            </PageHeader>
            {!loading && (
                <DataTable
                  highlightOnHover
                  striped
                  customStyles={tableStyleOverrides}
                  columns={tableColumns}
                  data={formattedPlans}
                  onRowClicked={viewActionPlan}
                  conditionalRowStyles={conditionalRowStyles}
                />
            )}
        </S.ActionPlansListContainer>
    );
};

export default ActionPlansList;
