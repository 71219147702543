import styled from 'styled-components';

export const ProviderInfoContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    div {
        flex-basis: 40%;
        margin-right: 40px;
    }
`;

export const InputBlock = styled.div`

`;
