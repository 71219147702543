import React, { useContext, useEffect, useState } from 'react';
import { ActionPlanContext } from '../..';
import { ActionPlanZoneType, IActionPlanZone, MedicalCondition } from '../../../../types/models';
import { ReducerActionType } from '../../reducer';
import { ZoneSectionContainer, ZoneSectionHeader, ActionPlanLabel } from '../common';
import * as S from './index.styles';

type Props = {
    zoneType: ActionPlanZoneType;
    condition: MedicalCondition
}

const FlowRateInputs = ({ zoneType, condition }: Props) => {
    const { actionPlan, updateActionPlan } = useContext(ActionPlanContext);
    const [zoneIndex, setZoneIndex] = useState<number | null>(null);
    const [zone, setZone] = useState<IActionPlanZone>();

    useEffect(() => {
        if (actionPlan) {
            const index = actionPlan.actionPlanZones.findIndex((z) => z.zone === zoneType);
            setZoneIndex(index);
            setZone(actionPlan.actionPlanZones[index]);
        }
    }, [actionPlan]);

    const updateFlowRate = (key: string, flowRate: number) => {
        if (zoneIndex !== null && zone && flowRate >= 0) {
            const newZone = { ...zone, [key]: flowRate };
            updateActionPlan({
                type: ReducerActionType.UPDATE_ZONE,
                payload: {
                    zone: newZone.zone,
                    [key]: flowRate,
                }
            });
            setZone(newZone);
        }
    };

    return (
        <ZoneSectionContainer>
            <ZoneSectionHeader>Oxygen usage (volumetric flowrate)</ZoneSectionHeader>
            <S.FlowRateInputsContainer>
                <S.FlowRateInputContainer>
                    <ActionPlanLabel htmlFor={`minValue-${zoneType}`}>At rest</ActionPlanLabel>
                    <S.FlowRateRow>
                        <S.FlowRateInput
                          inputSize="extra-small"
                          type="number"
                          value={zone?.minFlowRate}
                          step="0.1"
                          onChange={(e) => updateFlowRate('minFlowRate', parseFloat(e.target.value))}
                          disabled={!actionPlan.isActive}
                          id={`minValue-${zoneType}`}
                        />
                        <S.FlowRateUnit>L/minute</S.FlowRateUnit>
                    </S.FlowRateRow>
                </S.FlowRateInputContainer>
                <S.FlowRateInputContainer>
                    <ActionPlanLabel htmlFor={`maxValue-${zoneType}`}>While active</ActionPlanLabel>
                    <S.FlowRateRow>
                        <S.FlowRateInput
                          inputSize="extra-small"
                          type="number"
                          value={zone?.maxFlowRate}
                          step="0.1"
                          onChange={(e) => updateFlowRate('maxFlowRate', parseFloat(e.target.value))}
                          disabled={!actionPlan.isActive}
                          id={`maxValue-${zoneType}`}
                        />
                        <S.FlowRateUnit>L/minute</S.FlowRateUnit>
                    </S.FlowRateRow>
                </S.FlowRateInputContainer>
            </S.FlowRateInputsContainer>
        </ZoneSectionContainer>
    );
};

export default FlowRateInputs;
