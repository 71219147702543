import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`;

export const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`;

export const Title = styled.h2`
    margin: 0;
    font-weight: normal;
`;

export const CloseButton = styled(Buttons.Button)`
    background: none;
    color: ${props => props.theme.text.black};
    border: none;
    padding-right: 0px;
    margin-right: 0px;
`;

export const NumExercisesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 40px;
    padding: 5px 10px;
    background-color: ${props => props.theme.backgrounds.cream};
    border-radius: 6px;
    margin-bottom: 20px;
`;

export const NumExercises = styled.p`
    margin: 0;
    text-align: center;
`;

export const ConfirmationPrompt = styled.p`
    margin: 0 0 20px 0;
    text-align: center;
`;

export const CheckBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px 0;
`;

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const SaveButton = styled(Buttons.Button)`
    width: 100%;
    height: 50px;
`;
