import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const CalendarEntryContainer = styled.div`
  width: 95%;
  background-color: #fff;
  border: 2px solid ${props => props.theme.shades.lighterGrey};
  margin: 5px 0;
  padding: 10px;
  border-radius: 8px;
  position: relative;

  &:hover {
    background-color: ${props => props.theme.shades.offWhite};
  }

  @media (max-width: ${props => props.theme.screen.medium}) {
    width: 60%;
  }
`;

export const CalendarEntryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  svg {
    cursor: pointer;
  }
`;

export const CalendarEntryTitle = styled.h4`
  margin: 0;
`;

export const CalendarEntryButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const CalendarEntryButton = styled(Buttons.Button)`
  height: 20px;
  width: 35px;
  padding: 0 5px;
  margin: 2px;
  background-color: transparent;
  border: 1px solid ${props => props.theme.shades.darkGrey};
  color: ${props => props.theme.shades.darkGrey};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.shades.lightGrey};
  }
`;

export const EditEntryButton = styled(Buttons.Button)`
  padding: 0;
  margin: 5px 0 0 0;
`;

export const Thumbnail = styled.img`
  height: 70%;
  width: 70%;
  object-fit: cover;
`;

export const DeletedTag = styled.span`
  color: ${props => props.theme.shades.grey};
`;
