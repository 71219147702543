import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../../../types/models';
import { AppDispatch } from '../../store';

export interface SessionState {
    loggedIn: boolean;
    user: IUser | null;
}

const initialState = {
    loggedIn: false,
    user: null
} as SessionState;

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSessionUser: (state, { payload }: {payload: { user: IUser }}) => {
            state.user = payload.user;
        },
        login: (state, { payload }: { payload: { user: IUser } }) => {
            state.loggedIn = !!payload.user?.id;
            state.user = payload.user ? payload.user : null;
        },
        logout: state => {
            state.loggedIn = false;
            state.user = null;
        }
    }
});

export const {
    login,
    logout,
    setSessionUser
} = sessionSlice.actions;

export default sessionSlice.reducer;

export const setSignedInUser = (data: any) => async (dispatch: AppDispatch) => {
    dispatch(setSessionUser({ user: data as IUser }));
};
