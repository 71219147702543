export const WEEKDAYS = {
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday'
};

export const editorToolbar = {
    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline'],
    },
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3'],
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    list: {
        options: ['unordered', 'ordered'],
    },
    textAlign: {
        inDropdown: true,
        options: ['left', 'center', 'right', 'justify'],
    }
};
