import { Inputs, VideoEmbed } from '@apps/common-ui';
import { Editor } from 'react-draft-wysiwyg';
import React from 'react';
import { Controller } from 'react-hook-form';
import { isVimeoUrl } from '../../../../utils/url';
import * as S from '../../index.styles';
import { ExerciseTypes } from '@apps/common-utilities';
import { FormComponentProps } from '../../types';
import { editorToolbar } from '../../../../types/constants';
import RichTextInput from '../../../RichTextInput';

const { ExerciseCategory } = ExerciseTypes;

type Props = FormComponentProps & {
    control: any;
    editing?: boolean;
    initialCategory?: ExerciseTypes.ExerciseCategory;
}

const MainExerciseDetails = ({ errors, register, watch, control, editing, initialCategory }: Props) => (
    <>
        <S.FormRow>
            <Inputs.InputContainer error={errors.exerciseTitle}>
                <Inputs.Label>Exercise name</Inputs.Label>
                <Inputs.Input
                  disabled={editing}
                  {...register('exerciseTitle', { required: 'Exercise name is required' })}
                />
            </Inputs.InputContainer>
            <Inputs.InputContainer error={errors.videoUrl}>
                <Inputs.Label>Video URL</Inputs.Label>
                <Inputs.Input
                  {...register('videoUrl', {
                    required: 'Video URL is required',
                    validate: (u) => !isVimeoUrl(u as string) ? 'Video URL must be a valid Vimeo URL' : true
                  })}
                  type="url"
                />
                <Inputs.InputContainer>
                    <Inputs.Label>
                        Video is in portrait orientation
                        <input type="checkbox" {...register('videoIsPortrait')} />
                    </Inputs.Label>
                </Inputs.InputContainer>
            </Inputs.InputContainer>
        </S.FormRow>
        <S.FormRow>
            <Inputs.InputContainer error={errors.description}>
                <Inputs.Label>Description</Inputs.Label>
                <Controller
                  name="instructions"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                      <S.ExerciseInstructions>
                          <RichTextInput
                            onChange={onChange}
                            defaultValue={value}
                          />
                      </S.ExerciseInstructions>
                    )}
                />
            </Inputs.InputContainer>
            <Inputs.InputContainer>
                <VideoEmbed
                  title="Video preview"
                  src={watch('videoUrl')}
                />
            </Inputs.InputContainer>
        </S.FormRow>
        <S.FormRow direction="column">
            <S.FormSectionTitle>🏷️ Category</S.FormSectionTitle>
            <S.FormSectionSubtitle>This will help with searching later</S.FormSectionSubtitle>
            <S.RadioGroup>
                <div>
                    <input
                      {...register('category', { required: true })}
                      type="radio"
                      id="strength"
                      value={ExerciseCategory.STRENGTH}
                      disabled={editing && initialCategory === ExerciseCategory.RMT}
                    />
                    <Inputs.Label htmlFor="strength">Strength</Inputs.Label>
                </div>
                <div>
                    <input
                      {...register('category', { required: true })}
                      type="radio"
                      id="cardio"
                      value={ExerciseCategory.CARDIO}
                      disabled={editing && initialCategory === ExerciseCategory.RMT}
                    />
                    <Inputs.Label htmlFor="cardio">Cardio</Inputs.Label>
                </div>
                <div>
                    <input
                      {...register('category', { required: true })}
                      type="radio"
                      id="flexibility"
                      value={ExerciseCategory.FLEXIBILITY}
                      disabled={editing && initialCategory === ExerciseCategory.RMT}
                    />
                    <Inputs.Label htmlFor="flexibility">Flexibility</Inputs.Label>
                </div>
                <div>
                    <input
                      {...register('category', { required: true })}
                      type="radio"
                      id="breathing"
                      value={ExerciseCategory.BREATHING}
                      disabled={editing && initialCategory === ExerciseCategory.RMT}
                    />
                    <Inputs.Label htmlFor="breathing">Breathing</Inputs.Label>
                </div>
                <div>
                    <input
                      {...register('category', { required: true })}
                      type="radio"
                      id="rmtBreather"
                      value={ExerciseCategory.RMT}
                      disabled={editing && initialCategory !== ExerciseCategory.RMT}
                    />
                    <Inputs.Label htmlFor="rmtBreather">RMT Breather</Inputs.Label>
                </div>
            </S.RadioGroup>
            <Inputs.ErrorMessage>{errors.category?.message}</Inputs.ErrorMessage>
        </S.FormRow>
    </>
);

export default MainExerciseDetails;
