import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as S from './index.styles';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LearningTypes } from '@apps/common-utilities';
import { Spinner, Buttons } from '@apps/common-ui';
import { TenantApi } from '../../../../api/CoachingAPI';

const QuizHistory = () => {
    const { quizId, patientId } = useParams();
    const [quiz, setQuiz] = useState<LearningTypes.IQuizSubmission | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        TenantApi.get(`/coaches/users/${patientId}/education/assigned/quizzes/${quizId}/report`).then((q) => {
            setQuiz(q);
        }).catch(() => {
            // no quiz found
            setQuiz(null);
        }).finally(() => {
            setLoading(false);
        });
    }, [patientId, quizId]);

    return (
        <div>
            <Buttons.Button
              onClick={() => navigate(-1)}
              buttonType="tertiary"
              style={{ paddingLeft: '0' }}
            >
                <FontAwesomeIcon icon={faArrowLeft as IconProp} />
                Back to Course
            </Buttons.Button>
            {quiz && !loading && (
                <>
                    <S.Header>
                        <h3>{quiz.title}</h3>
                        <h3>Completed on {format(new Date(quiz.completedAt), 'yyyy/MM/dd')}</h3>
                    </S.Header>
                    <S.Subheading>
                        <h4>Score: {quiz.score}</h4>
                    </S.Subheading>
                    {quiz.responses.map(submission => {
                        const isCorrect = submission.answers.every(answer => answer.correct === answer.selected);
                        return (
                            <S.QuestionRow>
                                <S.Question dangerouslySetInnerHTML={{ __html: submission.question }} />
                                <S.QuestionResponse>
                                    <div>
                                        <S.QuestionCorrectness
                                          correct={isCorrect}
                                        >
                                            {isCorrect ? (
                                                <>
                                                    <FontAwesomeIcon icon={faCheckCircle as IconProp} />
                                                    Correct
                                                </>
                                    )
                                    : (
                                        <>
                                            <FontAwesomeIcon icon={faCircleXmark as IconProp} />
                                            Incorrect
                                        </>
                                    )}
                                        </S.QuestionCorrectness>
                                        {submission.answers.map(
                                        answer => (
                                            <S.Answer
                                              correct={answer.correct}
                                              selected={answer.selected}
                                            >
                                                {answer.answer}
                                            </S.Answer>
                                        )
                                        )}
                                    </div>
                                    <hr />
                                </S.QuestionResponse>
                            </S.QuestionRow>
                        );
                    })}
                </>
            )}
            {!quiz && !loading && <h3>No response found</h3>}
            {loading && <Spinner />}
        </div>
    );
};

export default QuizHistory;
