import { Inputs } from '@apps/common-ui';
import styled from 'styled-components';

export const StartEndTimesRow = styled.div`
    display: flex;
    margin: 0 0 20px 0;
`;

export const InputsGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const InputsGroup = styled.div`
    display: flex;
    gap: 5px;
`;

export const InputsGroupTitle = styled.p`
    margin: 0 0 5px 0;
`;

export const SingleInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TimeInput = styled(Inputs.Input)`
    width: 60px;
    height: 40px;
    border-radius: 10px;
`;

export const InputLabel = styled.label`
    margin: 0;
`;

export const ArrowContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
`;

export const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 40px;
`;

export const DateTitle = styled.label`
    margin: 0 0 13px 0;
`;

export const DateSelect = styled(Inputs.Input)`
  width: 200px;
  height: 40px;
  margin: 0;
  border-radius: 5px;
`;
