import { ExerciseTypes } from '@apps/common-utilities';
import { format } from 'date-fns';
import React, { createContext, PropsWithChildren, useEffect, useMemo, useReducer } from 'react';
import { actions, ExerciseAction } from './actions';
import { reducer } from './reducer';
import { getDefaultEndDate } from '../../../components/ExerciseForm/utils';
import { ExercisePlanType } from '@apps/common-utilities/src/types/exerciseTypes';

export type ExerciseState = {
    exercisePlan: ExerciseTypes.IExercisePlan;
    exerciseToEdit?: ExerciseTypes.IAssignedExercise;
    hasUnsavedChanges: boolean;
    dischargeIdOnCreate: number | null;
};

type ExerciseContextType = {
  state: ExerciseState;
  dispatch: React.Dispatch<ExerciseAction>;
}

export const defaultExercisePlan = (): ExerciseTypes.IExercisePlan => ({
    assignedExercises: [],
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(getDefaultEndDate(new Date()), 'yyyy-MM-dd'),
    dischargeDate: null,
    complete: false,
    id: null,
    userId: '',
    createdBy: '',
    onBetaBlockers: false,
    published: false,
    publishedAt: '',
    updatedAt: '',
    restingHeartRate: 0,
    userAge: 0,
    exercisePlanPauses: [],
    paused: false,
    spO2PercentTarget: 88,
    minHeartRatePercentOverride: 0,
    maxHeartRatePercentOverride: 0,
    blockPreviousPlans: false,
    exercisePlanType: ExercisePlanType.VPR,
});

const initialState: ExerciseState = {
    exercisePlan: {
        ...defaultExercisePlan(),
    },
    hasUnsavedChanges: false,
    dischargeIdOnCreate: null
};

const initialContext = {
    state: initialState,
    dispatch: ({ type, payload }: ExerciseAction) => null,
};

export const ExerciseContext = createContext<ExerciseContextType>(initialContext);

export const ExerciseContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    useEffect(() => {
        // Reset to initial state when first mounted
        dispatch(actions.setExercisePlan(defaultExercisePlan()));
    }, []);

    return (
        <ExerciseContext.Provider value={value}>
            {children}
        </ExerciseContext.Provider>
    );
};
