import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '@apps/common-ui/src/cssUtils.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);
