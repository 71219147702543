import { Inputs, Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px;
`;

export const TitleBar = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 20px 0;
`;

export const Title = styled.h3``;

export const CloseButton = styled(Buttons.Button)`
    background: transparent;
    color: ${props => props.theme.shades.grey};
    border: none;

    svg {
    
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
`;

export const InputLabel = styled.p`
    font-weight: bold;
    margin: 0 0 10px 0;
`;

export const Input = styled(Inputs.Select)`
    width: 180px;
    margin-left: 0;
`;

export const ButtonContainer = styled.div`
    display: flex;
    margin: 0 0 20px 0;
`;

export const PauseButton = styled(Buttons.Button)<{canClick: boolean, isPaused: boolean}>`
    background: ${props => props.canClick ? props.theme.buttons.red : props.theme.buttons.grey};
    color: ${props => props.theme.shades.white};
    border: ${props => props.canClick ? props.theme.buttons.red : props.theme.buttons.grey} 1px solid;
    width: ${props => props.isPaused ? '140px' : '120px'};
    height: 50px;
    margin-left: 0;
`;
