import { Inputs } from '@apps/common-ui';
import React, { useState, useEffect } from 'react';
import * as S from '../../index.styles';
import { ExerciseTypes, Labels } from '@apps/common-utilities';
import { FormComponentProps, RawExerciseFormData } from '../../types';

const { ExerciseEquipment } = ExerciseTypes;

const EquipmentInput = ({ register, watch, equipment, disabled, getError }:
    { register: any,
      watch: any,
      equipment: keyof typeof ExerciseEquipment,
      disabled: boolean,
      getError: (equipment: ExerciseTypes.ExerciseEquipment) => string
     }) => (
         <S.EquipmentContainer>
             <div>
                 <input
                   {...register(`equipment.${equipment}.selected`)}
                   type="checkbox"
                   id={equipment}
                   disabled={disabled}
                 />
                 <Inputs.Label htmlFor={equipment}>{Labels.equipment(equipment)}</Inputs.Label>
             </div>
             {watch(`equipment.${equipment}.selected`) && (
                 <Inputs.Input
                   {
                ...register(
                `equipment.${equipment}.specification`,
                { validate: () => getError(equipment as ExerciseTypes.ExerciseEquipment) ? getError(equipment as ExerciseTypes.ExerciseEquipment) : true }
                )}
                   type="text"
                   placeholder="Details"
                   inputSize={Inputs.InputSize.medium}
                 />
             )}
             <Inputs.ErrorMessage>{getError(equipment as ExerciseTypes.ExerciseEquipment)}</Inputs.ErrorMessage>
         </S.EquipmentContainer>
);

const Equipment = ({ errors, register, watch, setValue }: FormComponentProps) => {
    function equipmentPath(equipment: keyof typeof ExerciseEquipment): keyof RawExerciseFormData {
        return `equipment.${equipment}.selected` as keyof RawExerciseFormData;
    }
    useEffect(() => {
        if (watch('category') === ExerciseTypes.ExerciseCategory.RMT) {
            Object.keys(ExerciseEquipment).forEach((equip) => {
                setValue(equipmentPath(equip as keyof typeof ExerciseEquipment), false);
            });
        }
    }, [watch('category'), setValue]);

    const getErrorMessageForEquipment = (equipment: ExerciseTypes.ExerciseEquipment) => {
        if (equipment === ExerciseTypes.ExerciseEquipment.ASSISTED_DEVICES && watch(`equipment.${equipment}.selected`) && !watch(`equipment.${equipment}.specification`)) {
            return 'Details required for assisted device';
        }
        return '';
    };

    return (
        <S.FormRow direction="column">
            <S.FormSectionTitle>🏋️‍♂️ Equipment</S.FormSectionTitle>
            <S.FormSectionSubtitle>What equipment will someone need to do this exercise?</S.FormSectionSubtitle>
            <S.RadioGroup>
                {Object.values(ExerciseEquipment).map((equipment) => (
                    <EquipmentInput
                      key={equipment}
                      register={register}
                      watch={watch}
                      equipment={equipment}
                      disabled={watch('category') === ExerciseTypes.ExerciseCategory.RMT}
                      getError={getErrorMessageForEquipment}
                    />
                ))}
            </S.RadioGroup>
        </S.FormRow>
    );
};

export default Equipment;
