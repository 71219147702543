import React from 'react';
import { IUser } from '../../../../types/models';
import * as S from './index.styles';

type Props = {
    patient: IUser;
    children?: React.ReactNode;
}

const PatientPageHeader = ({ children, patient }: Props) => (
    <S.PageHeader>
        <h1>{patient.firstName} {patient.lastName}</h1>
        <S.Actions>
            {children}
        </S.Actions>
    </S.PageHeader>
);

export default PatientPageHeader;
