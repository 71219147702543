import { Buttons } from '@apps/common-ui';
import { ExerciseTypes } from '@apps/common-utilities';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { RootState } from '../../../../state/store';
import { Error } from '../../../../components/common/commonStyled';
import { ExerciseActions } from '../../state/actions';
import { ExerciseContext } from '../../state/ExerciseContext';
import EditExerciseDetails from '../AssignExercise/EditExerciseDetails';
import EditExerciseEquipment from '../AssignExercise/EditExerciseEquipment';
import EditExerciseFormat from '../AssignExercise/EditExerciseFormat';
import EditExerciseTargets from '../AssignExercise/EditExerciseTargets';
import * as S from './index.styles';
import { HeartRateValue } from '../../../../components/ExerciseForm/types';
import UpdateExerciseModal from '../UpdateExerciseModal';

const EditAssignedExercise = () => {
    const { state, dispatch } = useContext(ExerciseContext);
    const [assignedExercise, setAssignedExercise] = useState<ExerciseTypes.IAssignedExercise | undefined>(state.exerciseToEdit);
    const [exerciseTemplate, setExerciseTemplate] = useState<ExerciseTypes.IExercise>();
    const [error, setError] = useState<string>();
    const [showModal, setShowModal] = useState(false);
    const { exercises } = useSelector((store: RootState) => store.physicalTherapy);
    const navigate = useNavigate();

    const customiseExercise = (value) => {
        setAssignedExercise({ ...assignedExercise, ...value });
    };

    const saveExercise = () => {
        if (
            assignedExercise
            && assignedExercise.exerciseVariable.sets > 0
            && assignedExercise.exerciseVariable.amount > 0
            && (assignedExercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS
                ? (!!assignedExercise.exerciseVariable.rmtInhaleResistance
                    && assignedExercise.exerciseVariable.rmtInhaleResistance >= 1
                    && assignedExercise.exerciseVariable.rmtInhaleResistance <= 6
                    && !!assignedExercise.exerciseVariable.rmtExhaleResistance
                    && assignedExercise.exerciseVariable.rmtExhaleResistance >= 1
                    && assignedExercise.exerciseVariable.rmtExhaleResistance <= 5) : true)
            && (assignedExercise.exerciseInfo.type === ExerciseTypes.ExerciseType.HOLD ? (assignedExercise.exerciseVariable.holdSeconds > 0) : true)
            && (!assignedExercise.exerciseVariable.exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.ASSISTED_DEVICES && !e.specification))
        ) {
            dispatch({ type: ExerciseActions.EDIT_ASSIGNED_EXCERCISE, payload: assignedExercise });
            navigate(-1);
            return;
        }
        setError('Please ensure all fields are valid');
        if (showModal) {
            setShowModal(false);
        }
    };

    const showPopupElseSave = () => {
        if (assignedExercise?.exerciseId && state.exercisePlan.assignedExercises.filter((e) => e.exerciseId === assignedExercise.exerciseId).length > 1) {
            setShowModal(true);
        } else {
            saveExercise();
        }
    };

    const findEquipment = (equipment: ExerciseTypes.ExerciseEquipment) => {
        if (assignedExercise) {
            return !!assignedExercise.exerciseVariable.exerciseEquipment.find((e) => e.equipment === equipment);
        }
        return false;
    };

    const toggleEquipment = (equipment: ExerciseTypes.ExerciseEquipment) => {
        if (!assignedExercise) {
            return;
        }
        const equipmentIndex = assignedExercise.exerciseVariable.exerciseEquipment
            .findIndex((e) => e.equipment === equipment);
        if (equipmentIndex !== undefined && equipmentIndex !== -1) {
            setAssignedExercise({
                ...assignedExercise,
                exerciseVariable: {
                    ...assignedExercise.exerciseVariable,
                    exerciseEquipment: assignedExercise.exerciseVariable.exerciseEquipment.filter((e) => e.equipment !== equipment)
                }
            });
        } else {
            setAssignedExercise({
                ...assignedExercise,
                exerciseVariable: {
                    ...assignedExercise.exerciseVariable,
                    exerciseEquipment: [
                        ...assignedExercise.exerciseVariable.exerciseEquipment,
                        { equipment, specification: '' }
                    ]
                }
            });
        }
    };

    const setEquipmentSpecification = (value: string, equipment: ExerciseTypes.ExerciseEquipment) => {
        if (!assignedExercise) {
            return;
        }
        const equipmentIndex = assignedExercise.exerciseVariable.exerciseEquipment
            .findIndex((e) => e.equipment === equipment);
        if (equipmentIndex !== undefined && equipmentIndex !== -1) {
            setAssignedExercise({
                ...assignedExercise,
                exerciseVariable: {
                    ...assignedExercise.exerciseVariable,
                    exerciseEquipment: assignedExercise.exerciseVariable.exerciseEquipment.map((e) => {
                        if (e.equipment === equipment) {
                            return { ...e, specification: value };
                        }
                        return e;
                    })
                }
            });
        }
    };

    const setHeartRate = (value ?: HeartRateValue, rest?: boolean) => {
        if (rest) {
            if (value === HeartRateValue.BELOW_FORTY) {
                customiseExercise({
                    exerciseVariable: {
                        ...assignedExercise?.exerciseVariable,
                        heartRateRestPercent: 40,
                    }
                });
            } else if (value === HeartRateValue.BELOW_SIXTY) {
            // rest heart rate
                customiseExercise({
                    exerciseVariable: {
                        ...assignedExercise?.exerciseVariable,
                        heartRateRestPercent: 60,
                    }
                });
            } else {
                customiseExercise({
                    exerciseVariable: {
                        ...assignedExercise?.exerciseVariable,
                        heartRateRestPercent: null,
                    }
                });
            }
        } else {
            let minHeartRatePercent: number | null = 0;
            let maxHeartRatePercent: number | null = 0;
            switch (value) {
                case HeartRateValue.FORTY_TO_SIXTY:
                    minHeartRatePercent = 40;
                    maxHeartRatePercent = 60;
                    break;
                case HeartRateValue.SIXTY_TO_EIGHTY:
                    minHeartRatePercent = 60;
                    maxHeartRatePercent = 80;
                    break;
                case HeartRateValue.FORTY_TO_EIGHTY:
                    minHeartRatePercent = 40;
                    maxHeartRatePercent = 80;
                    break;
                default:
                    minHeartRatePercent = null;
                    maxHeartRatePercent = null;
                    break;
            }
            // active target
            customiseExercise({
                exerciseVariable: {
                    ...assignedExercise?.exerciseVariable,
                    maxHeartRatePercent,
                    minHeartRatePercent,
                }
            });
        }
    };

    useEffect(() => {
        if (assignedExercise) {
            setExerciseTemplate(exercises.find((e) => e.exerciseId === assignedExercise.exerciseId));
        }
    }, [assignedExercise]);

    return (
        <div>
            <UpdateExerciseModal showModal={showModal} dismissModal={() => setShowModal(false)} onSubmit={saveExercise} setError={setError} assignedExercise={assignedExercise} />
            {exerciseTemplate && assignedExercise
            && (
                <>
                    <EditExerciseDetails
                      exerciseTitle={assignedExercise.exerciseInfo.title}
                      exerciseInstructions={assignedExercise.exerciseInfo.instructions}
                      additionalInstructions={assignedExercise.exerciseVariable.additionalInstructions}
                      setAdditionalInstructions={(value) => customiseExercise({
                        exerciseVariable: { ...assignedExercise.exerciseVariable, additionalInstructions: value }
                      })}
                    />
                    <EditExerciseEquipment
                      equipmentIsAssigned={findEquipment}
                      exerciseEquipment={assignedExercise.exerciseVariable.exerciseEquipment}
                      toggleEquipment={toggleEquipment}
                      setEquipmentSpecification={setEquipmentSpecification}
                      type={exerciseTemplate.exerciseType}
                    />
                    <EditExerciseFormat
                      allowDistance={exerciseTemplate.allowDistance}
                      allowReps={exerciseTemplate.allowReps}
                      allowTime={exerciseTemplate.allowTime}
                      amount={assignedExercise.exerciseVariable.amount}
                      exerciseType={assignedExercise.exerciseInfo.type}
                      holdSeconds={assignedExercise.exerciseVariable.holdSeconds}
                      setExerciseVariable={(value) => customiseExercise({
                        exerciseVariable: { ...assignedExercise.exerciseVariable, ...value }
                      })}
                      sets={assignedExercise?.exerciseVariable.sets}
                      unit={assignedExercise?.exerciseVariable.unit}
                      inhaleResistance={assignedExercise?.exerciseVariable.rmtInhaleResistance}
                      exhaleResistance={assignedExercise?.exerciseVariable.rmtExhaleResistance}
                    />
                    <EditExerciseTargets
                      setShowTarget={(value) => customiseExercise({
                        exerciseVariable: { ...assignedExercise.exerciseVariable, ...value }
                      })}
                      showBreathlessnessRest={assignedExercise.exerciseVariable.showBreathlessnessRest}
                      showBreathlessnessTarget={assignedExercise.exerciseVariable.showBreathlessnessTarget}
                      showSpO2RestPercent={assignedExercise.exerciseVariable.showSpO2RestPercent}
                      showSpO2TargetPercent={assignedExercise.exerciseVariable.showSpO2TargetPercent}
                      showHeartRateTarget={
                        !!assignedExercise.exerciseVariable.minHeartRatePercent
                        && !!assignedExercise.exerciseVariable.maxHeartRatePercent
                      }
                      showHeartRateRest={!!assignedExercise.exerciseVariable.heartRateRestPercent}
                      setHeartRate={setHeartRate}
                      heartRateRest={assignedExercise.exerciseVariable.heartRateRestPercent}
                      heartRateTargetMax={assignedExercise.exerciseVariable.maxHeartRatePercent}
                      heartRateTargetMin={assignedExercise.exerciseVariable.minHeartRatePercent}
                      requireBreathlessnessLevelReporting={assignedExercise.exerciseVariable.requireBreathlessnessLevelReporting}
                      requireImmediateSpO2PercentReporting={assignedExercise.exerciseVariable.requireImmediateSpO2PercentReporting}
                      requireLowestSpO2PercentReporting={assignedExercise.exerciseVariable.requireLowestSpO2PercentReporting}
                      requireActivityReporting={assignedExercise.exerciseVariable.requireActivityReporting}
                      requireHeartRateReporting={assignedExercise.exerciseVariable.requireHeartRateReporting}
                      requireRMTExhaleResistanceReporting={assignedExercise.exerciseVariable.requireRMTExhaleResistanceReporting}
                      requireRMTInhaleResistanceReporting={assignedExercise.exerciseVariable.requireRMTInhaleResistanceReporting}
                      requireRMTDifficultyReporting={assignedExercise.exerciseVariable.requireRMTDifficultyReporting}
                      requireFlowRateReporting={assignedExercise.exerciseVariable.requireFlowRateReporting}
                      exerciseType={exerciseTemplate.exerciseType}
                    />
                    <Error>{error}</Error>
                    <S.Section>
                        <Buttons.Button
                          type="button"
                          onClick={() => showPopupElseSave()}
                        >
                            Save Exercise
                        </Buttons.Button>
                        <Buttons.LinkButton
                          buttonType="tertiary"
                          type="button"
                          to={-1}
                        >
                            Cancel
                        </Buttons.LinkButton>
                    </S.Section>
                </>
            )}
        </div>
    );
};

export default EditAssignedExercise;
