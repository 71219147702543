import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import * as S from './index.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const UnsavedChangesIcon = ({ text }: { text: string }) => {
    return (
        <S.ChangesMadeWarning>
            <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />
            {text}
        </S.ChangesMadeWarning>
    );
};

export default UnsavedChangesIcon;
