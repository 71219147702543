import { ExerciseTypes } from '@apps/common-utilities';
import { isAfter, isBefore, isSameDay, isWithinInterval } from 'date-fns';
import { NaiveDate } from '../../utils/dateUtils';

export const isDayOutsideOfPlan = (day: Date, startDate: string, endDate: string | null) => {
    if (endDate === null) {
        return false;
    }
    return !(
        isWithinInterval(day, { start: new NaiveDate(startDate), end: new NaiveDate(endDate) })
      || isSameDay(day, new NaiveDate(startDate)) || isSameDay(day, new NaiveDate(endDate))
    );
};

export const rmtDifficultyColor = (difficulty: number) => {
    switch (difficulty) {
        case 1:
            return 'lightblue';
        case 2:
            return 'green';
        case 3:
            return 'darkgreen';
        case 4:
            return 'orange';
        case 5:
            return 'red';
        default:
            return '#bfecff';
    }
};

export const getPercentageCompletionPerSession = (
    result: number[],
    assignedAmountPerSet: number
): number => {
    const totalAssignedAmount = assignedAmountPerSet * result.length;
    const percentageCompletionPerSession = result.reduce((acc, curr) => acc + curr, 0) / totalAssignedAmount;
    return percentageCompletionPerSession * 100;
};
