import { Inputs } from '@apps/common-ui';
import styled from 'styled-components';

export const FlowRateContainer = styled.div`
    margin-top: 30px;
`;

export const FlowRateInputsContainer = styled.div`
    margin-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const FlowRateInputContainer = styled(Inputs.InputContainer)`
    margin-bottom: 0;
    width: 220px;
`;

export const FlowRateRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FlowRateInput = styled(Inputs.Input)`
    width: 60px;
`;

export const FlowRateUnit = styled.span`
    margin-left: 10px;
`;
