export const Routes = {
    users: 'users',
    coaches: 'coaches',
    patients: 'patients',
    actionPlans: 'action-plans',
    userAssignment: 'user-assignment',
    exercises: 'exercises',
    summary: 'summary',
    exercisePlans: 'exercise-plans',
    education: 'education',
    courses: 'course-assignments',
    modules: 'modules',
    billableSessions: 'billable-sessions',
    syncBillableSessions: 'sync-billable-sessions',
    monthlySummaries: 'monthly-summaries',
    billableActivity: 'billable-activity',
};
