import styled from 'styled-components';

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 100%;
    width: 100%;
`;

export const Error = styled.div`
    color: ${props => props.theme.text.error};
    font-size: 0.8rem;
    margin-top: 5px;
    text-align: center;
    &:first-letter {
        text-transform: capitalize;
`;
