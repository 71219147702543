import { ExerciseTypes } from '@apps/common-utilities';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { RootState } from '../../state/store';
import ExerciseForm from '../../components/ExerciseForm';
import { mapExerciseToFormData } from '../../components/ExerciseForm/utils';
import { RawExerciseFormData } from '../../components/ExerciseForm/types';
import * as S from './index.styles';
import { useActionLoader } from '../../hooks/useActionLoader';
import { deleteExercise, updateExercise } from '../../state/reducers/physicalTherapy';
import { Buttons, useConfirmDialog } from '@apps/common-ui';

const EditExercise = () => {
    const { exerciseId } = useParams<{ exerciseId: string }>();
    const { exercises } = useSelector((state: RootState) => state.physicalTherapy);
    const [exerciseFormData, setExerciseFormData] = useState<RawExerciseFormData>();
    const { callAction: saveExercise, done } = useActionLoader(updateExercise, {
        successMessage: 'Exercise updated successfully',
        errorMessage: 'Failed to update exercise'
    });
    const { callAction: handleDeleteExercise, done: exerciseDeletedDone } = useActionLoader(deleteExercise, {
        successMessage: 'Exercise deleted successfully',
        errorMessage: 'Failed to delete exercise'
    });
    const navigate = useNavigate();
    const { ConfirmationDialog, confirm } = useConfirmDialog({
        message: 'Are you sure you want to delete this exercise? This cannot be undone.',
        successType: 'danger'
    });

    useEffect(() => {
        const exerciseToEdit = exercises.find(e => e.exerciseId.toString() === exerciseId);
        if (exerciseToEdit) {
            const formData = mapExerciseToFormData(exerciseToEdit);
            setExerciseFormData(formData);
        }
    }, [exerciseId]);

    const onSubmit = (data: ExerciseTypes.IExercise) => {
        saveExercise({ ...data, exerciseId: Number(exerciseId) });
    };

    if (done || exerciseDeletedDone) {
        navigate(-1);
    }

    const onDelete = () => {
        confirm().then((confirmed) => {
            if (confirmed) {
                handleDeleteExercise(Number(exerciseId));
            }
        });
    };

    return (
        <S.Container>
            <ConfirmationDialog />
            <S.Header>
                <h1>Edit Exercise</h1>
                <Buttons.Button onClick={onDelete} buttonStyle="danger">Delete</Buttons.Button>
            </S.Header>
            {exerciseFormData && (
                <ExerciseForm
                  defaultValues={exerciseFormData}
                  onSubmit={onSubmit}
                  editing
                />
            )}
        </S.Container>
    );
};

export default EditExercise;
