import React, { useContext, useState } from 'react';
import * as S from '../../index.styles';
import { Buttons } from '@apps/common-ui';
import { ExerciseTypes } from '@apps/common-utilities';
import { exercisePlanTypeLabel } from '../../../../../../components/ExerciseForm/utils';
import { ExercisePlanType } from '@apps/common-utilities/src/types/exerciseTypes';
import { monthDateYear } from '../../../../../../utils/dateUtils';

type Props = {
    onNewVprPlan: Function;
    onNewPTMaintenancePlan: Function;
}

const ManageProgramNewPlanType = ({ onNewVprPlan, onNewPTMaintenancePlan }: Props) => {
    return (
        <>
            <S.Row>
                <S.StatusContainer status="success">New PoC Will Be Created</S.StatusContainer>
            </S.Row>
            <S.Row>
                <p>What kind of Plan of Care is this?</p>
            </S.Row>
            <S.Row>
                <Buttons.Button
                  type="button"
                  onClick={onNewVprPlan}
                >
                    For vPR
                </Buttons.Button>
                <Buttons.Button
                  type="button"
                  onClick={onNewPTMaintenancePlan}
                  buttonType="secondary"
                >
                    For Maintenance
                </Buttons.Button>
            </S.Row>

        </>
    );
};

export default ManageProgramNewPlanType;
