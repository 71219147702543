import { Buttons } from '@apps/common-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { IUser, UserRoles } from '../../../../types/models';
import PatientPageHeader from '../PatientPageHeader';
import * as S from './index.styles';
import CourseProgress from './components/CourseProgressCard';
import { camelCaseToTitleCase } from '@apps/common-utilities/src/utils/utils';
import ExercisePlanProgress from './components/ExercisePlanProgress';
import { useOutletContext } from 'react-router';

const PatientSummary = () => {
    const { patient } = useOutletContext<{ patient: IUser }>();
    const { coaches } = useSelector((state: RootState) => state.coaching);

    const getPrimaryCoachName = (role: UserRoles) => {
        let coachId;
        if (role === UserRoles.PHYSICAL_THERAPIST) {
            coachId = patient.primaryPhysicalTherapist?.userId;
        } else if (role === UserRoles.RESPIRATORY_THERAPIST) {
            coachId = patient.primaryRespiratoryTherapist?.userId;
        } else if (role === UserRoles.NURSE_PRACTITIONER) {
            coachId = patient.primaryNursePractitioner?.userId;
        } else {
            return '';
        }
        return coaches.find(coach => coach.userId === coachId)?.name;
    };
    return (
        <>
            <PatientPageHeader patient={patient}>
                <Buttons.LinkButton to="edit">Edit</Buttons.LinkButton>
            </PatientPageHeader>
            <S.Header>CONTACT INFORMATION</S.Header>
            <S.PatientInfoRow>
                <S.PatientInfoBlock>
                    <span>Email</span>
                    <p>{patient.email}</p>
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>Phone</span>
                    <p>{patient.phoneNumber}</p>
                </S.PatientInfoBlock>
            </S.PatientInfoRow>
            <S.Header>MEDICAL INFORMATION</S.Header>
            <S.PatientInfoRow>
                <S.PatientInfoBlock>
                    <span>Condition</span>
                    <p>{patient.medicalCondition}</p>
                </S.PatientInfoBlock>
            </S.PatientInfoRow>
            <S.Header>ASSIGNMENTS</S.Header>
            <S.PatientInfoRow>
                <S.PatientInfoBlock>
                    <span>Cohort</span>
                    <ul>
                        {
                            patient.groupCode?.split('-').map(assignment => {
                                return <li key={assignment}>{camelCaseToTitleCase(assignment)}</li>;
                            })
                        }
                    </ul>
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>Primary Physical Therapist</span>
                    <p>{getPrimaryCoachName(UserRoles.PHYSICAL_THERAPIST)}</p>
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>Primary Respiratory Therapist</span>
                    <p>{getPrimaryCoachName(UserRoles.RESPIRATORY_THERAPIST)}</p>
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>Primary Nurse Practitioner</span>
                    <p>{getPrimaryCoachName(UserRoles.NURSE_PRACTITIONER)}</p>
                </S.PatientInfoBlock>
            </S.PatientInfoRow>
            <ExercisePlanProgress />
            <S.Header>COURSE PROGRESS</S.Header>
            <S.PatientInfoRow>
                <CourseProgress />
            </S.PatientInfoRow>

        </>
    );
};

export default PatientSummary;
