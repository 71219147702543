import React, { useContext, useState } from 'react';
import * as S from '../../index.styles';
import { Buttons } from '@apps/common-ui';
import { ExerciseTypes } from '@apps/common-utilities';
import { exercisePlanTypeLabel } from '../../../../../../components/ExerciseForm/utils';
import { ExercisePlanType } from '@apps/common-utilities/src/types/exerciseTypes';
import { monthDateYear } from '../../../../../../utils/dateUtils';

type Props = {
    onCopyExercises: Function;
    onDontCopyExercises: Function;
}

const ManageProgramNewPlanCopyExercises = ({ onCopyExercises, onDontCopyExercises }: Props) => {
    return (
        <>
            <S.Row>
                <S.StatusContainer status="success">New PoC Will Be Created</S.StatusContainer>
            </S.Row>
            <S.Row>
                <p>Would you like to copy exercises from the previous plan?</p>
            </S.Row>
            <S.Row>
                <Buttons.Button
                  type="button"
                  onClick={onCopyExercises}
                >
                    Yes
                </Buttons.Button>
                <Buttons.Button
                  type="button"
                  buttonType="secondary"
                  onClick={onDontCopyExercises}
                >
                    No
                </Buttons.Button>
            </S.Row>
        </>
    );
};

export default ManageProgramNewPlanCopyExercises;
