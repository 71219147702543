import React from 'react';
import { WEEKDAYS } from '../../../../../types/constants';
import * as S from '../index.styles';

const CalendarEntryTooltip = ({ exercise, exerciseFunctions }: {exercise: any, exerciseFunctions: any}) => (
    <S.CalendarTooltipContainer>
        {Object.keys(WEEKDAYS).map((dayOfWeek) => (
            <S.CalendarTooltipItem
              key={dayOfWeek}
              onClick={() => exerciseFunctions.cloneExercise(exercise, dayOfWeek)}
            >
                Clone to {WEEKDAYS[dayOfWeek]}
            </S.CalendarTooltipItem>
        ))}
        <S.CalendarTooltipItem
          danger
          onClick={() => exerciseFunctions.removeExercise(exercise)}
        >
            Remove
        </S.CalendarTooltipItem>
    </S.CalendarTooltipContainer>
);

export default CalendarEntryTooltip;
