import { createSlice } from '@reduxjs/toolkit';
import { Routes } from '../../../api/Routes';
import { ICoach, IGenericActionPlan, IUser, UserAssignment } from '../../../types/models';
import { AppDispatch } from '../../store';
import { removeKeys, setFirstPlanActive } from './utils';
import { TenantApi } from '../../../api/CoachingAPI';

export interface PatientState {
    actionPlans: IGenericActionPlan[] | [];
}

const initialState = {
    actionPlans: []
} as PatientState;

const patientSlice = createSlice({
    name: 'patient',
    initialState,
    reducers: {
        setActionPlans: (state, { payload }: {payload: { actionPlans: IGenericActionPlan[] }}) => {
            state.actionPlans = setFirstPlanActive(payload.actionPlans);
        },
        addActionPlan: (state, { payload }: {payload: { actionPlan: IGenericActionPlan }}) => {
            const newPlans = [...state.actionPlans, payload.actionPlan].sort((a, b) => {
                if (a.version && b.version) {
                    return b.version - a.version;
                }
                return 0;
            });
            // Make sure the new plan is active and others are inactive
            state.actionPlans = setFirstPlanActive(newPlans);
        }
    }
});

export const {
    setActionPlans,
    addActionPlan
} = patientSlice.actions;

export default patientSlice.reducer;

export const fetchActionPlans = ({ patientId }: { patientId: string }) => async (dispatch: AppDispatch) => {
    const actionPlans: any = await TenantApi.get(`/${Routes.coaches}/${Routes.users}/${patientId}/${Routes.actionPlans}`);
    const sortedPlans = actionPlans.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    dispatch(setActionPlans({ actionPlans: sortedPlans }));
};

export const createActionPlan = ({ patientId, coachId, actionPlan }:
    { patientId: string, actionPlan: IGenericActionPlan, coachId: string }) => async (dispatch: AppDispatch) => {
    let plan: IGenericActionPlan = {
        ...actionPlan,
        published: true,
        userId: patientId,
        createdBy: coachId,
        coachId,
        isActive: true
    };
    if (plan.id) {
        plan = removeKeys(plan, 'id');
        delete plan.version;
    }
    const newActionPlan: any = await TenantApi.post(`/${Routes.coaches}/${Routes.actionPlans}`, plan);
    dispatch(addActionPlan({ actionPlan: newActionPlan }));
};
