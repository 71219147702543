import React, { useState } from 'react';
import * as S from './index.styles';
import { Modal, useModal } from '@apps/common-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ApiResponse, RequestMethod, useApiRequest } from '../../../../hooks/useApiRequest';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';

const DeleteModuleModal = ({ showModal, dismissModal, moduleId }: {showModal: boolean, dismissModal: () => void, moduleId: number}) => {
    const [canDelete, setCanDelete] = useState(false);

    const { callApi: deleteRequest } = useApiRequest<any>(RequestMethod.DELETE);
    const navigate = useNavigate();

    const deleteModule = async () => {
        deleteRequest(`/coaches/education/modules/${moduleId}`).then(({ response, error }: ApiResponse<any>) => {
            if (error.hasError) {
                toast.error(`Failed to delete module. ${error.error || error.message}`, { duration: 5000 });
            } else {
                toast.success('Module deleted successfully', { duration: 5000 });
            }
            dismissModal();
        });
    };

    return (
        <Modal
          showModal={showModal}
          dismissModal={dismissModal}
          showTopBar={false}
        >
            <S.ModalContent>
                <S.TitleBar>
                    <S.Title>Delete Module?</S.Title>
                    <S.CloseButton
                      onClick={() => dismissModal()}
                    >
                        <FontAwesomeIcon icon={faX as IconProp} />
                    </S.CloseButton>
                </S.TitleBar>
                <S.InputContainer>
                    <S.InputLabel>Type &quot;Delete&quot; to confirm</S.InputLabel>
                    <S.Input
                      onChange={(e) => {
                        if (e.target.value === 'Delete') {
                            setCanDelete(true);
                        } else {
                            setCanDelete(false);
                        }
                      }}
                    />
                </S.InputContainer>
                <S.ButtonContainer>
                    <S.DeleteButton
                      canDelete={canDelete}
                      onClick={() => {
                        if (canDelete) {
                            deleteModule();
                            navigate('/learning');
                        }
                      }}
                    >
                        Delete
                    </S.DeleteButton>
                </S.ButtonContainer>
            </S.ModalContent>
        </Modal>
    );
};
export default DeleteModuleModal;
