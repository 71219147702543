import { activeHeartRateValueMap, HeartRateValue } from '../../../components/ExerciseForm/types';
import { ExerciseAction, ExerciseActions } from './actions';
import { ExerciseState } from './ExerciseContext';

export const reducer = (state: ExerciseState, action: ExerciseAction): ExerciseState => {
    switch (action.type) {
        case ExerciseActions.SET_EXERCISE_PLAN:
            return {
                ...state,
                exercisePlan: action.payload,
            };
        case ExerciseActions.SET_PROGRAM_INFO:
            return {
                ...state,
                exercisePlan: action.payload,
            };
        case ExerciseActions.ADD_ASSIGNED_EXCERCISE:
            state.exercisePlan.assignedExercises.push({
                ...action.payload,
                id: null,
                dayOfWeekOrder: state.exercisePlan.assignedExercises
                    .filter((e) => e.dayOfWeek === action.payload.dayOfWeek).length + 1,
                exerciseVariable: {
                    ...action.payload.exerciseVariable
                }
            });
            return {
                ...state,
                hasUnsavedChanges: true
            };
        case ExerciseActions.CLONE_DAY:
            state.exercisePlan.assignedExercises
                .filter((e) => e.dayOfWeek === action.payload.dayOfWeekToClone)
                .forEach((e) => {
                    state.exercisePlan.assignedExercises.push({
                        ...e,
                        id: null,
                        dayOfWeek: action.payload.dayOfWeek,
                        dayOfWeekOrder: state.exercisePlan.assignedExercises
                            .filter((exercise) => exercise.dayOfWeek === action.payload.dayOfWeek).length + 1,
                    });
                });
            return {
                ...state,
                hasUnsavedChanges: true
            };
        case ExerciseActions.MOVE_ASSIGNED_EXCERCISE_UP:
            state.exercisePlan.assignedExercises = state.exercisePlan.assignedExercises
                .map((e) => {
                    // if the exercise is the first exercise in the day, do nothing
                    if (e.dayOfWeek === action.payload.exercise.dayOfWeek
                        && e.dayOfWeekOrder === action.payload.exercise.dayOfWeekOrder
                        && e.dayOfWeekOrder === 1) {
                        return e;
                    }
                    // get the exercise to move and move it up
                    if (e.dayOfWeek === action.payload.exercise.dayOfWeek
                        && e.dayOfWeekOrder === action.payload.exercise.dayOfWeekOrder) {
                        return {
                            ...e,
                            dayOfWeekOrder: e.dayOfWeekOrder - 1
                        };
                    }
                    //  get the exercise that was above the moved exercise and move it down
                    if (e.dayOfWeek === action.payload.exercise.dayOfWeek
                        && e.dayOfWeekOrder === action.payload.exercise.dayOfWeekOrder - 1) {
                        return {
                            ...e,
                            dayOfWeekOrder: e.dayOfWeekOrder + 1
                        };
                    }
                    return e;
                });
            return {
                ...state,
                hasUnsavedChanges: true
            };
        case ExerciseActions.SET_EXERCISE_TO_EDIT:
            return {
                ...state,
                exerciseToEdit: action.payload
            };
        case ExerciseActions.EDIT_ASSIGNED_EXCERCISE:
            // if the id exists don't edit based on day of week and order
            // use id and day of week
            if (action.payload.id) {
                state.exercisePlan.assignedExercises = state.exercisePlan.assignedExercises
                    .map((e) => {
                        if (e.id === action.payload.id && e.dayOfWeek === action.payload.dayOfWeek) {
                            return {
                                ...e,
                                ...action.payload
                            };
                        }
                        return e;
                    });
            } else {
                // if the id doesn't exist edit based on day of week and order
                state.exercisePlan.assignedExercises = state.exercisePlan.assignedExercises
                    .map((e) => {
                        if (e.dayOfWeek === action.payload.dayOfWeek
                            && e.dayOfWeekOrder === action.payload.dayOfWeekOrder && !e.id) {
                            return {
                                ...e,
                                ...action.payload
                            };
                        }
                        return e;
                    });
            }
            return {
                ...state,
                hasUnsavedChanges: true
            };
        case ExerciseActions.MOVE_ASSIGNED_EXCERCISE_DOWN:
            state.exercisePlan.assignedExercises = state.exercisePlan.assignedExercises
                .map((e) => {
                    // if the exercise is the last exercise in the day, do nothing
                    if (e.dayOfWeek === action.payload.exercise.dayOfWeek
                        && e.dayOfWeekOrder === action.payload.exercise.dayOfWeekOrder
                        && e.dayOfWeekOrder === state.exercisePlan.assignedExercises
                            .filter((ex) => ex.dayOfWeek === e.dayOfWeek).length) {
                        return e;
                    }
                    // get the exercise to move and move it down
                    if (e.dayOfWeek === action.payload.exercise.dayOfWeek
                        && e.dayOfWeekOrder === action.payload.exercise.dayOfWeekOrder) {
                        return {
                            ...e,
                            dayOfWeekOrder: e.dayOfWeekOrder + 1
                        };
                    }
                    //  get the exercise that was below the moved exercise and move it up
                    if (e.dayOfWeek === action.payload.exercise.dayOfWeek
                        && e.dayOfWeekOrder === action.payload.exercise.dayOfWeekOrder + 1) {
                        return {
                            ...e,
                            dayOfWeekOrder: e.dayOfWeekOrder - 1
                        };
                    }
                    return e;
                });
            return {
                ...state,
                hasUnsavedChanges: true
            };
        case ExerciseActions.REMOVE_ASSIGNED_EXCERCISE:
            // Remove an exercise
            // If the assigned exercise has an id that means it's been saved
            // and we can filter based on id which should be unique
            if (action.payload.id) {
                state.exercisePlan.assignedExercises = state.exercisePlan.assignedExercises
                    .filter((e) => e.id !== action.payload.id);
            } else {
                // If the exercise doesn't have an id it hasn't been saved so we need to filter
                // based on day of week and order which should be unique
                state.exercisePlan.assignedExercises = state.exercisePlan.assignedExercises
                    .filter((e) => !(e.dayOfWeek === action.payload.dayOfWeek
                    && e.dayOfWeekOrder === action.payload.dayOfWeekOrder));
            }
            // need to update the day of week order after removing assigned exercises to prevent
            // exercises being overwritten accidentally because they have the same day of week order
            state.exercisePlan.assignedExercises = state.exercisePlan.assignedExercises
                .map((e) => {
                    if (e.dayOfWeek === action.payload.dayOfWeek
                        && e.dayOfWeekOrder > action.payload.dayOfWeekOrder) {
                        return {
                            ...e,
                            dayOfWeekOrder: e.dayOfWeekOrder - 1
                        };
                    }
                    return e;
                });
            return {
                ...state,
                hasUnsavedChanges: true
            };
        case ExerciseActions.REMOVE_DAY:
            state.exercisePlan.assignedExercises = state.exercisePlan.assignedExercises
                .filter((e) => e.dayOfWeek !== action.payload.dayOfWeek);
            return {
                ...state,
                hasUnsavedChanges: true
            };
        case ExerciseActions.SET_DEFAULT_HEART_RATE_RANGE:
            return {
                ...state,
                exercisePlan: {
                    ...state.exercisePlan,
                    maxHeartRatePercentOverride: action.payload.maxHeartRatePercent,
                    minHeartRatePercentOverride: action.payload.minHeartRatePercent,
                    assignedExercises: state.exercisePlan.assignedExercises
                        .map((e) => {
                            e.exerciseVariable.minHeartRatePercent = action.payload.minHeartRatePercent;
                            e.exerciseVariable.maxHeartRatePercent = action.payload.maxHeartRatePercent;
                            return e;
                        })
                }
            };
        case ExerciseActions.SET_UNSAVED_CHANGES:
            return {
                ...state,
                hasUnsavedChanges: action.payload,
            };
        case ExerciseActions.RESET_UNSAVED_CHANGES:
            return {
                ...state,
                hasUnsavedChanges: false
            };
        case ExerciseActions.SET_DISCHARGE_ID_ON_CREATE:
            // Don't discharge if current plan is already discharged
            if (state.exercisePlan.complete) {
                return state;
            }
            return {
                ...state,
                dischargeIdOnCreate: action.payload,
            };
        default:
            return state;
    }
};
