import styled from 'styled-components';

export const CalendarTooltipContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${props => props.theme.shades.offWhite};
  border: 1px solid ${props => props.theme.shades.darkGrey};
  border-radius: 6px;
  padding: 10px;
  z-index: 1;
`;

export const CalendarTooltipItem = styled.button<{danger?: boolean}>`
  border: none;
  background-color: transparent;
  color: ${props => props.danger ? props.theme.danger.medium : props.theme.text.black};
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid ${props => props.theme.shades.darkGrey};
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.shades.lighterGrey};
  }
`;
