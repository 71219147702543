import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 200px;
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const StatusContainer = styled.div<{status: 'success' | 'info' | 'danger'}>`
  display: flex;
  background-color: ${(props) => {
        switch (props.status) {
            case 'success':
                return props.theme.success.dark;
            case 'info':
                return props.theme.primary.dark;
            case 'danger':
                return props.theme.danger.dark;
            default:
                return props.theme.primary.dark;
        }
    }};
  color: ${(props) => props.theme.shades.white};
  border-radius: 5px;
  padding: 10px;
  margin: 10px 5px;
`;

export const Divider = styled.hr`
  width: 90%;
  margin: 20px 0;
`;
