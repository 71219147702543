import styled from 'styled-components';

export const EditorContainer = styled.div`
    min-height: 500px;
    width: 100%;
    border: 1px solid ${props => props.theme.shades.lightGrey};
    background-color: ${props => props.theme.shades.offWhite};
    border-radius: 3px;

    .DraftEditor-root {
        padding: 10px;
    }
`;
