import { Inputs } from '@apps/common-ui';
import React from 'react';
import * as S from '../index.styles';
import { ExerciseTypes } from '@apps/common-utilities';

const EditExerciseEquipment = ({
    toggleEquipment,
    equipmentIsAssigned,
    setEquipmentSpecification,
    exerciseEquipment,
    type
}: {
  toggleEquipment: (equipment: ExerciseTypes.ExerciseEquipment) => void;
  equipmentIsAssigned: (equipment: ExerciseTypes.ExerciseEquipment) => boolean;
  setEquipmentSpecification: (specification: string, equipment: ExerciseTypes.ExerciseEquipment) => void;
  exerciseEquipment: ExerciseTypes.IExerciseEquipment[];
  type: ExerciseTypes.ExerciseType;
}) => (
    <>
        <h4>Equipment</h4>
        <S.Section>
            <S.InputContainer>
                <Inputs.Checkbox
                  id="weights"
        // key forces re-render on state change which lets reset to default work
                  key={Math.random()}
                  onChange={() => toggleEquipment(ExerciseTypes.ExerciseEquipment.WEIGHTS)}
                  activeText="Weights"
                  inactiveText="Weights"
                  defaultChecked={exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.WEIGHTS)}
                  disabled={type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS}
                />
                {equipmentIsAssigned(ExerciseTypes.ExerciseEquipment.WEIGHTS) && (
                <Inputs.Input
                  onChange={(e) => setEquipmentSpecification(e.target.value, ExerciseTypes.ExerciseEquipment.WEIGHTS)}
                  value={exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.WEIGHTS)?.specification}
                  placeholder="Details"
                  size="medium"
                />
      )}
            </S.InputContainer>
            <S.InputContainer>
                <Inputs.Checkbox
                  id="assistive-device"
                  key={Math.random()}
                  onChange={() => toggleEquipment(ExerciseTypes.ExerciseEquipment.ASSISTED_DEVICES)}
                  activeText="Assistive Device"
                  inactiveText="Assistive Device"
                  defaultChecked={exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.ASSISTED_DEVICES)}
                  disabled={type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS}
                />
                {equipmentIsAssigned(ExerciseTypes.ExerciseEquipment.ASSISTED_DEVICES) && (
                <Inputs.Input
                  onChange={(e) => setEquipmentSpecification(e.target.value, ExerciseTypes.ExerciseEquipment.ASSISTED_DEVICES)}
                  value={exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.ASSISTED_DEVICES)?.specification}
                  placeholder="Details"
                  size="medium"
                />
                )}
                {equipmentIsAssigned(ExerciseTypes.ExerciseEquipment.ASSISTED_DEVICES)
                && !exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.ASSISTED_DEVICES)?.specification && (
                <S.InputError>Details required for assisted device</S.InputError>
                )}
            </S.InputContainer>
            <S.InputContainer>
                <Inputs.Checkbox
                  id="step-stairs"
                  key={Math.random()}
                  onChange={() => toggleEquipment(ExerciseTypes.ExerciseEquipment.STEP_STAIRS)}
                  activeText="Step/Stairs"
                  inactiveText="Step/Stairs"
                  defaultChecked={exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.STEP_STAIRS)}
                  disabled={type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS}
                />
                {equipmentIsAssigned(ExerciseTypes.ExerciseEquipment.STEP_STAIRS) && (
                <Inputs.Input
                  onChange={(e) => setEquipmentSpecification(e.target.value, ExerciseTypes.ExerciseEquipment.STEP_STAIRS)}
                  value={exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.STEP_STAIRS)?.specification}
                  placeholder="Details"
                  size="medium"
                />
      )}
            </S.InputContainer>
            <S.InputContainer>
                <Inputs.Checkbox
                  id="chair"
                  key={Math.random()}
                  onChange={() => toggleEquipment(ExerciseTypes.ExerciseEquipment.CHAIR)}
                  activeText="Chair"
                  inactiveText="Chair"
                  defaultChecked={exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.CHAIR)}
                  disabled={type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS}
                />
                {equipmentIsAssigned(ExerciseTypes.ExerciseEquipment.CHAIR) && (
                <Inputs.Input
                  onChange={(e) => setEquipmentSpecification(e.target.value, ExerciseTypes.ExerciseEquipment.CHAIR)}
                  value={exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.CHAIR)?.specification}
                  placeholder="Details"
                  size="medium"
                />
      )}
            </S.InputContainer>
            <S.InputContainer>
                <Inputs.Checkbox
                  id="bands"
                  key={Math.random()}
                  onChange={() => toggleEquipment(ExerciseTypes.ExerciseEquipment.BANDS)}
                  activeText="Bands"
                  inactiveText="Bands"
                  defaultChecked={exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.BANDS)}
                  disabled={type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS}
                />
                {equipmentIsAssigned(ExerciseTypes.ExerciseEquipment.BANDS) && (
                <Inputs.Input
                  onChange={(e) => setEquipmentSpecification(e.target.value, ExerciseTypes.ExerciseEquipment.BANDS)}
                  value={exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.BANDS)?.specification}
                  placeholder="Details"
                  size="medium"
                />
      )}
            </S.InputContainer>
        </S.Section>
    </>
);

export default EditExerciseEquipment;
