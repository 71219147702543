import { Buttons, Inputs } from '@apps/common-ui';
import { ExerciseTypes, Labels } from '@apps/common-utilities';
import { ExerciseEquipment } from '@apps/common-utilities/src/types/exerciseTypes';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Error } from '../../../../components/common/commonStyled';
import ExerciseList from '../../../ExerciseList';
import { ExerciseActions } from '../../state/actions';
import { ExerciseContext } from '../../state/ExerciseContext';
import EditExerciseDetails from './EditExerciseDetails';
import EditExerciseEquipment from './EditExerciseEquipment';
import EditExerciseFormat from './EditExerciseFormat';
import EditExerciseTargets from './EditExerciseTargets';
import * as S from './index.styles';
import { min } from 'date-fns';
import { HeartRateValue } from '../../../../components/ExerciseForm/types';

const AssignExercise = () => {
    const { day } = useParams();
    const { state, dispatch } = useContext(ExerciseContext);
    const [exerciseTemplate, setExerciseTemplate] = useState<ExerciseTypes.IExercise | null>(null);
    const [customExercise, setCustomExercise] = useState<ExerciseTypes.IExercise | null>(null);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const addExercise = (exercise: ExerciseTypes.IExercise) => {
        const exists = state.exercisePlan.assignedExercises.some((e) => e.exerciseId === exercise.exerciseId && e.dayOfWeek === day);
        if (exists) {
            setError('This exercise already exists for this day.');
            return;
        }
        const assignedExercise = {
            id: null,
            dayOfWeekOrder: 0,
            exercisePlanId: state.exercisePlan.id,
            userId: state.exercisePlan.userId,
            exerciseId: exercise.exerciseId,
            dayOfWeek: day,
            exerciseVariable: exercise.exerciseVariable,
            exerciseInfo: {
                id: null,
                title: exercise.exerciseTitle,
                type: exercise.exerciseType,
                category: exercise.category,
                videoUrl: exercise.videoUrl,
                instructions: exercise.instructions,
            }
        };
        if (
            assignedExercise.exerciseVariable.sets > 0
            && assignedExercise.exerciseVariable.amount > 0
            && (assignedExercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS
                ? (!!assignedExercise.exerciseVariable.rmtInhaleResistance
                    && assignedExercise.exerciseVariable.rmtInhaleResistance >= 1
                    && assignedExercise.exerciseVariable.rmtInhaleResistance <= 6
                    && !!assignedExercise.exerciseVariable.rmtExhaleResistance
                    && assignedExercise.exerciseVariable.rmtExhaleResistance >= 1
                    && assignedExercise.exerciseVariable.rmtExhaleResistance <= 5) : true)
            && (assignedExercise.exerciseInfo.type === ExerciseTypes.ExerciseType.HOLD ? (assignedExercise.exerciseVariable.holdSeconds > 0) : true)
            && (!assignedExercise.exerciseVariable.exerciseEquipment.find((e) => e.equipment === ExerciseTypes.ExerciseEquipment.ASSISTED_DEVICES && !e.specification))
        ) {
            dispatch({ type: ExerciseActions.ADD_ASSIGNED_EXCERCISE, payload: assignedExercise });
            navigate(-1);
            return;
        }
        setError('Please ensure all fields are valid.');
    };

    const customiseExercise = (exercise: ExerciseTypes.IExercise) => {
        setExerciseTemplate(exercise);
        setCustomExercise(exercise);
    };

    const resetExercise = () => {
        setCustomExercise(null);
        setExerciseTemplate(null);
    };

    const toggleEquipment = (equipment: ExerciseEquipment) => {
        if (customExercise === null) {
            return;
        }
        const equipmentIndex = customExercise.exerciseVariable.exerciseEquipment
            .findIndex((e) => e.equipment === equipment);
        if (equipmentIndex !== undefined && equipmentIndex !== -1) {
            setCustomExercise({
                ...customExercise,
                exerciseVariable: {
                    ...customExercise.exerciseVariable,
                    exerciseEquipment: customExercise.exerciseVariable.exerciseEquipment.filter((e) => e.equipment !== equipment)
                }
            });
        } else {
            setCustomExercise({
                ...customExercise,
                exerciseVariable: {
                    ...customExercise.exerciseVariable,
                    exerciseEquipment: [
                        ...customExercise.exerciseVariable.exerciseEquipment,
                        { equipment, specification: '' }
                    ]
                }
            });
        }
    };

    const setEquipmentSpecification = (value: string, equipment: ExerciseEquipment) => {
        if (customExercise === null) {
            return;
        }
        const equipmentIndex = customExercise.exerciseVariable.exerciseEquipment
            .findIndex((e) => e.equipment === equipment);
        if (equipmentIndex !== undefined && equipmentIndex !== -1) {
            setCustomExercise({
                ...customExercise,
                exerciseVariable: {
                    ...customExercise.exerciseVariable,
                    exerciseEquipment: customExercise.exerciseVariable.exerciseEquipment.map((e) => {
                        if (e.equipment === equipment) {
                            return { ...e, specification: value };
                        }
                        return e;
                    })
                }
            });
        }
    };

    const findEquipment = (equipment: ExerciseEquipment) => {
        if (customExercise !== null) {
            return !!customExercise.exerciseVariable.exerciseEquipment.find((e) => e.equipment === equipment);
        }
        return false;
    };

    const setExerciseVariable = (value: { [key: string]: any }) => {
        if (customExercise !== null) {
            setCustomExercise({
                ...customExercise,
                exerciseVariable: {
                    ...customExercise.exerciseVariable,
                    ...value
                }
            });
        }
    };

    const setHeartRate = (value: HeartRateValue, rest?: boolean) => {
        if (rest) {
            if (value === HeartRateValue.BELOW_FORTY) {
                setExerciseVariable({
                    heartRateRestPercent: 40,
                });
            } else if (value === HeartRateValue.BELOW_SIXTY) {
            // rest heart rate
                setExerciseVariable({
                    heartRateRestPercent: 60,
                });
            } else {
                setExerciseVariable({
                    heartRateRestPercent: null,
                });
            }
        } else {
            let minHeartRatePercent: number | null = 0;
            let maxHeartRatePercent: number | null = 0;
            switch (value) {
                case HeartRateValue.FORTY_TO_SIXTY:
                    minHeartRatePercent = 40;
                    maxHeartRatePercent = 60;
                    break;
                case HeartRateValue.SIXTY_TO_EIGHTY:
                    minHeartRatePercent = 60;
                    maxHeartRatePercent = 80;
                    break;
                case HeartRateValue.FORTY_TO_EIGHTY:
                    minHeartRatePercent = 40;
                    maxHeartRatePercent = 80;
                    break;
                default:
                    minHeartRatePercent = null;
                    maxHeartRatePercent = null;
                    break;
            }
            // active target
            setExerciseVariable({
                maxHeartRatePercent,
                minHeartRatePercent,
            });
        }
    };

    return (
        <S.Container>
            <Buttons.Button
              style={{ paddingLeft: 0, marginLeft: 0 }}
              buttonType="tertiary"
              type="button"
              onClick={() => exerciseTemplate ? setExerciseTemplate(null) : navigate(-1)}
            >
                <FontAwesomeIcon icon={faChevronLeft as IconProp} />
                Back
            </Buttons.Button>
            {!exerciseTemplate && <ExerciseList hasAction actionFunction={customiseExercise} actionLabel="Add" />}
            {exerciseTemplate && customExercise && (
                <div>
                    <EditExerciseDetails
                      exerciseTitle={customExercise.exerciseTitle}
                      exerciseInstructions={customExercise.instructions}
                      additionalInstructions={customExercise.exerciseVariable.additionalInstructions}
                      setAdditionalInstructions={(value) => setExerciseVariable({ additionalInstructions: value })}
                    />
                    <div>
                        <EditExerciseEquipment
                          toggleEquipment={toggleEquipment}
                          equipmentIsAssigned={findEquipment}
                          setEquipmentSpecification={setEquipmentSpecification}
                          exerciseEquipment={customExercise.exerciseVariable.exerciseEquipment}
                          type={customExercise.exerciseType}
                        />
                        <EditExerciseFormat
                          exerciseType={customExercise.exerciseType}
                          sets={customExercise.exerciseVariable.sets}
                          amount={customExercise.exerciseVariable.amount}
                          unit={customExercise.exerciseVariable.unit}
                          holdSeconds={customExercise.exerciseVariable.holdSeconds}
                          allowTime={customExercise.allowTime}
                          allowDistance={customExercise.allowDistance}
                          allowReps={customExercise.allowReps}
                          setExerciseVariable={setExerciseVariable}
                          inhaleResistance={customExercise.exerciseVariable.rmtInhaleResistance}
                          exhaleResistance={customExercise.exerciseVariable.rmtExhaleResistance}
                        />
                        <EditExerciseTargets
                          exerciseType={exerciseTemplate.exerciseType}
                          showBreathlessnessRest={customExercise.exerciseVariable.showBreathlessnessRest}
                          showBreathlessnessTarget={customExercise.exerciseVariable.showBreathlessnessTarget}
                          showSpO2TargetPercent={customExercise.exerciseVariable.showSpO2TargetPercent}
                          showSpO2RestPercent={customExercise.exerciseVariable.showSpO2RestPercent}
                          showHeartRateTarget={
                            !!customExercise.exerciseVariable.minHeartRatePercent
                            && !!customExercise.exerciseVariable.maxHeartRatePercent
                          }
                          showHeartRateRest={!!customExercise.exerciseVariable.heartRateRestPercent}
                          setShowTarget={(value: {}) => setCustomExercise({
                            ...customExercise,
                            exerciseVariable: {
                              ...customExercise.exerciseVariable,
                              ...value
                            }
                          })}
                          setHeartRate={setHeartRate}
                          heartRateRest={customExercise.exerciseVariable.heartRateRestPercent}
                          heartRateTargetMax={customExercise.exerciseVariable.maxHeartRatePercent}
                          heartRateTargetMin={customExercise.exerciseVariable.minHeartRatePercent}
                          requireBreathlessnessLevelReporting={customExercise.exerciseVariable.requireBreathlessnessLevelReporting}
                          requireImmediateSpO2PercentReporting={customExercise.exerciseVariable.requireImmediateSpO2PercentReporting}
                          requireLowestSpO2PercentReporting={customExercise.exerciseVariable.requireLowestSpO2PercentReporting}
                          requireActivityReporting={customExercise.exerciseVariable.requireActivityReporting}
                          requireRMTExhaleResistanceReporting={customExercise.exerciseVariable.requireRMTExhaleResistanceReporting}
                          requireHeartRateReporting={customExercise.exerciseVariable.requireHeartRateReporting}
                          requireRMTInhaleResistanceReporting={customExercise.exerciseVariable.requireRMTInhaleResistanceReporting}
                          requireRMTDifficultyReporting={customExercise.exerciseVariable.requireRMTDifficultyReporting}
                          requireFlowRateReporting={customExercise.exerciseVariable.requireFlowRateReporting}
                        />
                        <Error>{error}</Error>
                        <S.Section>
                            <S.ButtonGroup>
                                <Buttons.Button
                                  type="button"
                                  onClick={() => addExercise(customExercise)}
                                >
                                    Save Exercise
                                </Buttons.Button>
                                <Buttons.Button
                                  buttonType="tertiary"
                                  type="button"
                                  onClick={() => setCustomExercise({
                                    ...exerciseTemplate,
                                    exerciseVariable: { ...exerciseTemplate.exerciseVariable }
                                  })}
                                >
                                    Set to Default
                                </Buttons.Button>
                            </S.ButtonGroup>
                            <Buttons.Button
                              buttonType="tertiary"
                              type="button"
                              onClick={() => resetExercise()}
                            >
                                Cancel
                            </Buttons.Button>
                        </S.Section>
                    </div>
                </div>
            )}

        </S.Container>
    );
};

export default AssignExercise;
