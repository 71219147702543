import styled from 'styled-components';

interface RowItemProps {
    indent?: boolean;
}

export const ExercisePlanDates = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
`;

export const ExerciseRowItem = styled.div<{size?: 'small' | 'large', indent?: boolean}>`
    width: ${props => props.size === 'large' ? '350px' : '200px'};
    padding-left: ${props => props.indent ? '20px' : '0'};
`;

export const ExerciseFlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
`;

export const ExerciseRowBlock = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const ExerciseRowBlockTitle = styled.h3`
    margin-top: 0px;
    border-bottom: 2px solid ${props => props.theme.shades.lighterGrey};
`;

export const MultiColumnContainer = styled.div`
    display: flex;
    gap: 100px;
    width: 100%;
`;

export const ExerciseProgressContainer = styled.div`
    width: 100%;
    height: 20px;
    background-color: ${props => props.theme.shades.lighterGrey};
`;

export const ExerciseProgressLine = styled.div<{filledAmount: string}>`
    width: ${props => props.filledAmount};
    height: 100%;
    background-color: ${props => props.theme.primary.medium};
    border: 3px solid ${props => props.filledAmount === '100%' ? props.theme.danger.medium : 'transparent'};
`;
