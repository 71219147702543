import React from 'react';
import * as S from './index.styles';

export const CourseProgressBar = ({ percentage, text, color, backgroundColor, borderRadius }: {percentage: number, text: string, color?: string, backgroundColor?: string, borderRadius: string}) => (
    <S.ProgressBarBackground
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
    >
        <S.ProgressBarFill
          percentage={percentage}
          color={color}
          borderRadius={borderRadius}
        />
        <S.ProgressBarTextP>{text}</S.ProgressBarTextP>
    </S.ProgressBarBackground>
);

const ProgressBar = ({ percentage, color, backgroundColor, borderRadius }: { percentage: number, color?: string, backgroundColor?: string, borderRadius: string}) => (
    <S.ProgressBarBackground backgroundColor={backgroundColor} borderRadius={borderRadius}>
        <S.ProgressBarFill percentage={percentage} color={color} borderRadius={borderRadius}>
            <S.ProgressBarTextSpan>{percentage}%</S.ProgressBarTextSpan>
        </S.ProgressBarFill>
    </S.ProgressBarBackground>
);

export default ProgressBar;
