import styled from 'styled-components';

export const QuizForm = styled.form`
    max-width: 1200px;
    width: 100%;
    padding: 30px 50px;

    hr {
        margin: 20px 0;
    }
`;

export const ViewActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const Header = styled.div`
    h2 {
        margin: 0;
    }
    span {
        font-size: 14px;
        color: ${props => props.theme.shades.darkGrey};
        text-transform: capitalize;
    }
`;
