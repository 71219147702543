import { Buttons, Inputs } from '@apps/common-ui';
import styled from 'styled-components';

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px;
`;

export const TitleBar = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 20px 0;
`;

export const Title = styled.h3``;

export const CloseButton = styled(Buttons.Button)`
    background: transparent;
    color: ${props => props.theme.shades.grey};
    border: none;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
`;

export const InputLabel = styled.p`
    font-weight: bold;
    margin: 0 0 10px 0;
`;

export const Input = styled(Inputs.Input)`
    width: 90px;
    margin-left: 0;
`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: 20px;
    margin: 0 0 20px 0;
`;

export const DeleteButton = styled(Buttons.Button)<{canDelete: boolean}>`
    background: ${props => props.canDelete ? props.theme.buttons.red : props.theme.buttons.grey};
    color: ${props => props.theme.shades.white};
    border: ${props => props.canDelete ? props.theme.buttons.red : props.theme.buttons.grey} 1px solid;
    width: 100px;
    height: 50px;
    margin-left: 0;
`;

export const CancelButton = styled(Buttons.Button)`
    width: 165px;
    height: 50px;
    background: transparent;
    color: ${props => props.theme.buttons.blue};
`;
