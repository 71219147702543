import React from 'react';
import * as S from '../../index.styles';
import { ExerciseTypes } from '@apps/common-utilities';

interface Props {
    commentData : ExerciseTypes.IExerciseReport['commentData'];
}

const CommentExercise = (
    { title, comment } : { title?: string, comment: string | null}
) => {
    return (
        <S.CommentExerciseContainer>
            {!!title && <S.CommentExerciseTitle>{title}</S.CommentExerciseTitle>}
            {comment ? (
                <S.CommentText>{comment}</S.CommentText>
            ) : (
                <S.CommentNoCommentText>No comments left for this exercise</S.CommentNoCommentText>
            )}
        </S.CommentExerciseContainer>
    );
};

const Comments = ({ commentData }: Props) => {
    return (
        <S.CommentContainer>
            <S.CommentTitle>
                Comments
            </S.CommentTitle>
            {(commentData && commentData.length) ? (
                commentData.map((comment, index) => (
                    <CommentExercise key={index} title={comment.title} comment={comment.comment} />
                ))
            ) : (
                <S.CommentNoCommentText>No comments left today</S.CommentNoCommentText>
            )}
        </S.CommentContainer>
    );
};

export default {
    CommentExercise,
    Comments
};
