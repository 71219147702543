import React from 'react';
import * as S from './index.styles';
import RichTextInput from '../../../../components/RichTextInput';

type Props = {
    onChange: (html: string) => void;
    defaultValue?: string;
}

const QuizQuestionInput = ({ onChange, defaultValue }: Props) => (
    <S.EditorContainer>
        <RichTextInput
          onChange={onChange}
          defaultValue={defaultValue}
        />
    </S.EditorContainer>
);

export default QuizQuestionInput;
