import { useOnClickOutside } from '@apps/common-utilities';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import * as S from './index.styles';

type MenuItem = {
    label: string;
    type?: 'success' | 'danger'
    onClick: () => void;
};

type Props = {
    menuItems: MenuItem[];
};

const Menu = ({ menuItems }: Props) => {
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLUListElement>(null);
    const toggleMenu = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(!open);
    };

    useOnClickOutside(ref, () => setOpen(false));

    return (
        <S.MenuContainer>
            <S.MenuButton onClick={toggleMenu}>
                <FontAwesomeIcon icon={faEllipsisH as IconProp} />
            </S.MenuButton>
            {open && (
                <S.Menu ref={ref}>
                    {menuItems.map((item: MenuItem) => (
                        <S.MenuItem
                          key={item.label}
                          type={item.type}
                          onClick={item.onClick}
                        >
                            {item.label}
                        </S.MenuItem>
                    ))}
                </S.Menu>
            )}
        </S.MenuContainer>
    );
};

export default Menu;
