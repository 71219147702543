import { ExerciseTypes } from '@apps/common-utilities';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { RawExerciseFormData, defaultExercise, activeHeartRateValueMap, restHeartRateValueMap, HeartRateValue } from './types';
import { ExercisePlanType } from '@apps/common-utilities/src/types/exerciseTypes';

const getThumbnail = async (videoUrl: string): Promise<string> => {
    const url = `https://vimeo.com/api/oembed.json?url=${videoUrl}`;
    const response = await fetch(url).then(res => {
        if (res.ok) {
            return res.json();
        }
        return { thumbnail_url: '' };
    });
    return response.thumbnail_url;
};

const applyPortraitModeParam = (isPortrait: boolean, videoUrl: string): string => {
    const url = new URL(videoUrl);
    if (isPortrait) {
        url.searchParams.set('portrait', '1');
        return url.toString();
    }
    url.searchParams.delete('portrait');
    return url.toString();
};

// Map the exercise form data into an exercise object which is compatible with the backend
export const mapFormDataToExercise = async (rawData: RawExerciseFormData): Promise<ExerciseTypes.IExercise> => {
    const exerciseVar: ExerciseTypes.IExerciseVariable = defaultExercise.exerciseVariable;
    const exercise: ExerciseTypes.IExercise = {
        ...defaultExercise,
        exerciseTitle: rawData.exerciseTitle,
        instructions: rawData.instructions,
        videoUrl: applyPortraitModeParam(rawData.videoIsPortrait, rawData.videoUrl),
        category: rawData.category,
        exerciseType: rawData.exerciseVariable.type
    };
    if (rawData.exerciseVariable.type === ExerciseTypes.ExerciseType.HOLD) {
        exerciseVar.sets = parseInt(rawData.exerciseVariable.hold.numberOfHolds, 10);
        exerciseVar.amount = parseInt(rawData.exerciseVariable.hold.reps, 10);
        exerciseVar.holdSeconds = parseInt(rawData.exerciseVariable.hold.time, 10);
        exerciseVar.unit = ExerciseTypes.ExerciseIntervalUnit.REPS;
    } else if (rawData.exerciseVariable.type === ExerciseTypes.ExerciseType.INTERVALS) {
        exerciseVar.sets = parseInt(rawData.exerciseVariable.intervals.interval, 10);
        exerciseVar.amount = parseInt(rawData.exerciseVariable.intervals.reps, 10);
        exerciseVar.unit = rawData.exerciseVariable.intervals.unit;
        exercise.allowDistance = rawData.exerciseVariable.intervals.allowDistance;
        exercise.allowReps = rawData.exerciseVariable.intervals.allowReps;
        exercise.allowTime = rawData.exerciseVariable.intervals.allowTime;
    } else if (rawData.exerciseVariable.type === ExerciseTypes.ExerciseType.SETS_AND_REPS) {
        exerciseVar.sets = parseInt(rawData.exerciseVariable.setsAndReps.sets, 10);
        exerciseVar.amount = parseInt(rawData.exerciseVariable.setsAndReps.reps, 10);
        exerciseVar.unit = ExerciseTypes.ExerciseIntervalUnit.REPS;
    } else if (rawData.exerciseVariable.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS) {
        exerciseVar.sets = parseInt(rawData.exerciseVariable.setsAndBreathCycles.sets, 10);
        exerciseVar.amount = parseInt(rawData.exerciseVariable.setsAndBreathCycles.breathCycles, 10);
        exerciseVar.rmtInhaleResistance = parseInt(rawData.exerciseVariable.setsAndBreathCycles.inhaleResistance, 10);
        exerciseVar.rmtExhaleResistance = parseInt(rawData.exerciseVariable.setsAndBreathCycles.exhaleResistance, 10);
        exerciseVar.unit = ExerciseTypes.ExerciseIntervalUnit.BREATHS;
    }

    exerciseVar.showBreathlessnessTarget = rawData.activeTargets.breathlessness;
    exerciseVar.showSpO2TargetPercent = rawData.activeTargets.spO2;
    exerciseVar.showBreathlessnessRest = rawData.restTargets.breathlessness;
    exerciseVar.showSpO2RestPercent = rawData.restTargets.spO2;

    exerciseVar.requireActivityReporting = rawData.submissionOptions.requireExercise;
    exerciseVar.requireBreathlessnessLevelReporting = rawData.submissionOptions.requireBreathlessness;
    exerciseVar.requireFlowRateReporting = rawData.submissionOptions.requireOxygen;
    exerciseVar.requireHeartRateReporting = rawData.submissionOptions.requireHeartRate;
    exerciseVar.requireImmediateSpO2PercentReporting = rawData.submissionOptions.requireSpO2Immediate;
    exerciseVar.requireLowestSpO2PercentReporting = rawData.submissionOptions.requireSpO2Lowest;
    exerciseVar.requireRMTDifficultyReporting = rawData.submissionOptions.requireRMTDifficulty;
    exerciseVar.requireRMTExhaleResistanceReporting = rawData.submissionOptions.requireExhaleResistance;
    exerciseVar.requireRMTInhaleResistanceReporting = rawData.submissionOptions.requireInhaleResistance;

    if (rawData.activeTargets.hrRange) {
        // If the user has not selected a heart rate range, default to 40-60%
        if (!rawData.activeTargets.activeHrRange) {
            rawData.activeTargets.activeHrRange = HeartRateValue.FORTY_TO_SIXTY;
        }
        exerciseVar.minHeartRatePercent = activeHeartRateValueMap[rawData.activeTargets.activeHrRange].min;
        exerciseVar.maxHeartRatePercent = activeHeartRateValueMap[rawData.activeTargets.activeHrRange].max;
    } else {
        exerciseVar.minHeartRatePercent = 0;
        exerciseVar.maxHeartRatePercent = 0;
    }
    if (rawData.restTargets.hrRange) {
        // If the user has not selected a heart rate range, default to below 40%
        if (!rawData.restTargets.restHrRange) {
            rawData.restTargets.restHrRange = HeartRateValue.BELOW_FORTY;
        }
        exerciseVar.heartRateRestPercent = restHeartRateValueMap[rawData.restTargets.restHrRange];
    } else {
        exerciseVar.heartRateRestPercent = 0;
    }
    const thumbnail = await getThumbnail(rawData.videoUrl);
    exercise.thumbnailUrl = thumbnail;

    exerciseVar.exerciseEquipment = [];
    Object.keys(rawData.equipment).forEach((key) => {
        if (rawData.equipment[key].selected) {
            exerciseVar.exerciseEquipment.push({
                equipment: key as ExerciseTypes.ExerciseEquipment,
                specification: rawData.equipment[key].specification
            });
        }
    });
    exercise.exerciseVariable = exerciseVar;
    return exercise;
};

// Map an exercise object into a format that is compatible with the exercise form
export const mapExerciseToFormData = (exercise: ExerciseTypes.IExercise): RawExerciseFormData => {
    const exerciseVar = exercise.exerciseVariable;
    const rawData: any = {
        exerciseTitle: exercise.exerciseTitle,
        instructions: exercise.instructions,
        videoUrl: exercise.videoUrl,
        videoIsPortrait: exercise.videoUrl.includes('portrait=1'),
        category: exercise.category,
        exerciseVariable: {},
        activeTargets: {
            spO2: exerciseVar.showSpO2TargetPercent,
            hrRange: exerciseVar.minHeartRatePercent > 0,
            activeHrRange: Object.keys(activeHeartRateValueMap).find(key => {
                const value = activeHeartRateValueMap[key];
                return value.min === exerciseVar.minHeartRatePercent && value.max === exerciseVar.maxHeartRatePercent;
            }) as HeartRateValue,
            breathlessness: exerciseVar.showBreathlessnessTarget
        },
        restTargets: {
            spO2: exerciseVar.showSpO2RestPercent,
            hrRange: exerciseVar.heartRateRestPercent > 0,
            restHrRange: Object.keys(restHeartRateValueMap).find(key => {
                const value = restHeartRateValueMap[key];
                return value === exerciseVar.heartRateRestPercent;
            }) as HeartRateValue,
            breathlessness: exerciseVar.showBreathlessnessRest
        },
        equipment: {}
    };
    exerciseVar.exerciseEquipment.forEach(equipment => {
        rawData.equipment[equipment.equipment] = {
            selected: true,
            specification: equipment.specification
        };
    });
    rawData.exerciseVariable = {
        type: exercise.exerciseType,
    };
    switch (exercise.exerciseType) {
        case ExerciseTypes.ExerciseType.HOLD:
            rawData.exerciseVariable.hold = {
                numberOfHolds: exerciseVar.sets.toString(),
                reps: exerciseVar.amount.toString(),
                time: exerciseVar.holdSeconds.toString()
            };
            break;
        case ExerciseTypes.ExerciseType.INTERVALS:
            rawData.exerciseVariable.intervals = {
                interval: exerciseVar.sets.toString(),
                reps: exerciseVar.amount.toString(),
                unit: exerciseVar.unit,
                allowDistance: exercise.allowDistance,
                allowReps: exercise.allowReps,
                allowTime: exercise.allowTime
            };
            break;
        case ExerciseTypes.ExerciseType.SETS_AND_REPS:
            rawData.exerciseVariable.setsAndReps = {
                sets: exerciseVar.sets.toString(),
                reps: exerciseVar.amount.toString()
            };
            break;
        case ExerciseTypes.ExerciseType.SETS_AND_BREATHS:
            rawData.exerciseVariable.setsAndBreathCycles = {
                sets: exerciseVar.sets.toString(),
                breathCycles: exerciseVar.amount.toString(),
                inhaleResistance: exerciseVar.rmtInhaleResistance ? exerciseVar.rmtInhaleResistance.toString() : '',
                exhaleResistance: exerciseVar.rmtExhaleResistance ? exerciseVar.rmtExhaleResistance.toString() : ''
            };
            break;
        default:
            break;
    }
    rawData.submissionOptions = {
        requireSpO2Lowest: exerciseVar.requireLowestSpO2PercentReporting,
        requireSpO2Immediate: exerciseVar.requireImmediateSpO2PercentReporting,
        requireHeartRate: exerciseVar.requireHeartRateReporting,
        requireOxygen: exerciseVar.requireFlowRateReporting,
        requireExercise: exerciseVar.requireActivityReporting,
        requireInhaleResistance: exerciseVar.requireFlowRateReporting,
        requireExhaleResistance: exerciseVar.requireRMTExhaleResistanceReporting,
        requireRMTDifficulty: exerciseVar.requireRMTDifficultyReporting,
        requireBreathlessness: exerciseVar.requireBreathlessnessLevelReporting
    };
    return rawData;
};

export const getDefaultEndDate = (start: Date) => {
    const end = new Date(start);

    // default program duration is 12 weeks
    end.setDate(end.getDate() + 12 * 7);
    return end;
};

export const exercisePlanTypeLabel = (type: ExercisePlanType) => {
    switch (type) {
        case ExercisePlanType.VPR:
            return 'vPR';
        case ExercisePlanType.PT_MAINTENANCE:
            return 'PT Maintenance';
        case ExercisePlanType.NON_PT_MAINTENANCE:
            return 'Non-PT Maintenance (PCM)';
        default:
            return type;
    }
};
