import { Inputs } from '@apps/common-ui';
import React from 'react';

const EditExerciseDetails = ({
    exerciseTitle,
    exerciseInstructions,
    additionalInstructions,
    setAdditionalInstructions
}: {
    exerciseTitle: string;
    exerciseInstructions: string;
    additionalInstructions: string;
    setAdditionalInstructions: (value: string) => void;
}) => (
    <>
        <h3>{exerciseTitle}</h3>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: exerciseInstructions }} />
        <h4>Additional Instructions</h4>
        <Inputs.TextArea
          value={additionalInstructions}
          onChange={(e) => setAdditionalInstructions(e.target.value)}
        />
    </>
);

export default EditExerciseDetails;
