import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const Subheading = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const QuestionRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const QuestionCorrectness = styled.h3<{correct: boolean}>`
  color: ${(props) => props.correct ? props.theme.success.dark : props.theme.danger.dark};
  svg {
    margin-right: 10px;
  }
`;

export const QuestionResponse = styled.div`
  width: 100%;

  hr {
    width: 80%;
    margin: 30px 0;
  }
`;

export const Question = styled.h4`
`;

export const Answer = styled.h4<{correct: boolean, selected: boolean}>`
  border: 2px solid ${(props) => props.theme.shades.grey};
  border-radius: 5px;
  padding: 10px;
  width: 80%;
  margin: 10px 0;
  color: ${props => props.theme.shades.white};
  background-color: ${props => props.theme.shades.white};
  ${(props) => !props.correct && props.selected && `
    color: ${props.theme.shades.white};
    background-color: ${props.theme.danger.medium};
    border-color: ${props.theme.danger.medium};
  `}
  ${(props) => props.correct && props.selected && `
    background-color: ${props.theme.success.dark};
    border-color: ${props.theme.success.dark};
  `}
  ${(props) => props.correct && !props.selected && `
    color: ${props.theme.danger.dark};
    border-color: ${props.theme.danger.dark};
  `}
  ${(props) => !props.correct && !props.selected && `
    color: ${props.theme.shades.grey};
  `}
`;
