import React from 'react';
import { ActionPlanZoneType, MedicalCondition } from '../../../types/models';
import ActionPlanZone from '../components/ActionPlanZone';
import FlowRateInputs from '../components/FlowRateInputs';
import ProviderInfo from '../components/ProviderInfo';

const AsthmaActionPlan = () => (
    <div>
        <ProviderInfo />
        <ActionPlanZone
          zoneType={ActionPlanZoneType.GREEN}
          flowRate={<FlowRateInputs zoneType={ActionPlanZoneType.GREEN} condition={MedicalCondition.ASTHMA} />}
        />
        <ActionPlanZone
          zoneType={ActionPlanZoneType.YELLOW}
          flowRate={<FlowRateInputs zoneType={ActionPlanZoneType.YELLOW} condition={MedicalCondition.ASTHMA} />}
        />
        <ActionPlanZone
          zoneType={ActionPlanZoneType.RED}
          flowRate={<FlowRateInputs zoneType={ActionPlanZoneType.RED} condition={MedicalCondition.ASTHMA} />}
        />
    </div>
);

export default AsthmaActionPlan;
