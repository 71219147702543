import React, { useState } from 'react';
import * as S from './index.styles';
import { Modal } from '@apps/common-ui';
import { differenceInSeconds } from 'date-fns';
import { BillableSessionStatus, IBillableSession, IBillableSessionCreationResponse, IUser } from '../../../types/models';
import toast from 'react-hot-toast';
import { getSessionLengthString } from '../Utils/utils';
import { ApiResponse, RequestMethod, useApiRequest } from '../../../hooks/useApiRequest';
import SessionDateForm from '../SessionDateForm';

interface Props {
    showModal: boolean;
    dismissModal: () => void;
    patientId: string;
    provider: IUser | null;
    refreshSessions: () => void;
}

const AddEntryModal = ({ showModal, dismissModal, provider, patientId, refreshSessions }: Props) => {
    const [newStartDate, setNewStartDate] = useState(new Date());
    const [newEndDate, setNewEndDate] = useState(new Date());
    const [apiError, setApiError] = useState<string>('');
    const { callApi } = useApiRequest<IBillableSessionCreationResponse>(RequestMethod.POST);

    const changeDate = (date: Date) => {
        const newStart = new Date(newStartDate);
        newStart.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        setNewStartDate(newStart);
        const newEnd = new Date(newEndDate);
        newEnd.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        setNewEndDate(newEnd);
    };

    const getErrorMessage = () => {
        if (newStartDate > newEndDate) {
            return 'End time must be after start time';
        } else if (newStartDate > new Date() || newEndDate > new Date()) {
            return 'Start and end times must be in the past';
        }
        return '';
    };

    const createSession = async () => {
        if (!provider?.id || !patientId) {
            return;
        }

        const body: IBillableSession = {
            userId: patientId,
            providerId: provider.id,
            providerName: `${provider.firstName} ${provider.lastName}`,
            startDate: newStartDate,
            endDate: newEndDate,
        };

        // send create timer event to backend
        await callApi(`/users/${patientId}/billable-sessions`, body)
            .then(({ response, error }: ApiResponse<IBillableSessionCreationResponse>) => {
                if (!!error.error || !!error.message) {
                    setApiError(error.error || error.message);
                } else if (response.data.id) {
                    toast.success('Timer entry created');
                    refreshSessions();
                    dismissModal();
                }
            }).catch((err) => {
                toast.error('Error creating timer entry');
            });
    };

    return (
        <Modal showModal={showModal} dismissModal={dismissModal} showTopBar={false} closeOnOutsideClick={false} minWidth="800px">
            <S.Container>
                <S.Title>Add Time Entry</S.Title>
                <S.Subtitle>
                    All fields are defaulted to the current time. Make sure to update these fields to the best of your knowledge to capture the time
                </S.Subtitle>
                <SessionDateForm
                  newStartDate={newStartDate}
                  newEndDate={newEndDate}
                  setNewStartDate={setNewStartDate}
                  setNewEndDate={setNewEndDate}
                  changeDate={changeDate}
                />
                <S.NewTime>Add time: <b>{differenceInSeconds(newEndDate, newStartDate)} seconds</b> ({getSessionLengthString(differenceInSeconds(newEndDate, newStartDate))})</S.NewTime>
                {!!getErrorMessage && <S.ErrorMessage>{getErrorMessage()}</S.ErrorMessage>}
                {!!apiError && <S.ErrorMessage>{apiError}</S.ErrorMessage>}
                <S.ButtonContainer>
                    <S.ConfirmButton
                      onClick={() => createSession()}
                      disabled={!!getErrorMessage() || differenceInSeconds(newEndDate, newStartDate) === 0}
                    >
                        Add Entry
                    </S.ConfirmButton>
                    <S.CancelButton onClick={dismissModal}>Cancel</S.CancelButton>
                </S.ButtonContainer>
            </S.Container>
        </Modal>
    );
};
export default AddEntryModal;
