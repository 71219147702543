import { Buttons, Inputs } from '@apps/common-ui';
import React, { useMemo } from 'react';
import Menu from '../../../../components/Menu';
import QuizQuestionInput from '../QuizQuestionInput';
import * as S from './index.styles';
import { Controller, useFieldArray } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as quizUtils from '../../utils';

type Props = {
    register: any;
    control: any;
    index: number;
    onToggleCollapse: (e: any) => void;
    removeQuestion: (questionIndex: number) => void;
    watch: any;
}

const QuizQuestion = ({ index, control, register, onToggleCollapse, removeQuestion, watch }: Props) => {
    // Detect when the question has multiple correct answers
    const allValues = watch();
    const isMultiSelect = useMemo(() => {
        if (!allValues.questions) return false;
        const storedQuestion = allValues.questions.find((q: any, qindex: number) => qindex === index);
        if (!storedQuestion?.answers) return false;
        return quizUtils.isMultiSelect(storedQuestion);
    }, [allValues]);
    const { fields: answerFields, append, remove } = useFieldArray({
        control,
        name: `questions.${index}.answers`,
        shouldUnregister: true
    });

    const removeAnswer = (answerIndex: number) => {
        remove(answerIndex);
    };

    const addAnswer = (e: any) => {
        e.preventDefault();
        append({ answer: '', correct: false }, { shouldFocus: true });
    };

    const collapsed = watch(`questions.${index}.collapsed`);

    return (
        <div>
            <S.Header>
                <S.QuestionTitle>
                    <h3>Question {index + 1}</h3>
                    <Menu menuItems={[{ label: 'Remove', type: 'danger', onClick: () => removeQuestion(index) }]} />
                </S.QuestionTitle>
                <Buttons.Button
                  onClick={onToggleCollapse}
                  buttonType="tertiary"
                >
                    {!collapsed ? '- Collapse' : '+ Expand'}
                </Buttons.Button>
            </S.Header>
            {!collapsed && (
                <>
                    <Controller
                      name={`questions.${index}.question`}
                      control={control}
                      defaultValue={null}
                      render={({ field: { onChange, value } }) => (
                          <QuizQuestionInput
                            onChange={onChange}
                            defaultValue={value}
                          />
                        )}
                    />
                    <h4>Answers - {isMultiSelect
                        ? <>Multi-select <FontAwesomeIcon icon={faCheckDouble as IconProp} /></>
                        : <>Single select <FontAwesomeIcon icon={faCheck as IconProp} /></>}
                    </h4>
                    <S.Answers>
                        {answerFields.map((answer: any, answerIndex: number) => (
                            <S.AnswerContainer key={answer.id}>
                                <label htmlFor={`questions.${index}.answers.${answerIndex}.correct`}>
                                    <input
                                      type="checkbox"
                                      id={`questions.${index}.answers.${answerIndex}.correct`}
                                      {...register(`questions.${index}.answers.${answerIndex}.correct`)}
                                    />
                                    Correct
                                </label>
                                <Inputs.Input
                                  id={`questions.${index}.answers.${answerIndex}.answer`}
                                  placeholder="Answer"
                                  {...register(`questions.${index}.answers.${answerIndex}.answer`, { required: true })}
                                />
                                <Menu
                                  menuItems={[{ label: 'Remove', type: 'danger', onClick: () => removeAnswer(answerIndex) }]}
                                />
                            </S.AnswerContainer>
                        ))}
                        <Buttons.Button buttonType="tertiary" onClick={(e) => addAnswer(e)}>+ Add Answer Option</Buttons.Button>
                    </S.Answers>
                </>
            )}
            <hr />
        </div>
    );
};

export default QuizQuestion;
