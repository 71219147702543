import React, { useState } from 'react';
import { BillableSessionStatus, IBillableSession, IFetchedBillableSession } from '../../../types/models';
import * as S from './index.styles';
import { formatBillableSessionDate, getSessionLengthString } from '../Utils/utils';
import { Modal } from '@apps/common-ui';
import toast from 'react-hot-toast';
import ApiError from '@apps/common-utilities/src/api/ApiError';
import { RequestMethod, useApiRequest, ApiResponse } from '../../../hooks/useApiRequest';
import SessionDateForm from '../SessionDateForm';

interface Props {
    showModal: boolean;
    dismissModal: () => void;
    billableSession?: IFetchedBillableSession;
    refreshSessions: () => void;
}
export const EditEntryModal = ({ showModal, dismissModal, billableSession, refreshSessions }: Props) => {
    const [newStartDate, setNewStartDate] = useState<Date>(billableSession ? new Date(billableSession.startDate) : new Date());
    const [newEndDate, setNewEndDate] = useState<Date>(billableSession ? new Date(billableSession.endDate) : new Date());
    const { callApi } = useApiRequest<IBillableSession>(RequestMethod.PUT);
    const [apiError, setApiError] = useState('');

    const changeDate = (date: Date) => {
        const newStart = new Date(newStartDate);
        newStart.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        setNewStartDate(newStart);
        const newEnd = new Date(newEndDate);
        newEnd.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        setNewEndDate(newEnd);
    };

    const getErrorMessage = () => {
        if (newStartDate > newEndDate) {
            return 'End time must be after start time';
        } else if (newStartDate > new Date() || newEndDate > new Date()) {
            return 'Start and end times must be in the past';
        }
        return '';
    };

    const getNewSessionLength = () => {
        return Math.floor((newEndDate.getTime() - newStartDate.getTime()) / 1000);
    };

    const getDifferenceBetweenOriginalAndNewSessionLength = () => {
        if (!billableSession) {
            return 0;
        }
        const difference = getNewSessionLength() - billableSession.seconds;
        const hours = Math.floor(Math.abs(difference / 3600));
        let minutes = `${Math.floor(Math.abs((difference % 3600) / 60))}`;
        if (minutes.length === 1) {
            minutes = `0${minutes}`;
        }
        let seconds = `${Math.abs(difference % 60)}`;
        if (seconds.length === 1) {
            seconds = `0${seconds}`;
        }
        if (difference >= 0) {
            return `+${hours}:${minutes}:${seconds}`;
        }
        return `-${hours}:${minutes}:${seconds}`;
    };

    const updateSession = async () => {
        if (!billableSession) {
            return;
        }

        const body: IBillableSession = {
            userId: billableSession.userId,
            providerId: billableSession.providerId,
            providerName: billableSession.providerName,
            startDate: newStartDate,
            endDate: newEndDate,
            status: billableSession.status
        };

        // send update timer event to backend
        await callApi(`/users/${billableSession.userId}/billable-sessions/${billableSession.id}`, body)
            .then(({ response, error }: ApiResponse<IBillableSession>) => {
                if (!!error.error || !!error.message) {
                    setApiError(error.error || error.message);
                    toast.error('Error updating timer entry');
                } else if (response.data.id) {
                    toast.success('Timer entry updated');
                    refreshSessions();
                    dismissModal();
                }
            }).catch((err: ApiError) => {
                toast.error('Error updating timer entry');
            });
    };

    if (!billableSession) {
        return null;
    }

    return (
        <Modal showModal={showModal} dismissModal={dismissModal} showTopBar={false} closeOnOutsideClick={false} minWidth="800px">
            <S.Container>
                <S.Title>Edit Time Entry</S.Title>
                <S.TimeContainer>
                    <S.Time>
                        {getSessionLengthString(billableSession.seconds)} on {formatBillableSessionDate(billableSession.calendarDate, true)}
                    </S.Time>
                </S.TimeContainer>
                <SessionDateForm
                  newStartDate={newStartDate}
                  newEndDate={newEndDate}
                  setNewStartDate={setNewStartDate}
                  setNewEndDate={setNewEndDate}
                  changeDate={changeDate}
                />
                <S.NewTime><b>{getNewSessionLength()} {Math.abs(getNewSessionLength()) !== 1 ? 'seconds' : 'second'}</b> ({getDifferenceBetweenOriginalAndNewSessionLength()})</S.NewTime>
                {!!getErrorMessage() && <S.ErrorMessage>{getErrorMessage()}</S.ErrorMessage>}
                {!!apiError && <S.ErrorMessage>{apiError}</S.ErrorMessage>}
                <S.ButtonContainer>
                    <S.ConfirmButton onClick={() => updateSession()} disabled={!!getErrorMessage()}>Confirm Changes</S.ConfirmButton>
                    <S.CancelButton onClick={dismissModal}>Cancel</S.CancelButton>
                </S.ButtonContainer>
            </S.Container>
        </Modal>
    );
};

export default EditEntryModal;
