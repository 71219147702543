import { Buttons, useModal, ProgressBar, theme } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import { LearningTypes } from '@apps/common-utilities';
import * as S from './index.styles';
import { useNavigate, useParams } from 'react-router';
import { format, isFuture } from 'date-fns';
import { ProgramStatusIndicator } from '../../../../../../components/common/commonStyled';
import { NaiveDate } from '../../../../../../utils/dateUtils';
import { TenantApi } from '../../../../../../api/CoachingAPI';
import { CourseProgressBar } from '@apps/common-ui/src/ProgressBar';
import { RequestMethod, useApiRequest } from '../../../../../../hooks/useApiRequest';
import { fetchCourseSummary } from '../../../../../../state/reducers/learning';
import { useActionLoader } from '../../../../../../hooks/useActionLoader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/store';

export enum ProgramStatus {
    ACTIVE = 'active',
    COMPLETED = 'completed',
    UNASSIGNED = 'unassigned',
    PAUSED = 'paused'
}

const CourseProgress = () => {
    const { patientId } = useParams<{patientId: string}>();
    const { courseSummary } = useSelector((state: RootState) => state.learning);

    const navigate = useNavigate();
    const { callAction: getCourseSummary, done, loading } = useActionLoader(fetchCourseSummary);

    const initializeCourseSummary = () => {
        getCourseSummary(patientId);
    };

    useEffect(() => {
        if (patientId) {
            initializeCourseSummary();
        }
    }, [patientId]);

    return (
        <S.Container>
            {!!courseSummary && !!courseSummary.courseUserAssignmentId && (
                <>
                    {courseSummary.isPaused && <ProgramStatusIndicator status="danger">Paused</ProgramStatusIndicator>}
                    {isFuture(new Date(courseSummary.startDate)) && <ProgramStatusIndicator status="info">Start Date: {new NaiveDate(courseSummary.startDate).toLocaleDateString()}</ProgramStatusIndicator>}
                    <S.Body>
                        <S.CourseDetails>
                            <span>Start Date: {format(new NaiveDate(courseSummary.startDate), 'MM-dd-yyyy')}</span>
                        </S.CourseDetails>
                        <S.ProgressRow>
                            {courseSummary.moduleCompletions.map((moduleCompletion) => (
                                <S.ModuleProgressContainer>
                                    <CourseProgressBar
                                      percentage={(moduleCompletion.completedSubModules / moduleCompletion.totalSubModules) * 100}
                                      color={theme.buttons.green}
                                      backgroundColor={theme.shades.darkestGrey}
                                      borderRadius="5px"
                                      text={`${moduleCompletion.completedSubModules}/${moduleCompletion.totalSubModules}`}
                                    />
                                </S.ModuleProgressContainer>
                        ))}
                        </S.ProgressRow>
                        <div>
                            <S.ManageButton onClick={() => navigate(`/patients/${patientId}/education-plan`)}>Manage</S.ManageButton>
                        </div>
                    </S.Body>
                </>
            )}
            {(!courseSummary || !courseSummary.courseUserAssignmentId) && (
                <>
                    <h3>No Course Assigned</h3>
                    <S.NoCourseButton>
                        <Buttons.Button onClick={() => navigate(`/patients/${patientId}/education-plan`)}>Assign Course</Buttons.Button>
                    </S.NoCourseButton>
                </>
            )}
        </S.Container>
    );
};

export default CourseProgress;
