import Select from 'react-select';
import React from 'react';
import { theme } from '@apps/common-ui';

// Using react-select. View docs - https://react-select.com/home
const MultiSelect = (props: any) => {
    const { placeholder } = props;

    const colourStyles = {
        control: (styles: any) => ({
            ...styles,
            width: '100%',
            maxWidth: '400px',
            borderRadius: '10px',
            backgroundColor: theme.shades.offWhite,
            margin: '10px',
            padding: '2px',
            boxShadow: 'none',
            border: `1px solid ${theme.shades.lightGrey}`,
            '&:hover': {
                border: `1px solid ${theme.shades.lightGrey}`
            }

        }),
        option: (styles: any, _: any) => ({
            ...styles,
            ':hover': {
                backgroundColor: theme.buttons.blue,
                color: 'white'
            },
        }),
        multiValueLabel: (styles: any) => ({ ...styles, backgroundColor: theme.buttons.blue, color: 'white' }),
        multiValueRemove: (styles: any) => ({
            ...styles,
            backgroundColor: theme.buttons.blue,
            color: 'white',
            ':hover': {
                backgroundColor: theme.buttons.blue,
                color: 'white',
                opacity: 0.8
            }
        }),
        placeholder: (styles: any) => ({
            ...styles,
            color: 'black',
        })
    };

    props = {
        ...props,
        styles: colourStyles,
        menuPosition: 'fixed',
        placeholder: placeholder ?? 'Select',
        isMulti: true,
    };

    return (
        <Select
          {...props}
        />
    );
};

export default MultiSelect;
