import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ChangeDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  svg {
    cursor: pointer;
  }
`;

export const WeeklySummary = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DaySummaryContainer = styled.div<{ active?: boolean, disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.shades.lightGrey};
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.shades.lightGrey};
  }
  ${props => props.active && `
    background-color: ${props.theme.primary.medium};};
    color: ${props.theme.text.white};
    &:hover {
      background-color: ${props.theme.primary.medium};
    }
  `}
  ${props => props.disabled && `
    background-color: ${props.theme.shades.lighterGrey};
    &:hover {
      cursor: not-allowed;
      background-color: ${props.theme.shades.lighterGrey};
    }
  `}
`;

export const DaySummaryHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5px;
  width: 100%;

  h4 {
    margin: 0;
  }
`;

export const WarningIconContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.danger.medium};
  padding: 5px 15px;
  border-radius: 5px;
  margin: 0 0 0 10px;
  color: ${props => props.theme.text.white};
`;

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DailyInfoContainer = styled.div<{ column?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  ${props => props.column && `
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const DailyInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid ${props => props.theme.shades.lightGrey};
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 200px;
  p {
    margin: 0;
  }
`;

export const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
`;

export const DateButton = styled(Buttons.Button)`
  background-color: transparent;
  border: 1px solid ${props => props.theme.text.black};
  color: ${props => props.theme.text.black};
  padding: 5px 10px;

  svg {
    color: ${props => props.theme.text.black};
  }

  &:hover {
    background-color: ${props => props.theme.text.lightGrey};
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ChartDrawer = styled.details`
  margin: 20px 0;
  summary {
    cursor: pointer;
    user-select: none;
    margin-bottom: 30px;
  }
`;

export const Reporting = styled.div`
  margin: 20px 0;
`;

export const SessionChartContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const RMTDetailsContainer = styled.div`
  display: flex;
`;

export const RMTChartRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.shades.lightGrey};
  flex-wrap: wrap;
`;

export const RMTReportingSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 20px;
  min-width: 200px;

  p {
    margin: 5px 0;
  }
  b {
    margin-left: 5px;
  }
`;

export const RMTChartContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 10px;
  min-width: 500px;
`;

export const RMTCommentRow = styled.div`
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.shades.lightGrey};
`;

export const RMTCommentTitle = styled.h4`
  margin-right: 20px;
`;

export const ExerciseWarningList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
`;

export const ExerciseWarningTextContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${props => props.theme.danger.medium};
  padding: 5px 15px;
  border-radius: 5px;
  margin: 10px;
  color: ${props => props.theme.text.white};
  h3 {
    margin: 0;
  }
`;

export const ExerciseWarningContainer = styled.details`
  margin: 20px 0;
  width: 100%;
  summary {
    cursor: pointer;
    user-select: none;
  }
`;

export const ExerciseWarningListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const WarningText = styled.summary`
  color: ${props => props.theme.danger.medium};
  font-weight: 600;
  margin: 0;
`;

export const CommentContainer = styled.div`
    margin-top: 20px;
`;

export const CommentTitle = styled.h2`
    margin: 0;
    font-weight: normal;
`;

export const CommentExerciseContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
`;

export const CommentExerciseTitle = styled.p`
    font-weight: 600;
`;

export const CommentText = styled.p`
    margin: 0;
`;

export const CommentNoCommentText = styled.p`
    margin: 0;
    font-style: italic;
`;
