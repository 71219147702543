import { createSlice } from '@reduxjs/toolkit';
import { LearningTypes } from '@apps/common-utilities';
import { Routes } from '../../../api/Routes';
import { AppDispatch } from '../../store';
import { TenantApi } from '../../../api/CoachingAPI';
import { IModule } from '@apps/common-utilities/src/types/learningTypes';

export interface LearningState {
    courseSummary: LearningTypes.ICourseSummary;
    modules: LearningTypes.IModule[];
}

const initialState: LearningState = {
    courseSummary: {
        courseUserAssignmentId: 0,
        isPaused: false,
        startDate: '',
        pauseReason: null,
        moduleCompletions: []
    },
    modules: []
};

const learningSlice = createSlice({
    name: 'learning',
    initialState,
    reducers: {
        setModules: (state, { payload }: {payload: { modules: LearningTypes.IModule[] }}) => {
            state.modules = payload.modules;
        },
        setCourseSummary: (state, { payload }: {payload: { courseSummary: LearningTypes.ICourseSummary }}) => {
            state.courseSummary = payload.courseSummary;
        }
    }
});

export const {
    setModules,
    setCourseSummary
} = learningSlice.actions;

export default learningSlice.reducer;

export const fetchModules = () => async (dispatch: AppDispatch) => {
    const modules = await TenantApi.get(`/${Routes.coaches}/${Routes.education}/${Routes.modules}`);
    dispatch(setModules({ modules }));
};

export const fetchCourseSummary = (userId: string) => async (dispatch: AppDispatch) => {
    const courseSummary = await TenantApi.get(`/${Routes.coaches}/${Routes.users}/${userId}/${Routes.education}/${Routes.courses}/report/summary`);
    dispatch(setCourseSummary({ courseSummary }));
};
