import { Inputs } from '@apps/common-ui';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ActionPlanContext } from '../..';
import { ReducerActionType } from '../../reducer';
import * as S from './index.styles';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ActionPlanLabel } from '../common';

const ProviderInfo = () => {
    const { actionPlan, updateActionPlan } = useContext(ActionPlanContext);

    const schema = yup.object({
        providerName: yup.string().required('Provider name is required'),
        phoneNumber: yup.string()
            .matches(/^\d{10}$/, 'Invalid phone number')
            .required('Provider phone number is required'),
        emergencyContactName: yup.string().required('Emergency contact name is required'),
        emergencyContactPhone: yup.string()
            .matches(/^\d{10}$/, 'Invalid phone number')
            .required('Emergency contact number is required'),
    }).required();
    const { register, getValues, watch, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema)
    });

    // Propagate changes to parent component
    useEffect(() => {
        const s = watch((_, { name, type }) => {
            if (name) {
                updateActionPlan({
                    type: ReducerActionType.UPDATE_PLAN,
                    payload: {
                        [name]: getValues(name)
                    }
                });
            }
        });
        return () => s.unsubscribe();
    }, [watch]);

    return (
        <>
            <h4>Goals & Contacts</h4>
            <S.ProviderInfoContainer>
                <Inputs.InputContainer>
                    <ActionPlanLabel>Provider name</ActionPlanLabel>
                    <Inputs.Input
                      defaultValue={actionPlan.providerName}
                      {...register('providerName')}
                      id="providerName"
                      placeholder="Provider name"
                      disabled={!actionPlan.isActive}
                    />
                    {errors.providerName
                    && <Inputs.ErrorMessage>{errors.providerName.message}</Inputs.ErrorMessage>}
                </Inputs.InputContainer>
                <Inputs.InputContainer>
                    <ActionPlanLabel>Provider phone</ActionPlanLabel>
                    <Inputs.Input
                      defaultValue={actionPlan.phoneNumber}
                      {...register('phoneNumber')}
                      id="phoneNumber"
                      placeholder="123456789"
                      disabled={!actionPlan.isActive}
                    />
                    {errors.phoneNumber
                    && <Inputs.ErrorMessage>{errors.phoneNumber.message}</Inputs.ErrorMessage>}
                </Inputs.InputContainer>
                <Inputs.InputContainer>
                    <ActionPlanLabel>Emergency contact</ActionPlanLabel>
                    <Inputs.Input
                      defaultValue={actionPlan.emergencyContactName}
                      {...register('emergencyContactName')}
                      id="emergencyContactName"
                      placeholder="Emergency contact name"
                      disabled={!actionPlan.isActive}
                    />
                    {errors.emergencyContactName
                    && <Inputs.ErrorMessage>{errors.emergencyContactName.message}</Inputs.ErrorMessage>}
                </Inputs.InputContainer>
                <Inputs.InputContainer>
                    <ActionPlanLabel>Emergency contact phone</ActionPlanLabel>
                    <Inputs.Input
                      defaultValue={actionPlan.emergencyContactPhone}
                      {...register('emergencyContactPhone')}
                      id="emergencyContactPhone"
                      placeholder="123456789"
                      disabled={!actionPlan.isActive}
                    />
                    {errors.emergencyContactPhone
                    && <Inputs.ErrorMessage>{errors.emergencyContactPhone.message}</Inputs.ErrorMessage>}
                </Inputs.InputContainer>
            </S.ProviderInfoContainer>
        </>
    );
};

export default ProviderInfo;
