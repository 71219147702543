import React from 'react';
import { Route, Routes } from 'react-router';
import ManageModule from '../../pages/ManageModule';
import ManageEducationPlan from '../../pages/ManageEducationPlan';

const ModalRouter = () => (
    <main className="main-content-modal">
        <Routes>
            <Route path="modules/manage" element={<ManageModule />} />
            <Route path="modules/manage/:moduleId" element={<ManageModule />} />
        </Routes>
    </main>
);
export default ModalRouter;
