import { theme, Inputs } from '@apps/common-ui';
import styled from 'styled-components';
import { ActionPlanZoneType } from '../../../../types/models';

interface CardProps {
    marginLeft?: string;
}

export const ZoneHeader = styled.h4<{ zone: ActionPlanZoneType }>`
    width: 100%;
    padding: 10px;
    color: ${props => props.theme.text.black};
    text-transform: capitalize;
    border-radius: 3px;
    background-color: ${props => {
        switch (props.zone) {
            case ActionPlanZoneType.GREEN:
                return theme.actionPlanZone.green;
            case ActionPlanZoneType.YELLOW:
                return theme.actionPlanZone.yellow;
            default:
                return theme.actionPlanZone.red;
        }
    }}
`;

export const ZoneSectionHeader = styled.h4`
    margin-bottom: 0;
`;

export const ZoneSectionContainer = styled.div`
    margin-bottom: 30px;
    padding-left: 10px;
    padding-top: 5px;
    border-left: 1px solid ${props => props.theme.shades.lighterGrey};
`;

export const ActionPlanLink = styled.a<CardProps>`
    color: ${props => props.color};
    margin-left: ${props => `${props.marginLeft || 0}px`};
    font-weight: 600;
    cursor: pointer;
`;

export const ActionPlanLabel = styled(Inputs.Label)`
    color: ${props => props.theme.text.black};
`;

export const ActionMedicationInput = styled(Inputs.Input)`
    max-width: 900px;
`;
