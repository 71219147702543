import styled from 'styled-components';

export const AccessFieldRow = styled.div`
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    gap: 50px;
    > div:first-child {
        > input {
            margin-left: 0;
        }
    }
`;

export const AccessCheckboxContainer = styled.div`
    input {
        width: 20px;
    }

    label {
        margin-top: 0;
        margin-left: 0;
    }
`;
