import { TableStyles } from 'react-data-table-component';

export const tableStyleOverrides: TableStyles = {
    responsiveWrapper: {
        style: {
            overflowY: 'auto',
            cursor: 'pointer'
        }
    }
};
