import { Card, theme } from '@apps/common-ui';
import styled from 'styled-components';
import { DayCountColors } from '.';

interface DayCountProps {
  color: DayCountColors;
}

export const SummaryContainer = styled(Card)<{expanded?: boolean}>`
  display: ${props => props.expanded ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: ${props => props.expanded ? 'flex-start' : 'center'};
  align-items: center;
  width: 100%;
  margin: 10px 0;
  position: relative;
  padding: 10px;
`;

export const SummaryHeader = styled.p`
  width: 100%;
  padding: 0 0 20px 0;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
`;

export const SummaryFooter = styled.p`
  width: 100%;
  padding: 10px 0 0 0;
  margin: 0;
  font-size: 14px;
`;

export const Current98976Summary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const DaysCount = styled.div<DayCountProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 30px;
  background-color: ${props => {
        switch (props.color) {
            case DayCountColors.GREEN:
                return theme.shades.green;
            case DayCountColors.YELLOW:
                return theme.shades.yellow;
            default:
                return theme.shades.red;
        }
    }};
  color: ${props => {
        switch (props.color) {
            case DayCountColors.YELLOW:
                return theme.text.black;
            default:
                return theme.text.white;
        }
    }};
  border-radius: 20px;
  margin: 0 0 5px 0;
`;

export const DaysCountText = styled.p<{bold?: boolean}>`
  font-size: 14px;
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  margin: 0;
  text-align: center;
`;

export const DaysAway = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 10px 0;
`;

export const DaysAwayText = styled.p`
  font-size: 14px;
  margin: 0;
`;
