import { IExercisePlan } from '@apps/common-utilities/src/types/exerciseTypes';

export enum ExerciseActions {
    SET_EXERCISE_PLAN,
    SET_EXERCISE_PLAN_SUMMARY,
    SET_PROGRAM_TIMELINE,
    ADD_ASSIGNED_EXCERCISE,
    CLONE_DAY,
    MOVE_ASSIGNED_EXCERCISE_UP,
    MOVE_ASSIGNED_EXCERCISE_DOWN,
    REMOVE_ASSIGNED_EXCERCISE,
    REMOVE_DAY,
    SET_PROGRAM_INFO,
    SET_EXERCISE_TO_EDIT,
    EDIT_ASSIGNED_EXCERCISE,
    SET_DEFAULT_HEART_RATE_RANGE,
    SET_UNSAVED_CHANGES,
    RESET_UNSAVED_CHANGES,
    SET_DISCHARGE_ID_ON_CREATE
}

export const actions = {
    setExercisePlan: (data: IExercisePlan) => ({
        type: ExerciseActions.SET_EXERCISE_PLAN,
        payload: data
    }),
    setProgramTimeline: (data: any) => ({
        type: ExerciseActions.SET_PROGRAM_TIMELINE,
        payload: data
    }),
    addAssignedExcercise: (data: any) => ({
        type: ExerciseActions.ADD_ASSIGNED_EXCERCISE,
        payload: data
    }),
    moveAssigneedExerciseDown: (data: any) => ({
        type: ExerciseActions.MOVE_ASSIGNED_EXCERCISE_DOWN,
        payload: data
    }),
    moveAssigneedExerciseUp: (data: any) => ({
        type: ExerciseActions.MOVE_ASSIGNED_EXCERCISE_UP,
        payload: data
    }),
    setUnsavedChanges: (hasChanges: boolean) => ({
        type: ExerciseActions.SET_UNSAVED_CHANGES,
        payload: hasChanges,
    }),
    resetUnsavedChanges: () => ({
        type: ExerciseActions.RESET_UNSAVED_CHANGES,
        payload: null
    }),
    setDischargeIdOnCreate: (dischargeIdOnCreate: number) => ({
        type: ExerciseActions.SET_DISCHARGE_ID_ON_CREATE,
        payload: dischargeIdOnCreate
    }),
};

export type ExerciseAction = ReturnType<typeof actions[keyof typeof actions]>;
