import { Inputs, Buttons } from '@apps/common-ui';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { ExerciseTypes, Labels } from '@apps/common-utilities';
import * as S from './index.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { useNavigate } from 'react-router';

const getTableColumns = (actionLabel?: string, actionFunction?: (exercise: ExerciseTypes.IExercise) => void) => [
    {
        name: '',
        selector: (row: any) => row.thumbnailUrl,
        cell: (row: any) => (
            <S.Thumbnail src={row.thumbnailUrl} />
        ),
        width: '80px'
    },
    {
        name: 'Name',
        selector: (row: any) => row.exerciseTitle,
        sortable: true
    },
    {
        name: 'Type',
        selector: (row: any) => Labels.exerciseType(row.exerciseType),
        sortable: true

    },
    {
        name: 'Category',
        selector: (row: any) => Labels.exerciseCategory(row.category),
        sortable: true
    },
    {
        name: 'Equipment',
        selector: (row: any) => row.exerciseVariable.exerciseEquipment.map(e => Labels.equipment(e.equipment)).join(', '),
        sortable: true
    },
    {
        name: 'Sets',
        selector: (row: any) => row.exerciseVariable.sets,
        sortable: true
    },
    {
        name: 'Amount',
        selector: (row: any) => row.exerciseVariable.amount,
        sortable: true
    },
    {
        name: 'Unit',
        selector: (row: any) => row.exerciseVariable.unit,
        sortable: true
    },
    actionFunction !== undefined && {
        name: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row: ExerciseTypes.IExercise) => (
            <Buttons.Button
              buttonType="tertiary"
              type="button"
              onClick={() => actionFunction(row)}
            >
                {actionLabel}
            </Buttons.Button>
        ),
    },
];

type Props = {
    hasAction?: boolean;
    actionLabel?: string;
    actionFunction?: (exercise: ExerciseTypes.IExercise) => void;
}

const ExerciseList = ({
    hasAction,
    actionLabel,
    actionFunction,
}: Props) => {
    const [exerciseSearchVal, setExerciseSearchVal] = useState('');
    const [exerciseCategoryFilter, setExerciseCategoryFilter] = useState('');
    const [exerciseEquipmentFilter, setExerciseEquipmentFilter] = useState('');
    const [exerciseTypeFilter, setExerciseTypeFilter] = useState('');
    const { exercises } = useSelector((state: RootState) => state.physicalTherapy);
    const [filteredExercises, setFilteredExercises] = useState(exercises);
    const tableColumns = useMemo<any[]>(() => (
        getTableColumns(actionLabel, actionFunction)
    ), [hasAction, actionLabel, actionFunction]) || [];
    const navigate = useNavigate();

    const clearFilters = () => {
        setExerciseSearchVal('');
        setExerciseCategoryFilter('');
        setExerciseEquipmentFilter('');
        setExerciseTypeFilter('');
    };

    useEffect(() => {
        if (exerciseSearchVal === ''
          && exerciseCategoryFilter === ''
          && exerciseEquipmentFilter === ''
          && exerciseTypeFilter === '') {
            setFilteredExercises(exercises);
        } else {
            let filtered = exercises;
            if (exerciseSearchVal !== '') {
                filtered = exercises.filter((exercise) => exercise.exerciseTitle.toLowerCase()
                    .includes(exerciseSearchVal.toLowerCase()));
            }
            if (exerciseCategoryFilter !== '') {
                filtered = exercises.filter((exercise) => exercise.category === exerciseCategoryFilter);
            }
            if (exerciseEquipmentFilter !== '') {
                filtered = exercises.filter((exercise) => exercise.exerciseVariable.exerciseEquipment
                    .map((equipment) => equipment.equipment)
                    .includes(exerciseEquipmentFilter as ExerciseTypes.ExerciseEquipment));
            }
            if (exerciseTypeFilter !== '') {
                filtered = exercises.filter((exercise) => exercise.exerciseType === exerciseTypeFilter);
            }
            setFilteredExercises(filtered);
        }
    }, [exerciseSearchVal, exerciseCategoryFilter, exerciseEquipmentFilter, exerciseTypeFilter, exercises]);

    return (
        <S.ExerciseListContainer>
            <S.ExerciseListHeaderGroup>
                <S.ExerciseListHeader>
                    <h2>Exercises</h2>
                    {!actionFunction && <Buttons.LinkButton to="/exercises/new">+ Add New Exercise</Buttons.LinkButton>}
                </S.ExerciseListHeader>
                <S.ExerciseListHeader>
                    <form onSubmit={(e) => e.preventDefault()} style={{ width: '100%' }}>
                        <S.ExerciseListFiltersContainer>
                            <div>
                                <Inputs.Input
                                  onChange={(e: any) => setExerciseSearchVal(e.target.value)}
                                  value={exerciseSearchVal}
                                  placeholder="Search"
                                  inputSize={Inputs.InputSize.large}
                                />
                            </div>
                            <Inputs.Select
                              value={exerciseTypeFilter}
                              size="medium"
                              onChange={(e: any) => setExerciseTypeFilter(e.target.value)}
                            >
                                <option value="">All Types</option>
                                {Object.values(ExerciseTypes.ExerciseType).map((type) => (
                                    <option key={type} value={type}>
                                        {Labels.exerciseType(type)}
                                    </option>
                                ))}
                            </Inputs.Select>
                            <Inputs.Select
                              value={exerciseCategoryFilter}
                              size="medium"
                              onChange={(e: any) => setExerciseCategoryFilter(e.target.value)}
                            >
                                <option value="">All Categories</option>
                                {Object.values(ExerciseTypes.ExerciseCategory).map((category) => (
                                    <option key={category} value={category}>
                                        {Labels.exerciseCategory(category)}
                                    </option>
                                ))}
                            </Inputs.Select>
                            <Inputs.Select
                              value={exerciseEquipmentFilter}
                              size="medium"
                              onChange={(e: any) => setExerciseEquipmentFilter(e.target.value)}
                            >
                                <option value="">All Equipment</option>
                                {Object.values(ExerciseTypes.ExerciseEquipment).map((equipment) => (
                                    <option key={equipment} value={equipment}>
                                        {Labels.equipment(equipment)}
                                    </option>
                                ))}
                            </Inputs.Select>
                            <Buttons.Button buttonType="secondary" size="small" onClick={() => clearFilters()}>
                                Clear
                                <FontAwesomeIcon icon={faTimes as IconProp} />
                            </Buttons.Button>
                        </S.ExerciseListFiltersContainer>
                    </form>
                </S.ExerciseListHeader>
            </S.ExerciseListHeaderGroup>
            <DataTable
              columns={hasAction ? tableColumns : tableColumns.slice(0, -1)}
              data={filteredExercises}
              highlightOnHover
              paginationServer
              pagination
              striped
              paginationDefaultPage={1}
              onRowClicked={(row: any) => {
                if (actionFunction) {
                    actionFunction(row);
                } else {
                    navigate(`/exercises/${row.exerciseId}`);
                }
              }}
              paginationPerPage={60}
            />
        </S.ExerciseListContainer>
    );
};

export default ExerciseList;
