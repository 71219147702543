import styled from 'styled-components';

export const Calendar = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 2px solid ${props => props.theme.shades.lighterGrey};

`;

export const CalendarColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${props => props.theme.shades.lighterGrey};
  width: 100%;
`;

export const CalendarBody = styled.div`
  background-color: ${props => props.theme.shades.offWhite};
  justify-content: flex-start;
  display: flex;
  height: 100%;

  @media (max-width: ${props => props.theme.screen.medium}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const CalendarHeaderItem = styled.div`
  border-bottom: 1px solid ${props => props.theme.shades.lighterGrey};
  width: 100%;
  text-align: center;
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;
