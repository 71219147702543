import { Buttons, Inputs } from '@apps/common-ui';
import styled from 'styled-components';

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TitleBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Title = styled.h3`
    margin: 0;
`;

export const CloseButton = styled(Buttons.Button)`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: ${props => props.theme.primary.medium};

    svg {
        color: ${props => props.theme.primary.medium};
        height: 12px;
        width: 12px;
        margin: 1px 0 0 10px;
    }
`;

export const SearchBarContainer = styled.div`
    display: flex;
    width: 430px;
    margin: 10px 0 10px 0;

    svg {
        position: relative;
        right: 400px;
        top: 23px;
        color: ${props => props.theme.shades.grey};
    }
`;

export const SearchBar = styled(Inputs.Input)`
    padding-left: 30px;
    width: 100%;
    margin-left: 0;
`;

export const ModuleList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    border-top: 1px solid ${props => props.theme.table.borderLight};
    border-bottom: 1px solid ${props => props.theme.table.borderLight};
`;

export const ModuleRow = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.table.borderLight};

    &:last-child {
        border-bottom: none;
    }
`;

export const ModuleImage = styled.img`
    width: 70px;
    height: 70px;
    object-fit: cover;
`;

export const ModuleDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ModuleInternalTitle = styled.p`
    font-weight: bold;
    margin: 0;
`;

export const ModuleExternalTitle = styled.p`
    margin: 0;
`;

export const AddModuleButton = styled(Buttons.Button)<{canAdd: boolean}>`
    margin: 30px 0 20px 0;
    width: 150px;
    height: 50px;
    background: ${props => props.canAdd ? props.theme.buttons.blue : props.theme.buttons.grey};
    color: ${props => props.theme.shades.white};
    border: 2px solid ${props => props.canAdd ? props.theme.buttons.blue : props.theme.buttons.grey};
`;
