import { ExerciseTypes } from '@apps/common-utilities';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

export type RawExerciseFormData = {
    exerciseTitle: string;
    instructions: string;
    videoUrl: string;
    videoIsPortrait: boolean;
    category: ExerciseTypes.ExerciseCategory;
    equipment: {
        [key in ExerciseTypes.ExerciseEquipment]: {
            selected: boolean;
            specification: string;
        };
    };
    exerciseVariable: {
        type: ExerciseTypes.ExerciseType;
        hold: {
            numberOfHolds: string;
            reps: string;
            time: string;
        };
        intervals: {
            allowDistance: boolean;
            allowReps: boolean;
            allowTime: boolean;
            reps: string;
            unit: ExerciseTypes.ExerciseIntervalUnit;
            interval: string;
        };
        setsAndReps: {
            sets: string;
            reps: string;
        };
        setsAndBreathCycles: {
            sets: string;
            breathCycles: string;
            inhaleResistance: string;
            exhaleResistance: string;
        };
    };
    activeTargets: {
        spO2: boolean;
        hrRange: boolean;
        activeHrRange: HeartRateValue;
        breathlessness: boolean;
    };
    restTargets: {
        spO2: boolean;
        hrRange: boolean;
        restHrRange: HeartRateValue;
        breathlessness: boolean;
    };
    submissionOptions: {
        requireSpO2Lowest: boolean;
        requireSpO2Immediate: boolean;
        requireHeartRate: boolean;
        requireOxygen: boolean;
        requireExercise: boolean;
        requireInhaleResistance: boolean;
        requireExhaleResistance: boolean;
        requireRMTDifficulty: boolean;
        requireBreathlessness: boolean;
        requireActivity: boolean;
    };
};

export type FormComponentProps = {
    errors: any;
    register: UseFormRegister<RawExerciseFormData>;
    watch: (name: string) => string;
    setValue: UseFormSetValue<RawExerciseFormData>;
};

export enum HeartRateValue {
    FORTY_TO_SIXTY = '40_to_60',
    FORTY_TO_EIGHTY = '40_to_80',
    SIXTY_TO_EIGHTY = '60_to_80',
    BELOW_FORTY = 'below_40',
    BELOW_SIXTY = 'below_60'
}
export const activeHeartRateValueMap = {
    [HeartRateValue.FORTY_TO_SIXTY]: {
        min: 40,
        max: 60
    },
    [HeartRateValue.FORTY_TO_EIGHTY]: {
        min: 40,
        max: 80
    },
    [HeartRateValue.SIXTY_TO_EIGHTY]: {
        min: 60,
        max: 80
    }
};
export const restHeartRateValueMap = {
    [HeartRateValue.BELOW_FORTY]: 40,
    [HeartRateValue.BELOW_SIXTY]: 60
};
export const defaultExercise: ExerciseTypes.IExercise = {
    exerciseId: 0,
    exerciseTitle: '',
    exerciseType: ExerciseTypes.ExerciseType.SETS_AND_REPS,
    videoUrl: '',
    thumbnailUrl: '',
    instructions: '',
    category: ExerciseTypes.ExerciseCategory.STRENGTH,
    allowTime: false,
    allowDistance: false,
    allowReps: true,
    exerciseVariable: {
        sets: 0,
        amount: 0,
        unit: '',
        holdSeconds: 0,
        additionalInstructions: '',
        minHeartRatePercent: 0,
        maxHeartRatePercent: 0,
        showBreathlessnessTarget: true,
        heartRateRestPercent: 0,
        showSpO2TargetPercent: true,
        showSpO2RestPercent: true,
        showBreathlessnessRest: true,
        exerciseEquipment: [],
        requireBreathlessnessLevelReporting: true,
        requireLowestSpO2PercentReporting: true,
        requireImmediateSpO2PercentReporting: true,
        requireHeartRateReporting: true,
        requireFlowRateReporting: true,
        requireActivityReporting: true,
        requireRMTInhaleResistanceReporting: false,
        requireRMTExhaleResistanceReporting: false,
        requireRMTDifficultyReporting: false,
        rmtInhaleResistance: null,
        rmtExhaleResistance: null
    }
};
