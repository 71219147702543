import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const BodyContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;

  @media (max-width: ${props => props.theme.screen.medium}) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Header = styled.h1`
  margin-top: 20px;
  @media (max-width: ${props => props.theme.screen.medium}) {
    margin: 0;
    margin-left: 20px;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MessageTextBox = styled.textarea`
    width: 60%;
    max-width: 100%;
    min-width: 10%;
    height: 400px;
    padding: 8px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid ${props => props.theme.shades.lighterGrey};
    background-color: ${props => props.theme.shades.lightestGrey};
`;

export const BackButton = styled(Buttons.Button)`
  margin: 0;
  padding: 0;
  align-self: flex-start;

  & svg:last-of-type {
    margin-left: 0;
  }
`;
