import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

interface TableProps {
    minWidth?: string;
}

interface TableDataProps {
    bold?: boolean;
    minWidth?: string;
    darkBackground?: boolean;
    alignLeft?: boolean;
    borderLeft?: boolean;
    borderRight?: boolean;
    borderTop?: boolean;
    borderBottom?: boolean;
    redText?: boolean;
}

interface TableHeaderProps {
    minWidth?: string;
    darkBackground?: boolean;
    borderLeft?: boolean;
    borderRight?: boolean;
    borderTop?: boolean;
}

interface TableRowProps {
    finalRow?: boolean;
    borderLight?: boolean;
    highlightOnHover?: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;
export const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1100px;
`;

export const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const PageTitle = styled.h2`
    margin: 10px 0 30px 0;
`;

export const AddSessionButton = styled(Buttons.Button)`
    width: 250px;
    height 40px;
`;

export const TableContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Table = styled.table<TableProps>`
    border-collapse: collapse;
    width: 100%;
    min-width: ${props => props.minWidth ? props.minWidth : '800px'};
`;

export const TableRow = styled.tr<TableRowProps>`
    border-bottom: ${props => props.borderLight ? '1px' : '2px'} solid ${props => props.borderLight ? props.theme.table.borderLight : props.theme.table.borderMedium};

    &:hover {
        opacity: ${props => props.highlightOnHover ? '0.8' : '1.0'};
        background-color: ${props => props.highlightOnHover ? props.theme.shades.lightestGrey : 'none'};
        cursor: ${props => props.highlightOnHover ? 'pointer' : 'default'};
    }
`;

export const TableHeader = styled.th<TableHeaderProps>`
    padding: 10px;
    text-align: left;
    min-width: ${props => props.minWidth ? props.minWidth : '100px'};
    white-space: nowrap;
    background: ${props => props.darkBackground ? props.theme.table.backgroundDark : 'transparent'};
    border-left: ${props => props.borderLeft ? `2px solid ${props.theme.table.borderDark}` : 'none'};
    border-right: ${props => props.borderRight ? `2px solid ${props.theme.table.borderDark}` : 'none'};
    border-top: ${props => props.borderTop ? `2px solid ${props.theme.table.borderDark}` : 'none'};

`;

export const TableData = styled.td<TableDataProps>`
    padding: 5px 0 5px 0;
    font-weight: ${props => props.bold || props.redText ? 'bold' : 'normal'};
    color: ${props => props.redText ? props.theme.buttons.red : props.theme.text.default};
    min-width: ${props => props.minWidth ? props.minWidth : '100px'};
    text-align: ${props => props.alignLeft ? 'left' : 'center'};
    background: ${props => props.darkBackground ? props.theme.table.backgroundDark : 'transparent'};
    border-left: ${props => props.borderLeft ? `2px solid ${props.theme.table.borderDark}` : 'none'};
    border-right: ${props => props.borderRight ? `2px solid ${props.theme.table.borderDark}` : 'none'};
    border-bottom: ${props => props.borderBottom ? `2px solid ${props.theme.table.borderDark}` : 'none'};
`;

export const EditSessionButton = styled(Buttons.Button)`
    display: flex;
    background: transparent;
    border: none;
    align-items: center;
    color: ${props => props.theme.buttons.blue};
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
`;

export const EditIcon = styled.img`
    object-fit: contain;
    width: 24px;
    height: 24px;
`;

export const DiscardSessionButton = styled(Buttons.Button)`
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: ${props => props.theme.buttons.red};
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
`;

export const DiscardIcon = styled.img`
    object-fit: contain;
    width: 24px;
    height: 24px;
`;
