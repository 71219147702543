import { useState } from 'react';
import toast from 'react-hot-toast';
import { store } from '../state/store';

interface Options {
    hideToast?: boolean;
    successMessage?: string;
    errorMessage?: string;
}

export const useActionLoader = (action: any, options: Options = {}) => {
    const { dispatch } = store;
    const [error, setError] = useState<string>('');
    const [done, setDone] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const callAction = (input?: any) => {
        setLoading(true);
        dispatch(action(input)).then(() => {
            setError('');
            setDone(true);
            setLoading(false);
            if (!options.hideToast && options.successMessage) {
                toast.success(options.successMessage);
            }
        }).catch((err: any) => {
            const errorMsg = options.errorMessage || err.message;
            setDone(true);
            setError(errorMsg);
            setLoading(false);
            if (!options.hideToast) {
                toast.error(errorMsg);
            }
        });
    };

    return { callAction, done, error, loading };
};
