import { Inputs } from '@apps/common-ui';
import { ExerciseTypes, Labels } from '@apps/common-utilities';
import React, { memo, useEffect } from 'react';
import * as S from '../../index.styles';
import { FormComponentProps } from '../../types';

type IntervalTypes = {
    allowTime: string;
    allowDistance: string;
    allowReps: string;
}
// memoize to reduce unnecessary re-renders
// Disabled eslint due to this error https://github.com/jsx-eslint/eslint-plugin-react/issues/2760
// eslint-disable-next-line react/prop-types
const IntervalOptions = memo(({ allowTime, allowDistance, allowReps }: IntervalTypes) => {
    const options: any = [];
    if (allowTime) {
        options.push(
            <>
                <option key="seconds" value={ExerciseTypes.ExerciseIntervalUnit.SECONDS}>Seconds</option>
                <option key="minutes" value={ExerciseTypes.ExerciseIntervalUnit.MINUTES}>Minutes</option>
            </>
        );
    }
    if (allowDistance) {
        options.push(
            <>
                <option key="feet" value={ExerciseTypes.ExerciseIntervalUnit.FEET}>Feet</option>
                <option key="miles" value={ExerciseTypes.ExerciseIntervalUnit.MILES}>Miles</option>
                <option key="meters" value={ExerciseTypes.ExerciseIntervalUnit.METERS}>Meters</option>
                <option key="kilometers" value={ExerciseTypes.ExerciseIntervalUnit.KILOMETERS}>Kilometers</option>
            </>
        );
    }
    if (allowReps) {
        options.push(<option key="reps" value={ExerciseTypes.ExerciseIntervalUnit.REPS}>Reps</option>);
        options.push(<option key="steps" value={ExerciseTypes.ExerciseIntervalUnit.STEPS}>Steps</option>);
    }
    options.push(<option key="na" value={ExerciseTypes.ExerciseIntervalUnit.NA}>N/A</option>);
    return options;
});

const { ExerciseType } = ExerciseTypes;

const ExerciseTypeRadio = ({ type, register, disabled }:
{ type: keyof typeof ExerciseType; register: any, disabled?: boolean }) => (
    <div>
        <input
          {...register('exerciseVariable.type', { required: true })}
          readOnly={disabled}
          type="radio"
          id={type}
          value={type}
        />
        <Inputs.Label htmlFor={type}>{Labels.exerciseType(type)}</Inputs.Label>
    </div>
);

type Props = FormComponentProps & {
    editing?: boolean;
}

const ExerciseVariable = ({ errors, register, watch, setValue, editing }: Props) => {
    useEffect(() => {
        const category = watch('category');
        const exerciseType = watch('exerciseVariable.type');

        if (category === ExerciseTypes.ExerciseCategory.RMT) {
            setValue('exerciseVariable.type', ExerciseType.SETS_AND_BREATHS);
        } else if (exerciseType === ExerciseType.SETS_AND_BREATHS) {
            setValue('exerciseVariable.type', ExerciseType.SETS_AND_REPS);
        }
    }, [watch('category'), setValue]);

    const validateIntervalType = () => {
        return watch('exerciseVariable.intervals.allowTime')
        || watch('exerciseVariable.intervals.allowDistance')
        || watch('exerciseVariable.intervals.allowReps');
    };

    return (
        <>
            <S.FormSectionTitle>📝 Format</S.FormSectionTitle>
            <S.FormRow>
                {Object.values(ExerciseType).map((type) => {
                    return (
                        <ExerciseTypeRadio
                        // only allowed sets and breath cycles for RMT_BREATHER
                          disabled={editing || (watch('category') === ExerciseTypes.ExerciseCategory.RMT
                          && type !== ExerciseType.SETS_AND_BREATHS)
                          || (watch('category') !== ExerciseTypes.ExerciseCategory.RMT
                          && type === ExerciseType.SETS_AND_BREATHS)}
                          key={type}
                          type={type}
                          register={register}
                        />
                    );
                })}
            </S.FormRow>
            <S.FormRow>
                {watch('exerciseVariable.type') === ExerciseType.HOLD && (
                    <S.SubsectionInputs>
                        <Inputs.InputContainer>
                            <Inputs.Label># of holds</Inputs.Label>
                            <Inputs.Input
                              {...register('exerciseVariable.hold.numberOfHolds', { required: true })}
                              type="number"
                              min="1"
                              inputSize={Inputs.InputSize.small}
                            />
                        </Inputs.InputContainer>
                        <Inputs.InputContainer>
                            <Inputs.Label>Reps</Inputs.Label>
                            <Inputs.Input
                              {...register('exerciseVariable.hold.reps', { required: true })}
                              type="number"
                              min="1"
                              inputSize={Inputs.InputSize.small}
                            />
                        </Inputs.InputContainer>
                        <Inputs.InputContainer>
                            <Inputs.Label>Hold time (seconds)</Inputs.Label>
                            <Inputs.Input
                              {...register('exerciseVariable.hold.time', { required: true })}
                              type="number"
                              min="1"
                              inputSize={Inputs.InputSize.small}
                            />
                        </Inputs.InputContainer>
                    </S.SubsectionInputs>
                )}
                {watch('exerciseVariable.type') === ExerciseType.SETS_AND_REPS && (
                    <S.SubsectionInputs>
                        <Inputs.InputContainer>
                            <Inputs.Label>Sets</Inputs.Label>
                            <Inputs.Input
                              {...register('exerciseVariable.setsAndReps.sets', { required: true })}
                              type="number"
                              min="1"
                              inputSize={Inputs.InputSize.small}
                            />
                        </Inputs.InputContainer>
                        <Inputs.InputContainer>
                            <Inputs.Label>Reps</Inputs.Label>
                            <Inputs.Input
                              {...register('exerciseVariable.setsAndReps.reps', { required: true })}
                              type="number"
                              min="1"
                              inputSize={Inputs.InputSize.small}
                            />
                        </Inputs.InputContainer>
                    </S.SubsectionInputs>
                )}
                {watch('exerciseVariable.type') === ExerciseType.INTERVALS && (
                    <div>
                        <S.SubHeader>Interval type</S.SubHeader>
                        <S.SubsectionInputs>
                            <div>
                                <input
                                  {...register('exerciseVariable.intervals.allowTime', { validate: validateIntervalType })}
                                  id="time"
                                  type="checkbox"
                                />
                                <Inputs.Label htmlFor="time">Time</Inputs.Label>
                            </div>
                            <div>
                                <input
                                  {...register('exerciseVariable.intervals.allowDistance', { validate: validateIntervalType })}
                                  id="distance"
                                  type="checkbox"
                                />
                                <Inputs.Label htmlFor="distance">Distance</Inputs.Label>
                            </div>
                            <div>
                                <input
                                  {...register('exerciseVariable.intervals.allowReps', { validate: validateIntervalType })}
                                  id="amount"
                                  type="checkbox"
                                />
                                <Inputs.Label htmlFor="amount">Amount</Inputs.Label>
                            </div>
                        </S.SubsectionInputs>
                        <S.SubsectionInputs>
                            <Inputs.InputContainer>
                                <Inputs.Label>Interval</Inputs.Label>
                                <Inputs.Input
                                  {...register('exerciseVariable.intervals.interval', { required: true })}
                                  type="number"
                                  min="1"
                                  inputSize={Inputs.InputSize.small}
                                />
                            </Inputs.InputContainer>
                            <Inputs.InputContainer>
                                <Inputs.Label>Reps</Inputs.Label>
                                <Inputs.Input
                                  {...register('exerciseVariable.intervals.reps', { required: true })}
                                  type="number"
                                  min="1"
                                  inputSize="small"
                                />
                            </Inputs.InputContainer>
                            <Inputs.InputContainer>
                                <Inputs.Label>Unit</Inputs.Label>
                                <Inputs.Select
                                  {...register('exerciseVariable.intervals.unit', { required: true })}
                                >
                                    <IntervalOptions {...{
                                        allowTime: watch('exerciseVariable.intervals.allowTime'),
                                        allowDistance: watch('exerciseVariable.intervals.allowDistance'),
                                        allowReps: watch('exerciseVariable.intervals.allowReps'),
                                    }}
                                    />
                                </Inputs.Select>
                            </Inputs.InputContainer>
                        </S.SubsectionInputs>
                    </div>
                )}
                {watch('exerciseVariable.type') === ExerciseType.SETS_AND_BREATHS && (
                    <S.SubsectionInputs>
                        <Inputs.InputContainer>
                            <Inputs.Label>Sets</Inputs.Label>
                            <Inputs.Input
                              {...register('exerciseVariable.setsAndBreathCycles.sets', { required: true })}
                              type="number"
                              min="1"
                              inputSize={Inputs.InputSize.small}
                            />
                        </Inputs.InputContainer>
                        <Inputs.InputContainer>
                            <Inputs.Label>Breath cycles</Inputs.Label>
                            <Inputs.Input
                              {...register('exerciseVariable.setsAndBreathCycles.breathCycles', { required: true })}
                              type="number"
                              min="1"
                              inputSize={Inputs.InputSize.small}
                            />
                        </Inputs.InputContainer>
                        <Inputs.InputContainer>
                            <Inputs.Label>Inhale Resistance</Inputs.Label>
                            <Inputs.Input
                              {...register('exerciseVariable.setsAndBreathCycles.inhaleResistance', { required: true })}
                              type="number"
                              min="1"
                              max="6"
                              inputSize={Inputs.InputSize.small}
                            />
                            <Inputs.Label>(max 6)</Inputs.Label>
                        </Inputs.InputContainer>
                        <Inputs.InputContainer>
                            <Inputs.Label>Exhale Resistance</Inputs.Label>
                            <Inputs.Input
                              {...register('exerciseVariable.setsAndBreathCycles.exhaleResistance', { required: true })}
                              type="number"
                              min="1"
                              max="5"
                              inputSize={Inputs.InputSize.small}
                            />
                            <Inputs.Label>(max 5)</Inputs.Label>
                        </Inputs.InputContainer>
                    </S.SubsectionInputs>
                )}
            </S.FormRow>
        </>
    );
};

export default ExerciseVariable;
