import { IActionPlanZone, IGenericActionPlan } from '../../types/models';

export enum ReducerActionType {
    UPDATE_PLAN = 'UPDATE_PLAN',
    UPDATE_ZONE = 'UPDATE_ZONE',
    UPDATE_COPD_INFO = 'UPDATE_COPD_INFO',
}

type ReducerData = {
    type: ReducerActionType;
    payload: any
}

/**
 * The reducer that allows various subcomponents to update the active action plan,
 * rather than having to pass the plan up and down the component tree.
 */
export const reducer = (state: IGenericActionPlan, action: ReducerData): IGenericActionPlan => {
    switch (action.type) {
        case ReducerActionType.UPDATE_PLAN:
            return {
                ...state,
                ...action.payload
            };
        case ReducerActionType.UPDATE_ZONE:
            return {
                ...state,
                actionPlanZones: state.actionPlanZones.map((zone: IActionPlanZone) => {
                    if (zone.zone === action.payload.zone) {
                        return {
                            ...zone,
                            ...action.payload
                        };
                    }
                    return zone;
                })
            };
        case ReducerActionType.UPDATE_COPD_INFO:
            return {
                ...state,
                copdInfo: {
                    ...state.copdInfo,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};
