import React, { useState } from 'react';
import * as S from '../../index.styles';
import { Buttons } from '@apps/common-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ExerciseTypes } from '@apps/common-utilities';

interface Props {
    onThisWeekClicked: () => void;
    onPreviousWeekClicked: () => void;
    onNextWeekClicked: () => void;
    weekText: string;
    exerciseSummary: ExerciseTypes.IExercisePlanSummary | null;
}

const WeekHeader = ({
    onThisWeekClicked,
    onPreviousWeekClicked,
    onNextWeekClicked,
    weekText,
    exerciseSummary,
}: Props) => {
    return (
        <>
            <Buttons.Button
              buttonType="tertiary"
              onClick={onThisWeekClicked}
            >
                This Week
            </Buttons.Button>
            <S.ChangeDateContainer>
                <S.DateButton
                  onClick={onPreviousWeekClicked}
                >
                    <FontAwesomeIcon
                      icon={faChevronLeft as IconProp}
                    />
                </S.DateButton>
                <p style={{ userSelect: 'none' }}>
                    {weekText}
                </p>
                <S.DateButton
                  onClick={onNextWeekClicked}
                >
                    <FontAwesomeIcon
                      icon={faChevronRight as IconProp}
                    />
                </S.DateButton>
            </S.ChangeDateContainer>
        </>
    );
};

export default WeekHeader;
