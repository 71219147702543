import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: visible;
`;

export const Section = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 20px;
  width: 100%;
  select, fieldset {
    margin-left: 0;
    padding-left: 0;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    margin-right: 20px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const HRText = styled.h4`
  margin: 0;
  padding: 0;
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
`;

export const ErrorContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const ErrorMessage = styled.p`
    font-weight: bold;
    color: ${props => props.theme.text.error};
`;

export const InputError = styled.p`
    color: ${props => props.theme.text.error};
    padding-left: 18px;
    margin: 0;
`;
