import { LearningTypes } from '@apps/common-utilities';
import { IManagedQuestion } from '.';
import { simpleId } from '../../utils/utils';

export const collapseQuestion = (questionId: string, questions: IManagedQuestion[]): IManagedQuestion[] => {
    const updatedQuestions = questions.map((question, index) => {
        if (question.tmpId === questionId) {
            return {
                ...question,
                collapsed: !question.collapsed
            };
        }
        return question;
    });
    return updatedQuestions;
};

export const addAnswerToQuestion = (questionId: string, questions: IManagedQuestion[]): IManagedQuestion[] => {
    const updatedQuestions = questions.map((question, index) => {
        if (question.tmpId === questionId) {
            return {
                ...question,
                answers: [
                    ...question.answers,
                    {
                        tmpId: simpleId(),
                        answer: '',
                        correct: false
                    }
                ]
            };
        }
        return question;
    });
    return updatedQuestions;
};

export const removeAnswerFromQuestion = (questionId: string, answerId: string, questions: IManagedQuestion[]): IManagedQuestion[] => {
    const updatedQuestions = questions.map((question, qIndex) => {
        if (question.tmpId === questionId.toString()) {
            return {
                ...question,
                answers: question.answers.filter((answer, index) => answer.tmpId !== answerId.toString())
            };
        }
        return question;
    });
    return updatedQuestions;
};

export const isMultiSelect = (question: IManagedQuestion | undefined): boolean => {
    if (!question) {
        return false;
    }
    const correctAnswers = question.answers.filter(answer => answer.correct);
    return correctAnswers.length > 1;
};

export const formatQuizDataForCreate = (quiz: LearningTypes.IQuiz): LearningTypes.IQuiz => {
    const questions = Object.values(quiz.questions).map((question: LearningTypes.IQuestion) => {
        const answers = Object.values(question.answers).map(answer => ({
            answer: answer.answer,
            correct: answer.correct
        }));
        return {
            question: question.question,
            answers
        };
    });
    return {
        ...quiz,
        questions
    };
};
