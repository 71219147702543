import { Auth } from 'aws-amplify';
import { login, logout } from '.';
import { Routes } from '../../../api/Routes';
import { IUser } from '../../../types/models';
import { AppDispatch } from '../../store';
import { TenantApi } from '../../../api/CoachingAPI';

export const loginUser = (data: { user: IUser }) => async (dispatch: AppDispatch) => {
    const authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    let userId = authUser.getUsername();
    if (!userId && data.user?.id) {
        userId = data.user.id;
    }
    // Try to fetch user from core db, if the user doesn't exist create one
    const user = await TenantApi.get(`/${Routes.users}/${userId}`);
    if (user !== undefined) {
        dispatch(login({ user: { ...user, email: authUser.attributes.email } }));
    }
};

export const logoutUser = () => async (dispatch: AppDispatch) => {
    Auth.signOut();
    dispatch(logout());
};
