import React, { useEffect, useState } from 'react';
import { Modal } from '@apps/common-ui';
import * as S from './index.styles';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LearningTypes } from '@apps/common-utilities';
import { ApiResponse, RequestMethod, useApiRequest } from '../../../../hooks/useApiRequest';
import { IPauseCourse } from '@apps/common-utilities/src/types/learningTypes';
import toast from 'react-hot-toast';
import { pauseReason } from '@apps/common-utilities/src/types/labels';

type props = {
    showModal: boolean;
    dismissModal: () => void;
    courseId: number;
    userId: string;
    isPaused: boolean;
    refreshCourse?: () => Promise<void>;
};

const PauseCourseModal = ({ showModal, dismissModal, courseId, userId, isPaused, refreshCourse }: props) => {
    const [selectedReason, setSelectedReason] = useState<LearningTypes.CourseUserPauseReason | null>(null);

    const { callApi: updateCoursePauseStatus } = useApiRequest<LearningTypes.IPauseCourse>(RequestMethod.PATCH);

    const updatePauseStatus = async () => {
        const body: IPauseCourse = {
            pauseReason: selectedReason,
            isPaused: !isPaused,
        };
        updateCoursePauseStatus(`/coaches/users/${userId}/education/course-assignments/${courseId}/pause`, body).then(({ response, error }: ApiResponse<LearningTypes.IPauseCourse>) => {
            if (!!error.error || !!error.message) {
                toast.error(`Failed to update plan status. ${error.error || error.message}`, { duration: 5000 });
            } else if (response.data) {
                toast.success(`Plan ${isPaused ? 'resumed' : 'paused'} successfully`, { duration: 5000 });
                if (refreshCourse) {
                    refreshCourse();
                }
            }
            dismissModal();
        });
    };

    return (
        <Modal
          showModal={showModal}
          dismissModal={dismissModal}
          showTopBar={false}
        >
            <S.ModalContent>
                <S.TitleBar>
                    <S.Title>{isPaused ? 'Resume' : 'Pause'} Plan</S.Title>
                    <S.CloseButton
                      onClick={() => dismissModal()}
                    >
                        <FontAwesomeIcon icon={faX as IconProp} />
                    </S.CloseButton>
                </S.TitleBar>
                {!isPaused && (
                <S.InputContainer>
                    <S.InputLabel>Reason for pausing</S.InputLabel>
                    <S.Input
                      onChange={(e) => {
                            setSelectedReason(e.target.value as LearningTypes.CourseUserPauseReason);
                        }}
                    >
                        <option value="">Select Reason</option>
                        {Object.keys(LearningTypes.CourseUserPauseReason).map((reason) => (
                            <option key={reason} value={reason}>{pauseReason(reason as LearningTypes.CourseUserPauseReason)}</option>
                        ))}
                    </S.Input>
                </S.InputContainer>
                )}
                <S.ButtonContainer>
                    <S.PauseButton
                      canClick={(!isPaused && selectedReason !== null) || isPaused}
                      isPaused={isPaused}
                      onClick={updatePauseStatus}
                    >
                        {isPaused ? 'Resume Plan' : 'Pause Plan'}
                    </S.PauseButton>
                </S.ButtonContainer>
            </S.ModalContent>
        </Modal>
    );
};
export default PauseCourseModal;
