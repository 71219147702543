import { IGenericActionPlan } from '../../../types/models';

/**
 * Every time an action plan is saved a new version is created (totally new db record).
 * This function removes all ids from the old version so that a new version is created
 */
export const removeKeys = (obj: any, targetKey: string): any => {
    if (Array.isArray(obj)) return obj.map(item => removeKeys(item, targetKey));

    if (typeof obj === 'object' && obj !== null) {
        return Object.keys(obj).reduce((previousValue: any, key: string) => (
            targetKey === key ? previousValue : { ...previousValue, [key]: removeKeys(obj[key], targetKey) }
        ), {});
    }
    return obj;
};

export const setFirstPlanActive = (actionPlans: IGenericActionPlan[]): IGenericActionPlan[] => {
    if (actionPlans.length === 0) return [];
    return actionPlans.map((plan, index) => {
        if (index === 0) {
            return {
                ...plan,
                isActive: true
            };
        }
        return {
            ...plan,
            isActive: false
        };
    });
};
