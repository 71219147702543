import React, { useContext, useState } from 'react';
import * as S from '../../index.styles';
import { Buttons } from '@apps/common-ui';
import { ExerciseTypes } from '@apps/common-utilities';
import { exercisePlanTypeLabel } from '../../../../../../components/ExerciseForm/utils';
import { ExercisePlanType } from '@apps/common-utilities/src/types/exerciseTypes';
import { monthDateYear } from '../../../../../../utils/dateUtils';

type Props = {
    exercisePlan: ExerciseTypes.IExercisePlan;
    onNewPlan: Function;
    onPause: Function;
    onDischarge: Function;
}

const ManageProgramOverview = ({ exercisePlan, onNewPlan, onPause, onDischarge }: Props) => {
    let pausedStatus: 'info' | 'danger' | 'success' = 'info';
    if (exercisePlan.paused && exercisePlan.published) {
        pausedStatus = 'danger';
    } else if (exercisePlan.published) {
        pausedStatus = 'success';
    }
    const planActive = !exercisePlan.paused && exercisePlan.published;
    return (
        <>
            <S.Row>
                <S.StatusContainer status={exercisePlan.exercisePlanType !== ExercisePlanType.NON_PT_MAINTENANCE ? 'success' : 'danger'}>
                    {exercisePlanTypeLabel(exercisePlan.exercisePlanType)}
                </S.StatusContainer>
            </S.Row>
            {!exercisePlan.complete && (
            <S.Row>
                <p><strong>Plan is currently {planActive ? 'active' : 'inactive'} and {exercisePlan.published ? 'published' : 'unpublished'}.</strong></p>
            </S.Row>
            )}
            <S.Row>
                {exercisePlan.complete && (
                    <>
                        <S.StatusContainer status="danger">Discharged</S.StatusContainer>
                        { exercisePlan.paused && (
                        <S.StatusContainer status="danger">
                            Disabled
                        </S.StatusContainer>
                    )}
                    </>
                )}
                {!exercisePlan.complete && (
                    <>
                        <S.StatusContainer status={pausedStatus}>
                            {!exercisePlan.paused && exercisePlan.published && 'Active'}
                            {exercisePlan.published && exercisePlan.paused && 'Disabled'}
                            {!exercisePlan.published && 'Draft'}
                        </S.StatusContainer>
                        <S.StatusContainer status={exercisePlan.published ? 'success' : 'danger'}>
                            {exercisePlan.published ? 'Published' : 'Unpublished'}
                        </S.StatusContainer>
                    </>
                )}
                {exercisePlan.updatedAt != null && <p>Last updated {monthDateYear(new Date(exercisePlan.updatedAt))}</p>}
            </S.Row>
            <S.Row>
                <Buttons.Button
                  type="button"
                  onClick={onNewPlan}
                >
                    Start New Plan of Care
                </Buttons.Button>
            </S.Row>
            <S.Divider />
            <S.Row>
                { !exercisePlan.complete && (
                    <Buttons.Button
                      type="button"
                      buttonStyle="danger"
                      onClick={onDischarge}
                    >
                        Discharge Patient
                    </Buttons.Button>
                    )}
                <Buttons.Button
                  type="button"
                  buttonStyle={exercisePlan.paused ? undefined : 'danger'}
                  buttonType="secondary"
                  onClick={onPause}
                >
                    {exercisePlan.paused ? 'Resume Program' : 'Disable Program'}
                </Buttons.Button>

            </S.Row>
        </>
    );
};

export default ManageProgramOverview;
