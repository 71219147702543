import styled from 'styled-components';

export const ActionPlanContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 50px;
    box-sizing: border-box;
`;

export const ActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 50px;

    button {
        margin: 0 30px;
        width: 200px;
    }
`;

export const ActionPlanHeader = styled.h2`
    font-weight: normal;
`;

export const ActionPlanDivider = styled.hr`
    border: none;
    border-top: 1px solid ${props => props.theme.shades.black};
    margin: 0;
`;
