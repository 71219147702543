import { Buttons } from '@apps/common-ui';
import { useOnClickOutside } from '@apps/common-utilities';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { WEEKDAYS } from '../../../../types/constants';
import { ExerciseFunctions } from '../../../../types/models';
import { ExerciseActions } from '../../state/actions';
import { ExerciseContext } from '../../state/ExerciseContext';
import CalendarEntry from '../CalendarEntry';
import CalendarEntryTooltip from '../Tooltips/CalendarEntryTooltip';
import CalendarTooltip from '../Tooltips/CalendarTooltip';
import * as S from './index.styles';
import toast from 'react-hot-toast';

export const Calendar = () => {
    const { state, dispatch } = useContext(ExerciseContext);
    const [exercises, setExercises] = useState<any[]>(state.exercisePlan.assignedExercises);
    const [tooltipDay, setTooltipDay] = useState<string>('');
    const [showTooltip, setShowTooltip] = useState(
        Object.keys(WEEKDAYS).reduce((acc, day) => ({ ...acc, [day]: false }), {})
    );
    const tooltipRef = useRef(null);

    useOnClickOutside(
        tooltipRef,
        () => setShowTooltip(Object.keys(WEEKDAYS).reduce((acc, day) => ({ ...acc, [day]: false }), {}))
    );

    // this is how I'm re-rendering the calendar when the exercises change
    // the exercises are stored in the local state, but I'm not sure how to get the calendar to re-render
    // when the context changes
    // I pass down these functions but store them here so I can re-render the calendar
    const exerciseFunctions: ExerciseFunctions = {
        cloneExercise: (exercise, dayOfWeek: string) => {
            const exists = state.exercisePlan.assignedExercises.some((e) => e.exerciseId === exercise.exerciseId && e.dayOfWeek === dayOfWeek);
            if (exists) {
                toast.error(`This exercise already exists on ${WEEKDAYS[dayOfWeek]}`);
                return;
            }
            dispatch({
                type: ExerciseActions.ADD_ASSIGNED_EXCERCISE,
                payload: {
                    ...exercise,
                    dayOfWeek,
                }
            });
            setExercises([...state.exercisePlan.assignedExercises]);
        },
        cloneDay: (dayOfWeekToClone: string, dayOfWeek: string) => {
            const dayToCloneExercises = state.exercisePlan.assignedExercises.filter((e) => e.dayOfWeek === dayOfWeekToClone);
            const dayToCloneToExercises = state.exercisePlan.assignedExercises.filter((e) => e.dayOfWeek === dayOfWeek);
            const exists = dayToCloneExercises.some((e) => dayToCloneToExercises.some((e2) => e.exerciseId === e2.exerciseId));
            if (exists) {
                toast.error(`One or more of these exercises already exist on ${WEEKDAYS[dayOfWeek]} `);
                return;
            }
            dispatch({
                type: ExerciseActions.CLONE_DAY,
                payload: {
                    dayOfWeekToClone,
                    dayOfWeek,
                }
            });
            setExercises([...state.exercisePlan.assignedExercises]);
        },
        removeExercise: (exercise) => {
            dispatch({
                type: ExerciseActions.REMOVE_ASSIGNED_EXCERCISE,
                payload: exercise
            });
            setExercises([...state.exercisePlan.assignedExercises]);
        },
        removeDay: (dayOfWeek: string) => {
            dispatch({
                type: ExerciseActions.REMOVE_DAY,
                payload: {
                    dayOfWeek,
                }
            });
            setExercises([...state.exercisePlan.assignedExercises]);
        },
        moveExerciseUp: (exercise) => {
            dispatch({
                type: ExerciseActions.MOVE_ASSIGNED_EXCERCISE_UP,
                payload: {
                    exercise,
                }
            });
            setExercises([...state.exercisePlan.assignedExercises]);
        },
        moveExerciseDown: (exercise) => {
            dispatch({
                type: ExerciseActions.MOVE_ASSIGNED_EXCERCISE_DOWN,
                payload: {
                    exercise,
                }
            });
            setExercises([...state.exercisePlan.assignedExercises]);
        },
    };

    useEffect(() => {
        // todo: Remove this when I figure out why the page isn't re-rendering when the state changes
        setExercises([...state.exercisePlan.assignedExercises]);
    }, [state.exercisePlan.assignedExercises]);

    return (
        <S.Calendar>
            <S.CalendarBody ref={tooltipRef}>
                {Object.keys(WEEKDAYS).map((dayOfWeek, index) => (
                    <S.CalendarColumn key={index}>
                        {showTooltip[dayOfWeek]
                          && <CalendarTooltip exerciseFunctions={exerciseFunctions} dayOfWeek={tooltipDay} />}
                        <S.CalendarHeaderItem>
                            <h3>{WEEKDAYS[dayOfWeek]}</h3>
                            <FontAwesomeIcon
                              data-testid={`calendar-tooltip-${dayOfWeek}`}
                              icon={faEllipsis as IconProp}
                              onClick={() => {
                                setShowTooltip({ ...showTooltip, [dayOfWeek]: !showTooltip[dayOfWeek] });
                                setTooltipDay(dayOfWeek);
                              }}
                            />

                            {state.exercisePlan.assignedExercises
                              .filter((e: any) => e.dayOfWeek === dayOfWeek).length}
                        </S.CalendarHeaderItem>
                        <Buttons.LinkButton
                          buttonType="tertiary"
                          to={`assign-exercise/${dayOfWeek}`}
                        >
                            + Add Exercise
                        </Buttons.LinkButton>
                        {state.exercisePlan.assignedExercises.sort((a, b) => a.dayOfWeekOrder - b.dayOfWeekOrder)
                            .map((exercise) => {
                                if (exercise.dayOfWeek === dayOfWeek) {
                                    return (
                                        <CalendarEntry
                                          exerciseFunctions={exerciseFunctions}
                                          assignedExercise={exercise}
                                          key={exercise.dayOfWeek + exercise.exerciseId + exercise.dayOfWeekOrder}
                                        />
                                    );
                                }
                                return null;
                            })}
                    </S.CalendarColumn>
                    ))}
            </S.CalendarBody>
        </S.Calendar>
    );
};

export default Calendar;
