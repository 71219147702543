import { createSlice } from '@reduxjs/toolkit';
import { Routes } from '../../../api/Routes';
import { IBillableSessionMonthSummary, ICurrent98976PeriodSummary } from '../../../types/models';
import { AppDispatch } from '../../store';
import { TenantApi } from '../../../api/CoachingAPI';

export interface BillingState {
    totalBillingSummary: IBillableSessionMonthSummary[];
    current98976PeriodSummary: ICurrent98976PeriodSummary;
}

const initialState = {
    totalBillingSummary: [
        {
            calendarMonth: '',
            totalSeconds: 0,
            totalAsyncSeconds: 0,
            totalSyncSeconds: 0,
            totalBillingUnits: 0,
            maxBillingUnits: 0,
            maxSeconds: 0,
            sessions: []
        }
    ],
    current98976PeriodSummary: {
        daysWith98976Data: 0,
        daysLeftInPeriod: 0,
        isDischarged: false,
        evalPending: false,
    }
} as BillingState;

const sessionSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        setBillableSessionTotalSummary: (state, { payload }: {payload: { totalSummary: IBillableSessionMonthSummary[] }}) => {
            state.totalBillingSummary = payload.totalSummary;
        },
        setCurrent98976PeriodSummary: (state, { payload }: {payload: { currentPeriodSummary: ICurrent98976PeriodSummary }}) => {
            state.current98976PeriodSummary = payload.currentPeriodSummary;
        }
    }
});

export const {
    setBillableSessionTotalSummary,
    setCurrent98976PeriodSummary
} = sessionSlice.actions;

export default sessionSlice.reducer;

export const fetchBillableSessionTotalSummary = (userId: string) => async (dispatch: AppDispatch) => {
    const totalSummary = await TenantApi.get(`/${Routes.users}/${userId}/${Routes.billableSessions}/${Routes.monthlySummaries}`);
    dispatch(setBillableSessionTotalSummary({ totalSummary }));
};

export const fetch98976CurrentPeriodSummary = (userId: string) => async (dispatch: AppDispatch) => {
    const currentPeriodSummary = await TenantApi.get(`/${Routes.users}/${userId}/${Routes.billableActivity}/summary`);
    dispatch(setCurrent98976PeriodSummary({ currentPeriodSummary }));
};
