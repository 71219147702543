import React, { useState, useRef, PropsWithChildren, useContext, useEffect } from 'react';
import * as S from './index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import ExerciseImage from '../../../../assets/CalendarEntryTestImage.png';
import CalendarTooltip from '../Tooltips/CalendarEntryTooltip';
import { ExerciseTypes, useOnClickOutside } from '@apps/common-utilities';
import { ExerciseFunctions } from '../../../../types/models';
import { ExerciseContext } from '../../state/ExerciseContext';
import { useNavigate } from 'react-router';
import { ExerciseActions } from '../../state/actions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';

const CalendarEntry = (
    { assignedExercise, exerciseFunctions }: PropsWithChildren<{assignedExercise: ExerciseTypes.IAssignedExercise, exerciseFunctions: ExerciseFunctions}>
) => {
    const { state, dispatch } = useContext(ExerciseContext);
    const { exercises } = useSelector((store: RootState) => store.physicalTherapy);
    const [exercise, setExercise] = useState<ExerciseTypes.IExercise>();
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const navigate = useNavigate();
    const tooltipRef = useRef(null);
    useOnClickOutside(tooltipRef, () => setShowTooltip(false));

    useEffect(() => {
        const exerciseTemplate = exercises.find((e) => e.exerciseId === assignedExercise.exerciseId);
        setExercise(exerciseTemplate);
    }, [exercises]);

    const editExercise = () => {
        if (assignedExercise.exerciseInfo.deletedAt) {
            return;
        }
        dispatch({ type: ExerciseActions.SET_EXERCISE_TO_EDIT, payload: assignedExercise });
        navigate('assigned-exercise/edit');
    };

    return (
        <S.CalendarEntryContainer>
            <S.CalendarEntryHeader>
                {exercise && exercise.thumbnailUrl && <S.Thumbnail src={exercise.thumbnailUrl} alt="Exercise" />}
                <S.CalendarEntryButtonGroup ref={tooltipRef}>
                    <FontAwesomeIcon
                      onClick={() => setShowTooltip(!showTooltip)}
                      icon={faEllipsis as IconProp}
                      data-testid={`tooltip-button-${assignedExercise.exerciseId}-${assignedExercise.dayOfWeek}`}
                    />
                    {showTooltip
                      && <CalendarTooltip exerciseFunctions={exerciseFunctions} exercise={assignedExercise} />}
                    <S.CalendarEntryButton onClick={() => exerciseFunctions.moveExerciseUp(assignedExercise)} disabled={assignedExercise.dayOfWeekOrder === 1}>
                        <FontAwesomeIcon icon={faChevronUp as IconProp} />
                    </S.CalendarEntryButton>
                    <S.CalendarEntryButton onClick={() => exerciseFunctions.moveExerciseDown(assignedExercise)}>
                        <FontAwesomeIcon icon={faChevronDown as IconProp} />
                    </S.CalendarEntryButton>
                </S.CalendarEntryButtonGroup>
            </S.CalendarEntryHeader>
            <div
              onClick={editExercise}
              aria-hidden="true"
            >
                <S.CalendarEntryTitle>{assignedExercise.exerciseInfo.title}</S.CalendarEntryTitle>
                {!assignedExercise.exerciseInfo.deletedAt
                    ? (
                        <S.EditEntryButton
                          buttonType="tertiary"
                        >
                            Edit
                        </S.EditEntryButton>
                    )
                    : <S.DeletedTag>(deleted)</S.DeletedTag>}
            </div>
        </S.CalendarEntryContainer>
    );
};

export default CalendarEntry;
