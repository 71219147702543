import { Modal } from '@apps/common-ui';
import React, { useState } from 'react';
import * as S from './index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const RemoveModuleModal = ({ showModal, dismissModal, moduleId, removeModule }: {showModal: boolean, dismissModal: () => void, moduleId: number, removeModule: () => void}) => {
    const [canDelete, setCanDelete] = useState(false);

    return (
        <Modal
          showModal={showModal}
          dismissModal={dismissModal}
          showTopBar={false}
        >
            <S.ModalContent>
                <S.TitleBar>
                    <S.Title>Remove Module?</S.Title>
                    <S.CloseButton
                      onClick={() => dismissModal()}
                      aria-label="close"
                    >
                        <FontAwesomeIcon icon={faX as IconProp} />
                    </S.CloseButton>
                </S.TitleBar>
                <S.InputContainer>
                    <S.InputLabel>Type &quot;Remove&quot; to confirm</S.InputLabel>
                    <S.Input
                      onChange={(e) => {
                        if (e.target.value === 'Remove') {
                            setCanDelete(true);
                        } else {
                            setCanDelete(false);
                        }
                      }}
                      aria-label="remove"
                    />
                </S.InputContainer>
                <S.ButtonContainer>
                    <S.DeleteButton
                      canDelete={canDelete}
                      onClick={() => {
                            if (canDelete) {
                                removeModule();
                                dismissModal();
                            }
                        }}
                    >
                        Remove
                    </S.DeleteButton>
                    <S.CancelButton
                      onClick={() => dismissModal()}
                    >
                        Keep Module
                    </S.CancelButton>
                </S.ButtonContainer>
            </S.ModalContent>
        </Modal>
    );
};
export default RemoveModuleModal;
