import React from 'react';
import * as S from '../../index.styles';
import { isDayOutsideOfPlan } from '../../reportingUtils';
import { format, isSameDay } from 'date-fns';
import { ExerciseTypes } from '@apps/common-utilities';

interface Props {
    viewingDay: Date;
    handleChangeDay: (day: Date) => void;
    viewingWeek: Date[];
    exerciseSummary: ExerciseTypes.IExercisePlanSummary | null;
    weeklyReportingData: ExerciseTypes.IExerciseReportWeek | null;
}

const WeekSummary = ({
    viewingDay,
    handleChangeDay,
    viewingWeek,
    exerciseSummary,
    weeklyReportingData
}: Props) => {
    return (
        <S.WeeklySummary>
            {exerciseSummary && viewingWeek.map((day, index) => {
            const weekday = format(day, 'EEEE').toLowerCase();
            return (
                <S.DaySummaryContainer
                  active={isSameDay(viewingDay, day)}
                  key={index}
                  onClick={() => handleChangeDay(day)}
                >
                    <S.DaySummaryHeader>
                        <h4>{format(day, 'EEEE do')}</h4>
                        {weeklyReportingData
                    && weeklyReportingData[weekday]?.warnings > 0
                    && !isDayOutsideOfPlan(day, exerciseSummary.startDate, exerciseSummary.endDate) && (
                    <S.WarningIconContainer>
                        {weeklyReportingData[weekday].warnings}
                    </S.WarningIconContainer>
                    )}
                    </S.DaySummaryHeader>
                </S.DaySummaryContainer>
            );
          })}
        </S.WeeklySummary>
    );
};

export default WeekSummary;
