import { NaiveDate } from '../../../utils/dateUtils';

export const getMinutesStringFromSeconds = (seconds: number): string => {
    let secondsString = `${seconds % 60}`;
    if (secondsString.length === 1) {
        secondsString = `0${secondsString}`;
    }
    const minutesString = `${Math.floor(seconds / 60)}`;

    return `${minutesString}:${secondsString}`;
};

export const formatBillableSessionDate = (dateString: string, longMonth: boolean = false): string => {
    return new NaiveDate(dateString).toLocaleString('default', { month: longMonth ? 'long' : 'short', day: 'numeric' });
};

export const getSessionLengthString = (sessionSeconds: number): string => {
    const hours = Math.floor(Math.abs(sessionSeconds / 3600));
    let minutes = `${Math.floor(Math.abs((sessionSeconds % 3600) / 60))}`;
    if (minutes.length === 1) {
        minutes = `0${minutes}`;
    }
    let seconds = `${Math.abs(sessionSeconds % 60)}`;
    if (seconds.length === 1) {
        seconds = `0${seconds}`;
    }
    if (sessionSeconds < 0) {
        return `-${hours}:${minutes}:${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
};
