import { Buttons, Inputs } from '@apps/common-ui';
import styled from 'styled-components';

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ModuleForm = styled.form`
    width: 100%;
    height: 100%;
    display: flex
    flex-direction: column;
`;

export const InputLabel = styled.p`
    margin: 0 0 10px 0;
    font-weight: bold;
`;

export const Input = styled(Inputs.Input)`
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
    max-width: 100%;
`;

export const Dropdown = styled(Inputs.Select)`
    width: 100%;
    margin-left: 0;
    border-radius: 3px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    margin 30px 0 20px 0;
`;

export const DuplicateButton = styled(Buttons.Button)`
    width: 190px;
    height: 50px;
`;

export const DiscardButton = styled(Buttons.Button)`
    background: transparent;
    border: none;
    color: ${props => props.theme.buttons.blue};
`;
