import { Spinner } from '@apps/common-ui';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { ActionPlanContext } from '../..';
import {
    ActionplanInputType,
    ActionPlanZoneType,
    IActionPlanItem,
    IActionPlanZone
} from '../../../../types/models';
import { ReducerActionType } from '../../reducer';
import Actions from '../Actions';
import { ZoneHeader } from '../common';
import CopdSymptoms from '../CopdSymptoms';
import MedicationActions from '../MedicationActions';
import * as S from './index.styles';

type Props = {
    zoneType: ActionPlanZoneType;
    upperExtra?: ReactNode;
    flowRate?: ReactNode;
}

const ActionPlanZone = ({ zoneType, upperExtra, flowRate }: Props) => {
    const { actionPlan, updateActionPlan } = useContext(ActionPlanContext);
    const [zone, setZone] = useState<IActionPlanZone>();
    const [medicationActions, setMedicationActions] = useState<IActionPlanItem[]>([]);
    const [actions, setActions] = useState<IActionPlanItem[]>([]);

    useEffect(() => {
        const currentZone = actionPlan.actionPlanZones.find(z => z.zone === zoneType);
        if (currentZone) {
            setZone(currentZone);
            setMedicationActions(
                currentZone.zoneItems.filter(i => i.inputType === ActionplanInputType.MEDICATION) || []
            );
            setActions(currentZone.zoneItems.filter(i => i.inputType === ActionplanInputType.ACTION) || []);
        }
    }, []);

    // Save updates in context
    const propagateUpdates = (medicationItems: IActionPlanItem[], actionItems: IActionPlanItem[]) => {
        if (zone) {
            const updatedZone: IActionPlanZone = {
                ...zone,
                zoneItems: [...medicationItems, ...actionItems]
            };
            updateActionPlan({
                type: ReducerActionType.UPDATE_ZONE,
                payload: updatedZone
            });
        }
    };

    const updateMedications = (updatedMedications: IActionPlanItem[]) => {
        setMedicationActions(updatedMedications);
        propagateUpdates(updatedMedications, actions);
    };

    const updateActions = (updatedActions: IActionPlanItem[]) => {
        setActions(updatedActions);
        propagateUpdates(medicationActions, updatedActions);
    };

    if (!zone) {
        return <Spinner />;
    }

    return (
        <S.ZoneContainer>
            <ZoneHeader zone={zoneType}>{zoneType.toLowerCase()} Zone</ZoneHeader>
            {upperExtra}
            <S.ZoneInner>
                {zoneType !== ActionPlanZoneType.GREEN && (
                    <Actions
                      zoneType={zoneType}
                      actions={actions}
                      updateActions={updateActions}
                      disabled={!actionPlan.isActive}
                    />
                )}
                <MedicationActions
                  medicationActions={medicationActions}
                  updateActions={updateMedications}
                  disabled={!actionPlan.isActive}
                  zoneType={zoneType}
                />
                {flowRate}
            </S.ZoneInner>
        </S.ZoneContainer>
    );
};

export default ActionPlanZone;
