import React, { useContext, useEffect, useState } from 'react';
import { Modal, Inputs } from '@apps/common-ui';
import { ExerciseContext } from '../../state/ExerciseContext';
import * as S from './index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ExerciseActions } from '../../state/actions';
import { useNavigate } from 'react-router';
import { ExerciseTypes } from '@apps/common-utilities';

const UpdateExerciseModal = ({ showModal, dismissModal, onSubmit, setError, assignedExercise }: {showModal: boolean, dismissModal: Function, onSubmit: Function, setError: Function, assignedExercise: ExerciseTypes.IAssignedExercise | undefined }) => {
    const { state, dispatch } = useContext(ExerciseContext);
    const [applyToAll, setApplyToAll] = useState(false);
    const navigate = useNavigate();

    const getNumberOfIdenticalExercises = () => {
        if (assignedExercise?.exerciseId) {
            return state.exercisePlan.assignedExercises.filter(exercise => exercise.exerciseId === assignedExercise?.exerciseId).length;
        }
        return 0;
    };

    const saveChangesForAll = () => {
        if (assignedExercise && assignedExercise.exerciseVariable.sets > 0 && assignedExercise.exerciseVariable.amount > 0) {
            const exercisesToUpdate = state.exercisePlan.assignedExercises.filter(exercise => exercise.exerciseId === assignedExercise?.exerciseId);
            exercisesToUpdate.forEach(exercise => {
                // keep the id, dayofWeek, and dayOfWeekOrder of the original exercise and update the rest of the values with the edited exercise's values
                const payload: ExerciseTypes.IAssignedExercise = { ...assignedExercise, id: exercise.id, dayOfWeek: exercise.dayOfWeek, dayOfWeekOrder: exercise.dayOfWeekOrder };
                dispatch({ type: ExerciseActions.EDIT_ASSIGNED_EXCERCISE, payload });
            });
            navigate(-1);
            return;
        }
        setError('Please enter a valid number of sets and amount');
        dismissModal();
    };

    if (!assignedExercise) {
        return null;
    }

    return (
        <Modal showModal={showModal} dismissModal={dismissModal} showTopBar={false} showTranslucentBackground closeOnOutsideClick={false} minHeight="380px" minWidth="400px" maxWidth="380px">
            <S.Container>
                <S.TitleBar>
                    <S.Title>Apply Changes</S.Title>
                    <S.CloseButton onClick={() => dismissModal()}>
                        <FontAwesomeIcon icon={faX as IconProp} />
                    </S.CloseButton>
                </S.TitleBar>
                <S.NumExercisesContainer>
                    <S.NumExercises>This plan has <b>{getNumberOfIdenticalExercises()}</b> {assignedExercise.exerciseInfo.title} exercises</S.NumExercises>
                </S.NumExercisesContainer>
                <S.ConfirmationPrompt>Would you like to apply these changes to one or all of these exercises?</S.ConfirmationPrompt>
                <S.CheckBoxContainer>
                    <Inputs.Checkbox
                      id="applyToAll"
                      activeText={`Apply to ${getNumberOfIdenticalExercises()} exercises`}
                      inactiveText={`Apply to ${getNumberOfIdenticalExercises()} exercises`}
                      defaultChecked={false}
                      onChange={() => setApplyToAll(!applyToAll)}
                      boldActiveText
                      boldInactiveText
                    />
                </S.CheckBoxContainer>
                <S.ButtonRow>
                    <S.SaveButton
                      onClick={() => {
                        if (applyToAll) {
                            saveChangesForAll();
                        } else {
                            onSubmit();
                        }
                      }}
                    >
                        Save Changes
                    </S.SaveButton>
                </S.ButtonRow>
            </S.Container>
        </Modal>
    );
};

export default UpdateExerciseModal;
