var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from '../Modal';
import { useState } from 'react';
import * as S from './index.styles';
import { Button } from '../Button';
export var useConfirmDialog = function (_a) {
    var message = _a.message, buttons = _a.buttons, cancelType = _a.cancelType, successType = _a.successType;
    var _b = useState(), promise = _b[0], setPromise = _b[1];
    var confirm = function () { return new Promise(function (resolve, reject) {
        setPromise({ resolve: resolve });
    }); };
    var handleClose = function () {
        setPromise(null);
    };
    var handleConfirm = function () {
        promise === null || promise === void 0 ? void 0 : promise.resolve(true);
        handleClose();
    };
    var handleCancel = function () {
        promise === null || promise === void 0 ? void 0 : promise.resolve(false);
        handleClose();
    };
    var ConfirmationDialog = function (_a) {
        var children = _a.children;
        return (promise
            ? (_jsx(Modal, __assign({ dismissModal: handleClose, showModal: true, style: { maxWidth: '500px', minWidth: '500px' } }, { children: _jsxs(S.ConfirmContainer, { children: [_jsx("div", { children: message }), children, _jsxs(S.ButtonContainer, { children: [_jsx(Button, __assign({ buttonStyle: successType, onClick: handleConfirm }, { children: buttons ? buttons.yes : 'Yes' })), _jsx(Button, __assign({ onClick: handleCancel, buttonType: "tertiary", buttonStyle: cancelType }, { children: buttons ? buttons.no : 'No' }))] })] }) })))
            : null);
    };
    return { ConfirmationDialog: ConfirmationDialog, confirm: confirm };
};
export default useConfirmDialog;
