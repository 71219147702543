import React, { useContext, useState } from 'react';
import * as S from '../../index.styles';
import { Buttons } from '@apps/common-ui';
import { ExerciseTypes } from '@apps/common-utilities';

type Props = {
    exercisePlan: ExerciseTypes.IExercisePlan;
    onConfirmPause: Function;
}

const ManageProgramPaused = ({ exercisePlan, onConfirmPause }: Props) => {
    return (
        <>
            <S.Row>
                <p>Are you sure you want to {exercisePlan.paused ? 'resume' : 'disable'} this program?</p>
            </S.Row>
            <S.Row>
                <Buttons.Button
                  type="button"
                  buttonStyle={exercisePlan.paused ? undefined : 'danger'}
                  onClick={onConfirmPause}
                >
                    {exercisePlan.paused ? 'Resume Program' : 'Disable Program'}
                </Buttons.Button>
            </S.Row>
        </>
    );
};

export default ManageProgramPaused;
