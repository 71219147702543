import React from 'react';
import * as S from './index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { format } from 'date-fns';
import { NaiveDate } from '../../../utils/dateUtils';

interface Props {
    newStartDate: Date;
    setNewStartDate: (date: Date) => void;
    newEndDate: Date;
    setNewEndDate: (date: Date) => void;
    changeDate: (date: Date) => void;
}

export const SessionDateForm = ({ newStartDate, setNewStartDate, newEndDate, setNewEndDate, changeDate }: Props) => {
    return (
        <S.StartEndTimesRow>
            <S.InputsGroupContainer>
                <S.InputsGroupTitle>Start Time</S.InputsGroupTitle>
                <S.InputsGroup>
                    <S.SingleInputContainer>
                        <S.TimeInput
                          id="start-time-hours"
                          type="number"
                          value={newStartDate.getHours()}
                          min="0"
                          max="23"
                          onChange={(e) => {
                                    setNewStartDate(new Date(newStartDate.setHours(e.target.value)));
                                  }}
                        />
                        <S.InputLabel htmlFor="start-time-hours">hr</S.InputLabel>
                    </S.SingleInputContainer>
                    <S.SingleInputContainer>
                        <S.TimeInput
                          id="start-time-minutes"
                          type="number"
                          value={newStartDate.getMinutes()}
                          min="0"
                          max="59"
                          onChange={(e) => {
                                    setNewStartDate(new Date(newStartDate.setMinutes(e.target.value)));
                                  }}
                        />
                        <S.InputLabel htmlFor="start-time-minutes">min</S.InputLabel>
                    </S.SingleInputContainer>
                    <S.SingleInputContainer>
                        <S.TimeInput
                          id="start-time-seconds"
                          type="number"
                          value={newStartDate.getSeconds()}
                          min="0"
                          max="59"
                          onChange={(e) => {
                                    setNewStartDate(new Date(newStartDate.setSeconds(e.target.value)));
                                  }}
                        />
                        <S.InputLabel htmlFor="start-time-seconds">sec</S.InputLabel>
                    </S.SingleInputContainer>
                </S.InputsGroup>
            </S.InputsGroupContainer>
            <S.ArrowContainer>
                <FontAwesomeIcon icon={faArrowRight as IconProp} />
            </S.ArrowContainer>
            <S.InputsGroupContainer>
                <S.InputsGroupTitle>End Time</S.InputsGroupTitle>
                <S.InputsGroup>
                    <S.SingleInputContainer>
                        <S.TimeInput
                          id="end-time-hours"
                          type="number"
                          value={newEndDate.getHours()}
                          min="0"
                          max="23"
                          onChange={(e) => {
                                    setNewEndDate(new Date(newEndDate.setHours(e.target.value)));
                                  }}
                        />
                        <S.InputLabel htmlFor="end-time-hours">hr</S.InputLabel>
                    </S.SingleInputContainer>
                    <S.SingleInputContainer>
                        <S.TimeInput
                          id="end-time-minutes"
                          type="number"
                          value={newEndDate.getMinutes()}
                          min="0"
                          max="59"
                          onChange={(e) => {
                                    setNewEndDate(new Date(newEndDate.setMinutes(e.target.value)));
                                  }}
                        />
                        <S.InputLabel htmlFor="end-time-minutes">min</S.InputLabel>
                    </S.SingleInputContainer>
                    <S.SingleInputContainer>
                        <S.TimeInput
                          id="end-time-seconds"
                          type="number"
                          value={newEndDate.getSeconds()}
                          min="0"
                          max="59"
                          onChange={(e) => {
                                    setNewEndDate(new Date(newEndDate.setSeconds(e.target.value)));
                                  }}
                        />
                        <S.InputLabel htmlFor="end-time-seconds">sec</S.InputLabel>
                    </S.SingleInputContainer>
                </S.InputsGroup>
            </S.InputsGroupContainer>
            <S.DateContainer>
                <S.DateTitle htmlFor="date">Date</S.DateTitle>
                <S.DateSelect
                  id="date"
                  value={format(newStartDate, 'yyyy-MM-dd')}
                  type="date"
                  max={format(new Date(), 'yyyy-MM-dd')}
                  onChange={(e) => {
                            changeDate(new NaiveDate(e.target.value));
                          }}
                />
            </S.DateContainer>
        </S.StartEndTimesRow>
    );
};

export default SessionDateForm;
