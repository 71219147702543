import styled from 'styled-components';

export const SectionTitle = styled.p`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
`;

export const SectionText = styled.p`
    margin: 0;
    margin-bottom: 5px;
`;

export const SectionSubText = styled.p`
    margin: 0;
    font-size: 14px;
    color: #939393;
`;

export const InputContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin: 10px 0;

    input {
        width: 80%;
    }
`;
