var slugify = {
    toSlug: function (str) {
        return str
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
    },
    fromSlug: function (str) {
        return str.replace(/-/g, ' ');
    }
};
export default slugify;
