import { Inputs, Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    background: ${props => props.theme.primary.medium};
`;

export const BackButton = styled(Buttons.Button)`
    color: ${props => props.theme.shades.white};
    background: ${props => props.theme.primary.medium};
    border: ${props => props.theme.primary.medium} 1px solid;
    margin-left: 30px;
    padding-left: 0px;
    svg {
        margin-left: 0px !important;
        color: ${props => props.theme.shades.white};
    }
`;

export const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 1000px;
    height: 100%;
    margin-bottom: 100px;
`;

export const ModuleForm = styled.form`
    width: 100%;
    height: 100%;
    display: flex
    flex-direction: column;
`;

export const PageTitle = styled.h3`
    margin: 20px 0 20px 0;
`;

export const SectionRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FieldLabel = styled.p`
    margin: 0 0 10px 0;
    font-weight: bold;
`;

export const Field = styled(Inputs.Input)`
    width: 500px;
    max-width: 500px;
    margin: 0 0 20px 0;
`;

export const PhotoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 350px;
`;

export const PhotoRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const ModuleImageContainer = styled.div`
    display: flex;
    align-items: center;

    img {
        max-width: 80px;
    }
`;

export const PhotoButton = styled(Buttons.Button)`
    background: transparent;
    color: ${props => props.theme.buttons.blue};
    width: 165px;
    height: 50px;
    margin: 0;
`;

export const StatsRow = styled.div`
    display: flex;
    width: 350px;
    justify-content: space-between;
    margin: 30px 0 0 0;
`;

export const StatsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StatsTitle = styled.p`
    font-weight: bold;
    white-space: nowrap;
    margin: 0;
`;

export const StatsValue = styled.p`
    margin: 5px 0 0 0;
`;

export const ModuleContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 30px 0 0 0;
`;

export const ModuleContentsTitleRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;
`;

export const ModuleContentsTitle = styled.h3<{moduleExists: boolean}>`
    color: ${props => props.moduleExists ? props.theme.text.black : props.theme.text.lightGrey};
    margin: 0;
`;

export const TitleButtonsContainer = styled.div`
    display: flex;
    width: 350px;
    justify-content: space-between;
`;

export const AddQuizOrLessonButton = styled(Buttons.Button)<{moduleExists: boolean}>`
    background: transparent;
    color: ${props => props.moduleExists ? props.theme.buttons.blue : props.theme.buttons.grey};
    border: 2px solid ${props => props.moduleExists ? props.theme.buttons.blue : props.theme.buttons.grey};
    width: 150px;
    height: 50px;
    margin: 0;
`;

export const SaveModulePrompt = styled.p`
    color: ${props => props.theme.text.lightGrey};
    margin: 0;
`;

export const SubmoduleRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-top: ${props => props.theme.table.borderLight} 2px solid;

    &:last-child {
        border-bottom: ${props => props.theme.table.borderLight} 2px solid;
    }
`;

export const SubmoduleRowStartContainer = styled.div`
    display: flex;
    align-items: center;
    min-width: 300px;
    gap: 20px;
`;

export const PositionButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40px;
    height: 60px;
`;

export const SubmodulePositionButton = styled(Buttons.Button)<{isFirstOrLast: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.isFirstOrLast ? props.theme.text.black : 'transparent'};
    width: 35px;
    height: 20px;
    border: 1px solid ${props => props.theme.text.black};
    border-radius: 3px;
    margin: 3px 0;

    svg {
        color: ${props => props.theme.text.black};
    }
`;

export const SubmoduleTitle = styled.p`
    font-weight: bold;
    margin: 0;
`;

export const SubmoduleRowEndContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DuplicateButton = styled(Buttons.Button)`
    background: transparent;
    color: ${props => props.theme.buttons.blue};
    border: none;
    width: 150px;
    height: 50px;
    margin: 0;
    padding-right: 0;
`;

export const EditButton = styled(Buttons.Button)`
    background: transparent;
    color: ${props => props.theme.buttons.blue};
    border: none;
    width: 50px;
    height: 50px;
    margin: 0;
    padding-right: 0;
`;

export const ModuleContentsBottomContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
`;

export const ModuleContentsButtonContainer = styled.div`
    display: flex;
    width: 400px;
    gap: 20px;
`;

export const SaveButton = styled(Buttons.Button)`
    width: 165px;
    height: 50px;
    margin: 0;
`;

export const DiscardButton = styled(Buttons.Button)`
    width: 80px;
    height: 50px;
    background: transparent;
    color: ${props => props.theme.buttons.blue};
    border: none;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
`;

export const DeleteButton = styled(Buttons.Button)`
    width: 120px;
    height: 50px;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    color: ${props => props.theme.buttons.red};
    border: none;
`;

export const LastUpdatedContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const LastUpdated = styled.p`
    color: ${props => props.theme.text.lightGrey};
    margin: 0;
`;
