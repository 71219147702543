import { Modal, Inputs, Buttons, Spinner } from '@apps/common-ui';
import * as S from './index.styles';
import React, { useEffect, useState } from 'react';

interface Props {
    showModal: boolean;
    dismissModal: () => void;
    tasks: any[];
    onSubmit: (ids: string[]) => Promise<void>;
}

export const TasksModal = ({ showModal, dismissModal, tasks, onSubmit }: Props) => {
    const [idsToDelete, setIdsToDelete] = useState<Set<string>>(new Set());
    const [valid, setValid] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const onCheckboxChange = (id: string, checked: boolean) => {
        if (checked) {
            idsToDelete.add(id);
        } else {
            idsToDelete.delete(id);
        }
        setValid(idsToDelete.size > 0);
    };

    const submit = async () => {
        setSubmitting(true);
        await onSubmit(Array.from(idsToDelete));
        setSubmitting(false);
    };

    return (
        <Modal showModal={showModal} dismissModal={dismissModal} showTopBar closeOnOutsideClick title="Tasks" minWidth="500px">
            <S.Container>
                {tasks.map((task) => (
                    <S.TaskContainer key={task.id}>
                        <S.TaskContent>{task.content}</S.TaskContent>
                        <Inputs.Checkbox
                          id={task.id}
                          key={task.id}
                          onChange={(v) => onCheckboxChange(task.id, v)}
                        />
                    </S.TaskContainer>
                ))}
                <S.ButtonsContainer>
                    <Buttons.Button onClick={submit} disabled={!valid || submitting}>
                        {submitting ? <Spinner /> : 'Submit'}
                    </Buttons.Button>
                    <Buttons.Button onClick={dismissModal} buttonType="secondary">
                        Discard Changes
                    </Buttons.Button>
                </S.ButtonsContainer>
            </S.Container>
        </Modal>
    );
};
