import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const PageTitle = styled.h3`
    margin: 30px 0 20px 0;
`;

export const SubtitleRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
`;

export const DetailsContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

export const PatientName = styled.p`
    font-weight: bold;
    margin: 0;
`;

export const LastUpdated = styled.p`
    color: ${props => props.theme.text.lightGrey};
    margin: 0;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SaveButton = styled(Buttons.Button)`
    background: transparent;
    color: ${props => props.theme.buttons.blue};
    width: 150px;
    height: 50px;
`;

export const AssignPlanButton = styled(Buttons.Button)<{canAssign: boolean}>`
    background: ${props => props.canAssign ? props.theme.buttons.green : props.theme.buttons.grey};
    color: ${props => props.theme.shades.white};
    border: 2px solid ${props => props.canAssign ? props.theme.buttons.green : props.theme.buttons.grey};
    width: 135px;
    height: 50px;
`;

export const ManageButton = styled(Buttons.Button)`
    width: 135px;
    height: 50px;
`;

export const NoModulesRow = styled.div`
    display: flex;
    height: 70px;
    align-items: center;
    border-top: ${props => props.theme.table.borderLight} 2px solid;
    border-bottom: ${props => props.theme.table.borderLight} 2px solid;
`;

export const NoModulesText = styled.p`
    margin: 0;
`;

export const ModuleListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ModuleRow = styled.div`
    display:flex;
    justify-content: space-between;
    border-top: ${props => props.theme.table.borderLight} 2px solid;

    &:last-child {
        border-bottom: ${props => props.theme.table.borderLight} 2px solid;
    }
`;

export const ModuleRowStartContainer = styled.div`
    display: flex;
    align-items: center;
    min-width: 300px;
    gap: 20px;
`;

export const PositionButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40px;
    height: 60px;
`;

export const ModulePositionButton = styled(Buttons.Button)<{isFirstOrLast?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.isFirstOrLast ? props.theme.buttons.grey : 'transparent'};
    width: 35px;
    height: 20px;
    border: 1px solid ${props => props.theme.buttons.grey};
    border-radius: 3px;
    margin: 3px 0;

    svg {
        color: ${props => props.theme.buttons.grey};
    }
`;

export const ModuleImage = styled.img`
    object-fit: cover;
    width: 70px;
    height: 70px;
`;

export const ModuleTitle = styled.p`
    margin: 0;
    font-weight: bold;
`;

export const ModuleRowEndContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const RemoveButton = styled(Buttons.Button)`
    background: transparent;
    border: none;
    color: ${props => props.theme.buttons.red};
    width: 80px;
    height: 50px;
`;

export const ModuleDetailsButton = styled(Buttons.Button)`
    background: transparent;
    border: none;
    color: ${props => props.theme.buttons.blue};
    width: 170px;
    height: 50px;
    margin-right: 0;
    padding-right: 0;
`;

export const SubmoduleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0 10px 130px;
    gap: 10px;
`;

export const SubmoduleRow = styled.div`
    display: flex;
    gap: 10px;
`;

export const SubmoduleNumber = styled.p`
    margin: 0;
`;

export const SubmoduleTitle = styled.p`
    margin: 0;
`;

export const BottomButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
`;

export const AddModuleButton = styled(Buttons.Button)`
    width: 150px;
    height: 50px;
`;
