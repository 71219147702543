import { createSlice } from '@reduxjs/toolkit';
import { ExerciseTypes } from '@apps/common-utilities';
import { Routes } from '../../../api/Routes';
import { AppDispatch } from '../../store';
import { TenantApi } from '../../../api/CoachingAPI';
import { AppointmentAttendedRoleType, IExercisePlanDischargeDatePayload } from '@apps/common-utilities/src/types/exerciseTypes';

export interface PhysicalTherapyState {
    exercises: ExerciseTypes.IExercise[] | [];
    exerciseSummary: ExerciseTypes.IExercisePlanSummary | null;
    totalExercises: string;
    categorySummary: { [key: string]: string };
    exerciseScheduledAppointmentSummary: ExerciseTypes.IScheduledAppointmentSummary | null;
}

const initialState = {
    exercises: [],
    exerciseSummary: {
        startDate: '2021-01-01 09:00:00',
        endDate: '2021-01-01 09:00:00',
    },
    totalExercises: '0 / 0',
    categorySummary: {},
    exerciseScheduledAppointmentSummary: {}
} as PhysicalTherapyState;

const physicalTherapySlice = createSlice({
    name: 'physicalTherapy',
    initialState,
    reducers: {
        setExercises: (state, { payload }: {payload: { exercises: ExerciseTypes.IExercise[] }}) => {
            state.exercises = payload.exercises;
        },
        addExercise: (state, { payload }: {payload: { exercise: ExerciseTypes.IExercise }}) => {
            state.exercises = [payload.exercise, ...state.exercises];
        },
        setExerciseSummary: (state, { payload }: {payload: {
            exerciseSummary: ExerciseTypes.IExercisePlanSummary,
            totalExercises: string,
            categorySummary: { [key: string]: string }
        }}) => {
            state.exerciseSummary = payload.exerciseSummary;
            state.totalExercises = payload.totalExercises;
            state.categorySummary = payload.categorySummary;
        },
        setExerciseScheduledAppointmentSummary: (state, { payload }: {payload: {
            exerciseScheduledAppointmentSummary: ExerciseTypes.IScheduledAppointmentSummary
        }}) => {
            state.exerciseScheduledAppointmentSummary = payload.exerciseScheduledAppointmentSummary;
        },
        _updateExercise: (state, { payload }: {payload: { exercise: ExerciseTypes.IExercise }}) => {
            const index = state.exercises.findIndex((exercise) => exercise.id === payload.exercise.id);
            state.exercises[index] = payload.exercise;
        },
        removeExercise: (state, { payload }: {payload: { exerciseId: number }}) => {
            state.exercises = state.exercises.filter((exercise) => exercise.exerciseId !== payload.exerciseId);
        }
    }
});

export const {
    setExercises,
    addExercise,
    setExerciseSummary,
    setExerciseScheduledAppointmentSummary,
    _updateExercise,
    removeExercise
} = physicalTherapySlice.actions;

export default physicalTherapySlice.reducer;

export const fetchExercises = () => async (dispatch: AppDispatch) => {
    const exercises = await TenantApi.get(`/${Routes.coaches}/${Routes.exercises}`);
    dispatch(setExercises({ exercises }));
};

export const createExercise = (exercise: ExerciseTypes.IExercise) => async (dispatch: AppDispatch) => {
    await TenantApi.post(`/${Routes.coaches}/${Routes.exercises}`, exercise).then((newExercise: ExerciseTypes.IExercise) => {
        if (newExercise.id !== null) {
            dispatch(addExercise({ exercise: newExercise }));
        } else {
            throw new Error('Failed to create exercise');
        }
    });
};

export const fetchExerciseSummary = (patientId: string) => async (dispatch: AppDispatch) => {
    const response = await TenantApi
        .get(`/${Routes.coaches}/${Routes.users}/${patientId}/${Routes.exercisePlans}/${Routes.summary}`);
    const { summaries, totalExercises, categorySummary } = response;

    summaries.sort((a: any, b: any) => a.id - b.id);
    const exerciseSummary = summaries.length > 0 ? summaries[0] : null;

    dispatch(setExerciseSummary({ exerciseSummary, totalExercises, categorySummary }));
};

export const fetchExerciseScheduledAppointmentSummary = (patientId: string) => async (dispatch: AppDispatch) => {
    const exerciseScheduledAppointmentSummary = await TenantApi.get(`/${Routes.coaches}/${Routes.patients}/${patientId}/appointments-summary?type=${AppointmentAttendedRoleType.PHYSICAL_THERAPIST}`);
    dispatch(setExerciseScheduledAppointmentSummary({ exerciseScheduledAppointmentSummary }));
};

export const updateExercise = (exercise: ExerciseTypes.IExercise) => async (dispatch: AppDispatch) => {
    const updatedExercise: any = await TenantApi.put(`/${Routes.coaches}/${Routes.exercises}/${exercise.exerciseId}`, exercise);
    dispatch(_updateExercise({ exercise: updatedExercise }));
};

export const deleteExercise = (exerciseId: number) => async (dispatch: AppDispatch) => {
    await TenantApi.delete(`/${Routes.coaches}/${Routes.exercises}/${exerciseId}`);
    dispatch(removeExercise({ exerciseId }));
};
