import React, { PropsWithChildren, useState } from 'react';
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    Tooltip,
    Title,
    Legend,
    BarSeries,
    ScatterSeries,
    LineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker, PointComponentProps, Stack } from '@devexpress/dx-react-chart';
import { rmtDifficultyColor } from '../../reportingUtils';
import { ExerciseTypes } from '@apps/common-utilities';

const TrianglePoint = (styles) => (props: {arg: number, val: number, color: string}) => {
    const {
        arg,
        val,
        color,
    } = props;
    if (val === undefined) {
        return null;
    }
    return (
        <path
          fill={color}
          transform={`translate(${arg - 10} ${val})`}
          style={styles}
          d="M 15 8 L 5 8 L 10 0 Z"
        />
    );
};

const ScatterPoint = (props: PointComponentProps) => {
    const { value } = props;
    if (value !== null) {
        return <ScatterSeries.Point {...props} point={{ size: 10 }} />;
    }
    return null;
};

const LineWithPoints = (props: LineSeries.SeriesProps) => {
    const { coordinates } = props;
    return (
        <>
            <LineSeries.Path {...props} coordinates={coordinates} />
            <ScatterSeries.Path {...props} coordinates={coordinates} pointComponent={ScatterPoint} />
        </>
    );
};

const LineWithTriangles = (props: LineSeries.SeriesProps) => {
    const { coordinates } = props;
    return (
        <ScatterSeries.Path
          {...props}
          coordinates={coordinates}
          pointComponent={
            TrianglePoint({
                    strokeWidth: '1px',
                })
              }
        />
    );
};

// shifts the labels on the y axis so they don't overlap
const ArgumentRoot = ({ ...props }: PropsWithChildren<any>) => {
    const [rootWidth, setRootWidth] = useState(0);
    const lineAndTicks = React.Children.toArray(props.children).filter(
        (child: any) => child.type !== ArgumentAxis.Label
    );
    const labels = React.Children.toArray(props.children)
        .filter((child: any) => child.type === ArgumentAxis.Label)
        .map((label: any, index) => {
            const offset = rootWidth > 300 || index % 2 === 0 ? 0 : 20;
            return React.cloneElement(label, {
                ...label.props,
                y: label.props.y + offset
            });
        });
    return (

        <ArgumentAxis.Root {...props}>
            {lineAndTicks}
            {labels}
        </ArgumentAxis.Root>
    );
};
const LineChart = (
    {
        data,
        chartTitle,
        warningValues
    }: {
        data: {
            value: number | null,
            title: string
        }[],
        chartTitle: string,
        warningValues?: number[]
    }
) => {
    const chartData = [...data.map((item) => {
        const entry = {
            title: item.title,
            value: item.value,
        };
        if (warningValues) {
            warningValues.forEach((war, index) => {
                entry[`warning${index}`] = war;
            });
        }
        return entry;
    })];
    return (
        <Chart
          data={chartData}
        >
            <Title
              text={chartTitle}
            />
            <ArgumentAxis
              rootComponent={ArgumentRoot}
            />
            <ValueAxis />
            {warningValues && warningValues.map((war, index) => (
                <LineSeries
                  color="red"
                  argumentField="title"
                  valueField={`warning${index}`}
                />
            ))}
            <LineSeries
              seriesComponent={LineWithPoints}
              valueField="value"
              argumentField="title"
            />
            <EventTracker />
            <Tooltip />
            <Animation />
        </Chart>
    );
};

const SpO2Chart = (
    {
        data,
        chartTitle,
        warningValues
    }: {
        data: { title: string, lowestSpO2: number | null, immediateSpO2: number | null }[],
        chartTitle: string,
        warningValues?: number[]
    }
) => {
    const chartData = [...data.map((item) => {
        const entry = {
            title: item.title,
            lowestSpO2: item.lowestSpO2,
            immediateSpO2: item.immediateSpO2,
        };
        if (warningValues) {
            warningValues.forEach((war, index) => {
                entry[`warning${index}`] = war;
            });
        }
        return entry;
    })];

    return (
        <Chart
          data={chartData}
        >
            <Title
              text={chartTitle}
            />
            <ArgumentAxis
              rootComponent={ArgumentRoot}
            />
            <ValueAxis />
            {warningValues && warningValues.map((war, index) => (
                <LineSeries
                  color="#FF7171"
                  argumentField="title"
                  valueField={`warning${index}`}
                  name="Warning"
                />
            ))}
            <LineSeries
              seriesComponent={LineWithPoints}
              name="Lowest SpO2"
              valueField="lowestSpO2"
              argumentField="title"
              color="lightblue"
            />
            <LineSeries
              seriesComponent={LineWithTriangles}
              name="Immediate SpO2"
              valueField="immediateSpO2"
              argumentField="title"
            />
            <Legend />
            <EventTracker />
            <Tooltip />
            <Animation />
        </Chart>
    );
};

interface IBloodPressureChartProps {
    data: {
        title: string,
        bpSystolic: number | null,
        bpDiastolic: number | null
    }[],
    chartTitle: string,
    warningValues?: {
        value: number,
        color: string,
        label: string,
    }[]
}

const BloodPressureChart = (
    {
        data,
        chartTitle,
        warningValues
    }: IBloodPressureChartProps
) => {
    const chartData = [...data.map((item) => {
        const entry = {
            title: item.title,
            bpSystolic: item.bpSystolic,
            bpDiastolic: item.bpDiastolic,
        };
        if (warningValues) {
            warningValues.forEach((war, index) => {
                entry[`warning${index}`] = war.value;
            });
        }
        return entry;
    })];

    return (
        <Chart
          data={chartData}
        >
            <Title
              text={chartTitle}
            />
            <ArgumentAxis
              rootComponent={ArgumentRoot}
            />
            <ValueAxis />

            <LineSeries
              seriesComponent={LineWithPoints}
              name="Systolic"
              valueField="bpSystolic"
              argumentField="title"
            />
            <LineSeries
              seriesComponent={LineWithPoints}
              name="Diastolic"
              valueField="bpDiastolic"
              argumentField="title"
            />
            {warningValues && warningValues.map((war, index) => (
                <LineSeries
                  color={war.color}
                  argumentField="title"
                  valueField={`warning${index}`}
                  name={`${war.label}`}
                />
            ))}
            <EventTracker />
            <Tooltip />
            <Animation />
            <Legend />
        </Chart>
    );
};

const ExerciseCompletionChart = ({ data, chartTitle }: {
    data: {
        title: string,
        assignedSets: number | null,
        assignedAmount: number | null,
        results: number[],
        type: ExerciseTypes.ExerciseType,
    }[],
    chartTitle: string
}) => {
    const chartData = [...data.map((item, index) => {
        // baselines don't have assigned amount or sets
        // still show them so the charts columns are consistent
        if (item.assignedAmount === null || item.assignedSets === null) {
            return {
                title: item.title,
                assignedAmount: 0,
                results: 0,
            };
        }
        const entry = {
            title: item.title,
            assignedAmount: 100,
            results: 0,
        };

        // if no results, then assume the assigned amount is the result
        if (item.results.length === 0) {
            entry.results = 0;
        } else {
            entry.results = (item.results.reduce((a, b) => (a + b), 0) / (item.assignedAmount * item.assignedSets)) * 100;
        }
        return entry;
    })];

    return (
        <Chart
          data={chartData}
        >
            <Title
              text={chartTitle}
            />
            <ArgumentAxis rootComponent={ArgumentRoot} />
            <ValueAxis />
            <Stack
              stacks={[
                    { series: ['Percent Completed', 'Total'] },
                ]}
            />
            <BarSeries name="Total" valueField="assignedAmount" argumentField="title" color="lightgrey" />
            <BarSeries name="Percent Completed" valueField="results" argumentField="title" color="lightblue" />
            <EventTracker />
            <Tooltip />
            <Animation />
        </Chart>
    );
};

const RMTSessionChart = ({ data, chartTitle, difficulty, assignedAmount }: {
    data: number[],
    chartTitle: string,
    difficulty: number,
    assignedAmount: number,
}) => {
    const getChartWidth = () => data.length > 6 ? data.length * 40 + 150 : 400;

    const chartData = data.map((item, index) => {
        return {
            assignedAmount: assignedAmount - item,
            result: item,
            title: `Set ${index + 1}`,
        };
    });
    return (
        <Chart
          height={300}
          width={getChartWidth()}
          data={chartData}
        >
            <Title
              text={chartTitle}
            />
            <ArgumentAxis
              rootComponent={ArgumentRoot}
            />
            <ValueAxis />
            <BarSeries
              name="Results"
              valueField="result"
              argumentField="title"
              color={rmtDifficultyColor(difficulty)}
            />
            <BarSeries
              name="Assigned Amount"
              valueField="assignedAmount"
              argumentField="title"
              color="lightgrey"
            />
            <Stack
              stacks={[
                    { series: ['Results', 'Assigned Amount'] },
                ]}
            />
            <EventTracker />
            <Tooltip />
            <Animation />
        </Chart>
    );
};

export default {
    LineChart,
    ExerciseCompletionChart,
    BloodPressureChart,
    SpO2Chart,
    RMTSessionChart,
};
