import { Inputs } from '@apps/common-ui';
import React from 'react';
import * as S from '../../index.styles';
import { FormComponentProps, HeartRateValue, RawExerciseFormData } from '../../types';

const Targets = ({ errors, register, watch }: FormComponentProps) => (
    <S.FormRow>
        <S.FormRow direction="column">
            <S.FormSectionTitle>💪 Active Targets</S.FormSectionTitle>
            <S.TargetsCheckbox>
                <Inputs.Input {...register('activeTargets.breathlessness')} type="checkbox" id="breathlessness" />
                <Inputs.Label htmlFor="breathlessness">Breathlessness (between 4-6)</Inputs.Label>
            </S.TargetsCheckbox>
            <S.TargetsCheckbox>
                <Inputs.Input {...register('activeTargets.hrRange')} type="checkbox" id="hrRange" />
                <Inputs.Label htmlFor="hrRange">HR Range</Inputs.Label>
                <Inputs.Select
                  disabled={!watch('activeTargets.hrRange')}
                  {...register('activeTargets.activeHrRange')}
                >
                    <option selected value={HeartRateValue.FORTY_TO_SIXTY}>40-60%</option>
                    <option value={HeartRateValue.FORTY_TO_EIGHTY}>40-80%</option>
                    <option value={HeartRateValue.SIXTY_TO_EIGHTY}>60-80%</option>
                </Inputs.Select>
            </S.TargetsCheckbox>
            <S.TargetsCheckbox>
                <Inputs.Input {...register('activeTargets.spO2')} type="checkbox" id="spO2" />
                <Inputs.Label htmlFor="spO2">Show SpO2</Inputs.Label>
            </S.TargetsCheckbox>
        </S.FormRow>
        <S.FormRow direction="column">
            <S.FormSectionTitle>😴 Rest Targets</S.FormSectionTitle>
            <S.TargetsCheckbox>
                <Inputs.Input {...register('restTargets.breathlessness')} type="checkbox" id="restTargets.breathlessness" />
                <Inputs.Label htmlFor="restTargets.breathlessness">Breathlessness (less than 4/10)</Inputs.Label>
            </S.TargetsCheckbox>
            <S.TargetsCheckbox>
                <Inputs.Input {...register('restTargets.hrRange')} type="checkbox" id="restTargets.hrRange" />
                <Inputs.Label htmlFor="restTargets.hrRange">HR Range</Inputs.Label>
                <Inputs.Select
                  disabled={!watch('restTargets.hrRange')}
                  {...register('restTargets.restHrRange')}
                >
                    <option selected value={HeartRateValue.BELOW_FORTY}>Below 40%</option>
                    <option value={HeartRateValue.BELOW_SIXTY}>Below 60%</option>
                </Inputs.Select>
            </S.TargetsCheckbox>
            <S.TargetsCheckbox>
                <Inputs.Input {...register('restTargets.spO2')} type="checkbox" id="restTargets.spO2" />
                <Inputs.Label htmlFor="restTargets.spO2">Show SpO2</Inputs.Label>
            </S.TargetsCheckbox>
        </S.FormRow>
    </S.FormRow>
);

export default Targets;
