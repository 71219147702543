import { uploadFile } from '../../utils/fileUpload';

export const dragAndDropUpload = (data, success, failed, progress): any => {
    const file = data.files[0];
    uploadFile(file).then((res: any) => {
        success([{ name: file.name, src: res }]);
    });
};

export const editorEntityStyleHandler = (entity) => {
    const entityType = entity.get('type').toLowerCase();
    if (entityType === 'image') {
        const data = entity.getData();
        return {
            element: 'img',
            attributes: {
                src: data.src,
                width: data.width ? `${data.width}%` : undefined,
                height: data.height ? `${data.height}%` : undefined,
            },
            style: {
                float: data.alignment,
            },
        };
    }
    return entity;
};
