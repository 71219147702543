import styled from 'styled-components';

interface ColumnProps {
    maxWidth?: string;
}

export const PatientInfoRow = styled.div<{border?: string, gap?: string}>`
    display: flex;
    margin-bottom: 25px;
    padding-bottom: 50px;
    width: 100%;
    border-bottom: ${props => props.border || `1px solid ${props.theme.shades.lightGrey}`};
    gap: ${props => props.gap ? props.gap : '0'};
`;

export const PatientInfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10%;
    width: 90%;

    span {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 5px;
    }

    p {
        margin: 0;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
`;

export const Header = styled.h4`
    font-size: 12px;
    font-weight: bold;
    color: ${props => props.theme.primary.light};
`;

export const PatientInfoColumn = styled.div<ColumnProps>`
    display: flex;
    flex-direction: column;
    max-width: ${props => props.maxWidth || '100%'};
`;
