import styled from 'styled-components';

export const PageTitle = styled.h2`
    font-weight: normal;
`;

export const ModuleDetails = styled.div`
    display: flex;
    gap: 15px;
    padding: 10px 0;
`;

export const ColumnNameContainer = styled.div`
    display: flex;  
`;

export const ColumnName = styled.p`
    font-weight: bold;
    margin: 0;
`;

export const ModuleName = styled.div`
    h3 {
        margin-bottom: 8px;
    }
    span {
        color: ${props => props.theme.shades.grey};
        font-weight: bold;
    }
`;

export const ModuleListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    overflow: auto;
    padding: 0 50px;
`;

export const ModuleImage = styled.img`
    object-fit: cover;
    width: 70px;
    height: 70px;
`;
