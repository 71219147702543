import React, { useEffect, useState } from 'react';
import * as S from '../index.styles';
import Charts from '../components/Charts';
import { ExerciseTypes } from '@apps/common-utilities';
import { format } from 'date-fns';
import { Spinner } from '@apps/common-ui';
import { da } from 'date-fns/locale';
import { type } from 'os';
import Comments from '../components/Comments';

interface Props {
  viewingDay: Date;
  dailyReportingData: ExerciseTypes.IExerciseReport;
  rmt: boolean;
}

type Warning = {
  value: any;
  type: 'high' | 'low';
  label: string;
  exerciseName: string;
  unit: string;
};

const HEART_RATE_WARNING_HIGH = 150;
const HEART_RATE_WARNING_HIGH_BASELINE = 110;
const HEART_RATE_WARNING_LOW = 50;
const SPO2_WARNING = 85;
const BLOOD_PRESSURE_WARNING_SYSTOLIC_HIGH = 179;
const BLOOD_PRESSURE_WARNING_SYSTOLIC_LOW = 90;
const BLOOD_PRESSURE_WARNING_DIASTOLIC_HIGH = 100;
const BLOOD_PRESSURE_WARNING_DIASTOLIC_LOW = 60;

const GeneralReporting = ({ viewingDay, dailyReportingData, rmt }: Props) => {
    const [warnings, setWarnings] = useState<{[key: string]: Warning[]}>({});

    // Remove baseline reporting for comment data
    const comments = dailyReportingData.commentData ? dailyReportingData.commentData.slice(1) : [];

    const getWarnings = () => {
        const warningList: {[key: string]: Warning[]} = {};
        dailyReportingData.heartRateData?.forEach((heartRate) => {
            if (heartRate.title.includes('Baseline')) {
                if (heartRate.value
                && (heartRate.value > HEART_RATE_WARNING_HIGH_BASELINE || heartRate.value < HEART_RATE_WARNING_LOW)) {
                    warningList[heartRate.title] = [...warningList[heartRate.title] || [], {
                        value: heartRate.value,
                        type: heartRate.value > HEART_RATE_WARNING_HIGH_BASELINE ? 'high' : 'low',
                        label: 'Heart Rate',
                        exerciseName: heartRate.title,
                        unit: 'bpm'
                    }];
                }
            } else if (heartRate.value
                && (heartRate.value > HEART_RATE_WARNING_HIGH || heartRate.value < HEART_RATE_WARNING_LOW)) {
                warningList[heartRate.title] = [...warningList[heartRate.title] || [], {
                    value: heartRate.value,
                    type: heartRate.value > HEART_RATE_WARNING_HIGH ? 'high' : 'low',
                    label: 'Heart Rate',
                    exerciseName: heartRate.title,
                    unit: 'bpm'
                }];
            }
        });
        dailyReportingData.spO2Data.forEach((spO2) => {
            if (spO2.lowestSpO2 && spO2.lowestSpO2 < SPO2_WARNING) {
                warningList[spO2.title] = [...warningList[spO2.title] || [], {
                    value: spO2.lowestSpO2,
                    type: 'low',
                    label: 'SpO2 Lowest',
                    exerciseName: spO2.title,
                    unit: '%'
                }];
            }
            if (spO2.immediateSpO2 && spO2.immediateSpO2 < SPO2_WARNING) {
                warningList[spO2.title] = [...warningList[spO2.title] || [], {
                    value: spO2.immediateSpO2,
                    type: 'low',
                    label: 'SpO2 Immediate',
                    exerciseName: spO2.title,
                    unit: '%'
                }];
            }
        });
        dailyReportingData.bloodPressureData.forEach((bloodPressure) => {
            if (bloodPressure.bpSystolic && bloodPressure.bpDiastolic
              && (bloodPressure.bpSystolic > BLOOD_PRESSURE_WARNING_SYSTOLIC_HIGH
                || bloodPressure.bpSystolic < BLOOD_PRESSURE_WARNING_SYSTOLIC_LOW
                || bloodPressure.bpDiastolic > BLOOD_PRESSURE_WARNING_DIASTOLIC_HIGH
                || bloodPressure.bpDiastolic < BLOOD_PRESSURE_WARNING_DIASTOLIC_LOW)) {
                warningList[bloodPressure.title] = [...warningList[bloodPressure.title] || [], {
                    value: `${bloodPressure.bpSystolic}/${bloodPressure.bpDiastolic}`,
                    type: (bloodPressure.bpSystolic > BLOOD_PRESSURE_WARNING_SYSTOLIC_HIGH) ? 'high' : 'low',
                    label: 'Blood Pressure',
                    exerciseName: bloodPressure.title,
                    unit: 'mmHg'
                }];
            }
        });
        setWarnings(warningList);
    };

    useEffect(() => {
        if (dailyReportingData.heartRateData
            && dailyReportingData.spO2Data
            && dailyReportingData.bloodPressureData
        ) {
            getWarnings();
        }
    }, [dailyReportingData]);

    if (!dailyReportingData.exerciseCompletionData && !rmt) {
        return <Spinner />;
    }

    return (
        <>
            <S.DailyInfoContainer>
                <div>
                    <h3>{format(viewingDay, 'do MMMM yyyy')}</h3>
                </div>
                <S.DailyInfo>Additional Exercises Completed: {dailyReportingData.additionalExercisesCompleted}</S.DailyInfo>
                <S.DailyInfo>Exercises Completed: {dailyReportingData.todaysExercisesCompleted}/{dailyReportingData.exercisesAssigned}</S.DailyInfo>
                <S.DailyInfo>Exercise Completion: {dailyReportingData.setsCompletedPercentage}%</S.DailyInfo>
                <S.DailyInfo>
                    Duration: {Math.floor(dailyReportingData.minuteDuration / 60)}hr {dailyReportingData.minuteDuration % 60}min
                </S.DailyInfo>
            </S.DailyInfoContainer>
            <S.ExerciseWarningContainer open>
                <S.WarningText>
                    {dailyReportingData.warnings} Warnings
                </S.WarningText>
                <S.ExerciseWarningListContainer>
                    {Object.values(warnings).map((warningList: Warning[]) => (
                        <S.ExerciseWarningList key={warningList[0].exerciseName}>
                            <S.ExerciseWarningTextContainer>
                                <h3>{warningList[0].exerciseName}</h3>
                                {warningList.map((warning) => (
                                    <span>{warning.label}: {warning.value} {warning.unit}</span>
                            ))}
                            </S.ExerciseWarningTextContainer>
                        </S.ExerciseWarningList>
                ))}
                </S.ExerciseWarningListContainer>
            </S.ExerciseWarningContainer>
            <S.DailyInfoContainer />
            <S.ChartContainer>
                {!!dailyReportingData.exerciseCompletionData.length
                && (
                <Charts.ExerciseCompletionChart
                  data={dailyReportingData.exerciseCompletionData}
                  chartTitle="Exercise Completion"
                />
                )}
                {!!dailyReportingData.heartRateData.length
                && (
                <Charts.LineChart
                  data={dailyReportingData.heartRateData}
                  chartTitle="Heart Rate"
                  warningValues={[50, 110]}
                />
                )}
                {!!dailyReportingData.spO2Data.length
                && (
                <Charts.SpO2Chart
                  warningValues={[85]}
                  data={dailyReportingData.spO2Data}
                  chartTitle="SpO2"
                />
                )}
                {!!dailyReportingData.flowRateData.length
                && (
                <Charts.LineChart
                  data={dailyReportingData.flowRateData}
                  chartTitle="Flow Rate"
                />
                )}
                {!!dailyReportingData.breathlessnessData.length
                && (
                <Charts.LineChart
                  data={dailyReportingData.breathlessnessData}
                  chartTitle="Breathlessness"
                />
                )}
                {!!dailyReportingData.bloodPressureData.length
                && (
                <Charts.BloodPressureChart
                  data={dailyReportingData.bloodPressureData}
                  chartTitle="Blood Pressure"
                  warningValues={[
                      {
                        value: 90,
                        color: '#FF0000',
                        label: 'Systolic Low - 90'
                      }, {
                        value: 179,
                        color: '#FF0000',
                        label: 'Systolic High - 179'
                      },
                      {
                        value: 100,
                        color: 'orange',
                        label: 'Diastolic High - 100'
                      },
                      {
                        value: 60,
                        color: 'orange',
                        label: 'Diastolic Low - 60'
                      },
                    ]}
                />
                )}
            </S.ChartContainer>
            <Comments.Comments
              commentData={comments}
            />
        </>
    );
};

export default GeneralReporting;
